import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/images/noImage.png";
import { AiOutlineHeart } from "react-icons/ai";
import WatchedHandler from "../WatchedHandler/WatchedHandler";
import NewCardModal from "../CardModal/NewCardModal";
import ToFavouriteButton from "../FavWishAdd/ToFavouriteButton";

const TrendingCard = ({ data, type, theme, release, allDetails }) => {
  const navigate = useNavigate();

  const [createOrLogin, setCreateOrLogin] = useState();

  const navigateToDetails = () => {
    navigate(`../detailspage/${type}/${data.id}`);
  };

  const handleModalClose = () => {
    setCreateOrLogin(false);
  };

  const handleWatButtonClick = (event) => {
    event.stopPropagation();

    setCreateOrLogin(true);
  };

  return (
    <Wrapper theme={theme} bg={data.backdrop_path}>
      <Container
        theme={theme}
        onClick={navigateToDetails}
        poster={data.poster_path}
        bg={data.backdrop_path || data.poster_path}
        id={data.id}
        rating={data.vote_average}
        release={data.release}
      >
        <Info>
          {data.vote_average != 0 && data.vote_count > 10 && (
            <Rating rating={data.vote_average}>
              <h1>{data?.vote_average?.toFixed(1) ?? "N/A"}</h1>
            </Rating>
          )}
          <h2>{data.release != null ? data.release.slice(0, 4) : ""}</h2>
          <FavButtonWrapper>
            <ToFavouriteButton
              location="favourites"
              id={data.id}
              type={type}
              allDetails={allDetails}
            />
          </FavButtonWrapper>

          <WatchedWrap>
            <WatchedHandler
              movie={data.id}
              screenType={type}
              functionWatched={handleWatButtonClick}
            />
          </WatchedWrap>
          <Year>
            <p>
              {data.release_date
                ? data.release_date.slice(0, 4)
                : data.first_air_date?.slice(0, 4)}
            </p>
          </Year>
        </Info>
      </Container>
      {data.name || data.title ? (
        <TitleWrapper theme={theme}>
          {data.name || data.title.length < 36
            ? data.title || data.name
            : data.title.slice(0, 35) + "..." || data.name.slice(0, 35) + "..."}
        </TitleWrapper>
      ) : null}
      {release === "show" && (
        <TitleWrapper>
          {data.first_air_date?.split("-").reverse().join("-") ||
            data.release_date?.split("-").reverse().join("-")}
        </TitleWrapper>
      )}
      <NewCardModal
        handleModalClose={handleModalClose}
        createOrLogin={createOrLogin}
      />
    </Wrapper>
  );
};

export default TrendingCard;

const Wrapper = styled.div`
  width: ${({ theme }) =>
    theme === "dark" ? "250px" : theme === "oscar" ? "100%" : "320px"};
  height: ${({ theme }) =>
    theme === "dark" ? "200px" : theme === "oscar" ? "100%" : "250px"};
  margin: 0 10px;
  display: ${({ bg }) => bg === null && "none"};
`;

const TitleWrapper = styled.div`
  text-align: center;
  color: ${({ theme }) =>
    theme === "dark" || theme === "oscar" ? "#fff" : "black"};
  font-size: ${({ theme }) =>
    theme === "dark" || theme === "oscar" ? "12px" : "16px"};
  margin-bottom: 0;
  margin-top: 10px;
  font-weight: bold;
`;

const Container = styled.div.attrs((props) => ({
  style: {
    display: "flex",
    backgroundImage: props.bg
      ? `url(https://www.themoviedb.org/t/p/w780${props.bg})`
      : `url(https://www.themoviedb.org/t/p/original${props.bg})`,
  },
}))`
  width: ${({ theme }) =>
    theme === "dark" ? "99%" : theme === "oscar" ? "280px" : "320px"};
  height: ${({ theme }) =>
    theme === "dark" ? "70%" : theme === "oscar" ? "160px" : "200px"};
  border-radius: 7px;
  border: 1px solid #000;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 768px) {
    height: ${({ theme }) => (theme === "dark" ? "80%" : "70%")};
    margin-top: 15px;
    background-image: ${({ bg, poster, theme }) =>
      (theme === "dark" || theme === "oscar") && poster !== null
        ? `url(https://www.themoviedb.org/t/p/original${poster})`
        : bg === null && poster === null
        ? `url(${noImage})`
        : "none"};
  }
`;

const Info = styled.div.attrs((data) => ({
  style: {
    display: data.bg === null && data.rating === 0 ? "none" : "flex",
    color: data.categ === "categories" ? "white" : "black",
  },
}))`
  height: 100%;
  width: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;

  h2 {
    text-align: center;
    font-size: 18px;
    margin: 10px 10px 0;
  }
`;

const Year = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #000;

  p {
    font-size: 10px;
    margin: 0;
    color: #fff;
    font-weight: bold;
  }
`;

const FavButtonWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  z-index: 3;
`;

const Rating = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ rating }) => (rating >= 6 ? "green" : "red")};
  border-radius: 50%;
  height: 30px;
  width: 30px;
  h1 {
    color: white;
    text-align: center;
    font-size: 12px;
  }
`;

const HearthIcon = styled(AiOutlineHeart)`
  font-size: 15px;
  color: #000;
`;

const WatchedWrap = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #000;
  color: black;
  cursor: pointer;
  overflow: hidden;
`;
