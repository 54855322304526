import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import AvatarPage from "./AvatarPage";
import AuthContext from "../../context/User/AuthContext";

const UserDetails = (props) => {
  const [showAvatarPage, setShowAvatarPage] = useState(false);
  const { imgUrl } = useContext(AuthContext);

  const updateHandler = () => {
    setShowAvatarPage(!showAvatarPage);
  };

  if (showAvatarPage) {
    return <AvatarPage showAvatarPageHandler={updateHandler} />;
  } else {
    return (
      <Wrap>
        <Details>
          <h4>User Details:</h4>
          <WrapPhotoAndInfo>
            <WrapUserInfo>
              <h3>
                Username:{" "}
                <span>{props.user?.username || props.user?.displayName}</span>
              </h3>
              <h3>
                Email: <span>{props.user?.email}</span>
              </h3>
            </WrapUserInfo>
            <WrapProfilePhoto>
              <ImageWrapper content="no-referrer" image={imgUrl} />
              <Updater onClick={updateHandler}>Update Photo</Updater>
            </WrapProfilePhoto>
          </WrapPhotoAndInfo>
        </Details>
      </Wrap>
    );
  }
};

export default UserDetails;

const Wrap = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  margin-left: 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 300px;
    margin-left: 0;
  }
`;

const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  h4 {
    text-align: center;
    font-size: 22px;
    border-bottom: 1px solid black;
  }

  h3 {
    margin: 5px 20px;
    color: red;
    font-size: 18px;
  }

  span {
    color: black;
  }
  @media screen and (max-width: 768px) {
    h4 {
      font-size: 18px;
    }
    h3 {
      margin: 5px 20px;
      color: red;
      font-size: 12px;
    }
  }
`;

const WrapPhotoAndInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapUserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapProfilePhoto = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
`;

const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  border: 1px solid #000;
  border-radius: 7px;
  background-image: url(${(props) => props.image});
  background-size: cover;
`;

const Updater = styled.div`
  cursor: pointer;
  border: 1px solid black;
  border-radius: 8px;
  padding: 3px;
  margin-top: 10px;
  text-align: center;
`;
