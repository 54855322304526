import { css } from "styled-components";

export const UniversalButtonStyles = css`
  ${
    "" /* color:${props => props.cat === props.category ? 'powderblue' : '#000'};
background-color:${props => props.cat === props.category ? '#000' : '#fff'}; */
  }
  ${"" /* min-width:100px; */}
  height:30px;
  border: none;
  box-shadow: 2px 2px 2px #888888;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 20px;
  cursor: pointer;
  font-size: 14px;
  padding: 0 5px;

  &:hover {
    background: #222;
    color: powderblue;
  }

  @media screen and (max-width: 768px) {
    margin: 5px;
  }
`;
