import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import {
  MdOutlineDoneOutline as WatchedIcon,
  MdAccountCircle as Account,
} from "react-icons/md";
import {
  AiOutlineHeart as HearthIcon,
  AiOutlineUnorderedList as ListIcon,
  AiOutlineEye as EyeIcon,
} from "react-icons/ai";
import Favourites from "../components/UserPageComponents/Favourites";
import UserDetails from "../components/UserPageComponents/UserDetails";
import AuthContext from "../context/User/AuthContext";
import SizeContext from "../context/SizeContext";
import UserNavButton from "../components/UserPageComponents/UserNavButton";
import ListComponent from "../components/UserPageComponents/ListComponent";
import WatchedMovies from "../components/UserPageComponents/WatchedComponent";
import WatchlistComponent from "../components/UserPageComponents/WatchlistComponent";
import HeaderBackdrop from "../components/HeaderBackdrop/HeaderBackdropcomponent";

const UserPage = () => {
  const { user } = useContext(AuthContext);
  const { isSmall } = useContext(SizeContext);
  const [myLink, setMyLink] = useState("");
  const navigate = useNavigate();
  const { option } = useParams();

  // Update myLink state when localStorage is updated
  useEffect(() => {
    setMyLink(option);

    if (!user) {
      navigate("/");
    }
  }, [user, option]);

  const iconStyle = {
    height: "30px",
    width: "30px",
  };

  const buttons = [
    { name: "Watchlist", icon: <EyeIcon style={iconStyle} /> },
    { name: "Lists", icon: <ListIcon style={iconStyle} /> },
    { name: "Favourites", icon: <HearthIcon style={iconStyle} /> },
    { name: "Watched", icon: <WatchedIcon style={iconStyle} /> },
    { name: "Account", icon: <Account style={iconStyle} /> },
  ];

  const btnClickHandler = (input) => {
    setMyLink(input);
  };

  const buttonList = isSmall ? (
    <NewButtonsWrapper>
      {buttons.map((btn, idx) => (
        <NewButton
          key={idx}
          onClick={() => btnClickHandler(btn.name)}
          className={myLink === btn.name ? "active" : ""}
        >
          {btn.name}
        </NewButton>
      ))}
    </NewButtonsWrapper>
  ) : (
    <UserPageNavigation>
      {buttons.map((btn, idx) => (
        <UserNavButton
          key={idx}
          list={btn.name}
          icon={btn.icon}
          btnClickHandler={btnClickHandler}
          btnClicked={myLink}
        />
      ))}
    </UserPageNavigation>
  );

  return (
    <>
      <HeaderBackdrop />
      <Master>
        <Standard>
          <UserPageContainer>
            {isSmall &&
              buttons.map(
                (btn, idx) =>
                  btn.name === myLink && (
                    <ListTitle key={idx}>
                      {btn.icon}
                      {btn.name}
                    </ListTitle>
                  )
              )}

            {buttonList}

            <Favourites />

            <WatchlistComponent show={myLink === "Watchlist"} />
            <ListComponent buttonClicked={myLink} />
            <Favourites lists="favourites" show={myLink === "Favourites"} />
            <WatchedMovies show={myLink === "Watched"} />
            <UserListsWrapper show={myLink === "Account" ? "show" : "noShow"}>
              <UserDetails user={user} />
            </UserListsWrapper>
          </UserPageContainer>
        </Standard>
      </Master>
    </>
  );
};

export default UserPage;

const Master = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Standard = styled.div`
  ${"" /* width: 1180px; */}
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
  }
`;

const UserPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;

  @media screen and (max-width: 768px) {
    min-height: 50vh;
    flex-direction: column;
    width: 100vw;
    padding: 0;
    justify-content: flex-start;
    align-items: center;
  }
`;

const UserPageNavigation = styled.div`
  width: 150px;
  height: 400px;
  margin: 20px 0 20px 50px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
    max-height: 180px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
`;

const NewButtonsWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  background-color: #000;
  border-bottom: 1px solid #000;
  left: 0;
  position: absolute;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  z-index: 50;
`;

const NewButton = styled.div`
  width: 200px;
  height: 70%;
  display: flex;
  margin: 0 5px;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
  z-index: 52;
  cursor: pointer;

  /* Add underline effect on click */
  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: red;
    opacity: 0;
    transition: opacity 0.3s ease, bottom 0.3s ease;
  }

  /* Add underline effect on click */
  &.active::after {
    opacity: 1;
    bottom: -1px;
  }

  /* Update hover effect to keep underline visible */
  &:hover::after {
    opacity: 1;
    bottom: -1px;
  }
`;
const ListTitle = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #000;
  color: #fff;
  text-shadow: ${(props) =>
    props.focused ? "2px 2px 2px red" : "2px 2px 2px gray"};
`;

const UserListsWrapper = styled.div`
  height: 500px;
  width: 80%;
  display: ${(props) => props.show !== "show" && "none"};

  h1 {
    margin-left: 20px;
  }
`;
