import React, { useState, useContext, useEffect } from "react";
import styled, { css } from "styled-components";

import AuthContext from "../../context/User/AuthContext";

import { SlArrowRightCircle as Arrow } from "react-icons/sl";

const AvatarPage = ({ showAvatarPageHandler }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const { handleImageUploadToFirebase, imgUrl } = useContext(AuthContext);

  function handleImageUpload() {
    if (file === null) {
      alert("Please select an image to upload");
    } else {
      const reader = new FileReader();

      reader.onload = function (event) {
        const img = new Image();
        img.onload = function () {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = 400;
          const MAX_HEIGHT = 300;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(function (blob) {
            // Upload the resized image to Firebase
            handleImageUploadToFirebase(blob);
          }, file.type);
        };

        img.src = event.target.result;
      };

      reader.readAsDataURL(file);
      showAvatarPageHandler();
    }
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      setError(null);
      setPreviewImage(URL.createObjectURL(event.target.files[0]));
    } else {
      setFile(null);
      setError("Please select a valid image file (JPEG/PNG/GIF).");
    }
  };

  return (
    <MainWrapper>
      <ClosingWrapper>
        <div onClick={showAvatarPageHandler}>X</div>
      </ClosingWrapper>

      <ImageSelectorWrapper>
        <h3>Update your Avatar Image</h3>
        <input
          type="file"
          onChange={(e) => {
            handleFileChange(e);
          }}
        />
        <button onClick={handleImageUpload}>Upload</button>
        {error && <div>{error}</div>}
      </ImageSelectorWrapper>
      <ImagesWrapper>
        <div>
          <CurrentImage src={imgUrl} />
        </div>

        <Arrow />
        <div>
          {previewImage != null ? (
            <PreviewImage alt="preview image" src={previewImage} />
          ) : (
            <EmptyBox></EmptyBox>
          )}
        </div>
      </ImagesWrapper>
    </MainWrapper>
  );
};

export default AvatarPage;

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  height: 400px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-top: 20px;
  }
`;

const ImageSelectorWrapper = styled.div`
  height: 100px;
  margin: 10px 0;
  position: relative;
`;

const ImagesWrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ImageStyle = css`
  width: 100px;
  height: 100px;
  border-radius: 7px;
  box-shadow: 3px 3px 2px #333;
`;
const CurrentImage = styled.img`
  ${ImageStyle}
`;
const PreviewImage = styled.img`
  ${ImageStyle}
`;

const EmptyBox = styled.div`
  ${ImageStyle}
`;

const ClosingWrapper = styled.div`
  width: 350px;
  height: 20px;
  display: flex;
  justify-content: flex-end;

  div {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 50%;
    border: 1px solid #000;
    cursor: pointer;

    &:hover {
      color: red;
      border: 1px solid red;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
