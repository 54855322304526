import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import FirstLoaderContext from "../../context/FirstLoaders/FirstLoaders";
import SizeContext from "../../context/SizeContext";
import CategoriesWrapper from "../CardWrapper/CategoriesWrapper";
import CategoriesSelectionWrapper from "../CardWrapper/CategoriesSelectionWrapper";
import HeaderBackdrop from "../HeaderBackdrop/HeaderBackdropcomponent";
import Upcoming from "../UpcomingComponent/Upcoming";
import MasterCardWrapper from "../CardWrapper/MasterCardWrapper";
import TrendingComponent from "../Trending/TrendingComponent";
import LoadingCircle from "../Loading/LoadingCircle";
import MainCardWrapper from "../MainCardWrapperComponent/MainCardWrapper";
import useLocalStorage from "../../hooks/useLocalStorage";
import Oscar from "../Oscar/Oscar";
import NetworksComponent from "../NetworksComponent/NetworksComponent";
import SearchParameters from "../SearchParameters/SearchParameters";

const Main = () => {
  const { bestLast20, trendingShows, trendingMovies } =
    useContext(FirstLoaderContext);

  const { handleResize } = useContext(SizeContext);
  const [isLoading, setIsLoading] = useState(true);
  const [videoType, setVideoType] = useLocalStorage("videoType", "Trailer");
  const [cat, setCat] = useLocalStorage("category", "Horror");

  useEffect(() => {
    handleResize();
    setIsLoading(false);
    setVideoType("Trailer");
    setCat("Horror");
  }, [handleResize]);

  if (isLoading) {
    return <LoadingCircle />;
  } else {
    return (
      <Master>
        <HeaderBackdrop />
        <Standard>
          <MainWrapper id="mainWrapper">
            <CardWrapperHolder>
              <SearchParameters />
              <div id="trending" />

              <MasterCardWrapper
                movieCard={"new"}
                id="trending_wrapper"
                movies={trendingShows}
                type="tv"
                category="Trending shows"
                autoplayEnabled={true}
              />
              <Upcoming />
              <div id="popular" />
              <MainCardWrapper content="MOVIES" type="movie" />
              <NetworksComponent />
              <MainCardWrapper content="SHOWS" type="tv" />
              <TrendingComponent />
              <MasterCardWrapper
                movieCard={"new"}
                movies={trendingMovies}
                type="movie"
                category="Trending movies"
                autoplayEnabled={true}
              />
              <CategoriesWrapper
                categ="categories"
                side="center"
                name="Last 20 yrs ||"
                movies={bestLast20}
                type="movie"
                content={"MOVIES"}
              />
              <Oscar />
              <CategoryTitle>Best in:</CategoryTitle>
              <CategoriesSelectionWrapper categ="categories" />
            </CardWrapperHolder>
          </MainWrapper>
        </Standard>
      </Master>
    );
  }
};
export default React.memo(Main);

const Master = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1180px) {
    display: block;
  }
`;

const Standard = styled.div`
  max-width: 1180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #edeef2;
`;

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const CardWrapperHolder = styled.div`
  width: 95%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const CategoryTitle = styled.div`
  width: 97%;
  text-align: center;
  padding: 0 20px;
  margin: 30px 0 0 0;
  border-bottom: solid black 2px;

  @media screen and (max-width: 768px) {
    width: auto;
    margin-top: 30px;
    ${
      "" /* margin: 16px 45px 16px 0;
  padding:5px 90px ; */
    }
  }
`;
