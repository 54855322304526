import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import TmdbContext from "../../context/TmdbContext";
import SizeContext from "../../context/SizeContext";
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules

import { Pagination, FreeMode, Navigation, Thumbs } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const MovieTrailers = ({ movieid, screentype }) => {
  const { getVideos, videos, videoType, setVideo } = useContext(TmdbContext);
  const { trailersCount } = useContext(SizeContext);
  // const [videoType, setVideoType] = useState('Trailer');
  const [active, setActive] = useState(false);
  const [count, setCount] = useState(0);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // Checking how many video is available and set the thumbnail number on that

  const checkVideoCount = (videoType) => {
    const count = videos.filter((video) => video.type === videoType)?.length;
    if (count < 2) {
      setCount(200);
    } else {
      setCount(400);
    }
  };
  let types = [...new Set(videos.map((video) => video.type))];

  const buttonHandler = (type) => {
    setVideo(type);
    setActive(!active);
    checkVideoCount(type);
  };
  // Checking if there is a trailer and if not go to other available video type

  const checkIfTrailerAvailable = async () => {
    const filteredVideos = await Promise.all([
      videos.filter((obj) => obj.type === "Trailer"),
      videos.filter((obj) => obj.type !== ""),
    ]);

    const available = await videos.filter((obj) => obj.type === "Trailer")
      ?.length;
    const newType = await videos.filter((obj) => obj.type !== "");
  };
  useEffect(() => {
    setVideo("Trailer");
  }, []);

  useEffect(() => {}, [trailersCount]);

  useEffect(() => {
    getVideos(movieid, screentype);
    checkVideoCount(videoType);
    checkIfTrailerAvailable();
  }, [movieid, videoType]);

  if (videos?.length > 0) {
    return (
      <Main>
        <ButtonsWrapper>
          {types?.length > 0 &&
            types.map((type, idx) => (
              <Button
                type={type}
                videoType={videoType}
                onClick={() => {
                  buttonHandler(type);
                }}
                key={idx}
              >
                {type}
              </Button>
            ))}
        </ButtonsWrapper>

        <>
          <SwiperStyles
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
              "--swiper-pagination-bullet": "#eee",
            }}
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            {videos.map(
              (video, idx) =>
                video.type === videoType && (
                  <SwiperSlideStyles key={idx}>
                    <YouFrame
                      src={`https://www.youtube-nocookie.com/embed/${video.key}`}
                      title="My video"
                      frameBorder="0"
                      allow="web-share;fullscreen"
                    ></YouFrame>
                  </SwiperSlideStyles>
                )
            )}
          </SwiperStyles>

          <SwiperStyles
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
              "--swiper-pagination-bullet-inactive-color": "#eee",
              width: count,
            }}
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={20}
            slidesPerView="auto"
            freeMode={true}
            loopedSlides={2}
            watchSlidesProgress={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Navigation, Thumbs, Pagination]}
          >
            <Thumb>
              {videos.map(
                (video, idx) =>
                  video.type === videoType && (
                    <SwiperSlideStyles key={idx} thumbsize="true" count={count}>
                      <img
                        src={`https://i.ytimg.com/vi/${video.key}/hqdefault.jpg`}
                      />
                    </SwiperSlideStyles>
                  )
              )}
            </Thumb>
          </SwiperStyles>
        </>
      </Main>
    );
  }
};

export default MovieTrailers;

const Thumb = styled.div``;

const Main = styled.main`
  background: #000;
  font-size: 14px;
  color: #fff;
  margin: -60px 0 50px 0;
  padding: 0;
  @media screen and (max-width: 768px) {
    margin: -60px 0 30px 0;
  }
`;

const SwiperStyles = styled(Swiper)`
  width: 80%;
  padding: 40px 0;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const SwiperSlideStyles = styled(SwiperSlide)`
  background-position: center;
  background-size: cover;
  border: ${({ thumbsize }) => (thumbsize === "true" ? "1px solid #fff" : "")};
  width: ${({ thumbsize, count }) =>
    thumbsize === "true" && count === 200 ? "200px" : "50%"};
  height: ${({ thumbsize }) => (thumbsize === "true" ? "100px" : "300px")};

  margin: 30px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ thumbsize }) => thumbsize === "true" && "pointer"};

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 768px) {
    img {
      width: 100%;
    }
  }
`;

const YouFrame = styled.iframe`
  width: ${({ thumbsize }) => (thumbsize === "true" ? "500px" : "700px")};
  height: ${({ thumbsize }) => (thumbsize === "true" ? "150px" : "400px")};
  margin: 20px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    height: 300px;
    margin: 0;
  }
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;

  @media screen and (max-width: 768px) {
    padding-top: 100px;
    flex-wrap: wrap;
  }
`;

const Button = styled.button`
  color: ${(props) => (props.type === props.videoType ? "green" : "#fff")};
  background-color: ${(props) =>
    props.type === props.videoType ? "#fff" : "#000"};
  border: 1px solid #fff;
  width: 150px;
  height: 25px;
  border-radius: 8px;
  margin: 20px;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: #000;
    border: 1px solid green;
  }

  @media screen and (max-width: 768px) {
    min-width: 80px;
    margin: 5px;
    &:active {
      background-color: #000;
      border: 1px solid green;
      color: #fff;
    }
  }
`;
