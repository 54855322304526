import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from "firebase/auth";
import { getFirestore, Timestamp, arrayUnion } from "firebase/firestore";

import { getAnalytics, logEvent } from "firebase/analytics";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyCUutFhyCCb6wutC53C2QP8pXXQ19iE8Go",
  authDomain: "mycinema-15e12.firebaseapp.com",
  projectId: "mycinema-15e12",
  storageBucket: "mycinema-15e12.appspot.com",
  messagingSenderId: "248934584964",
  appId: "1:248934584964:web:ab53546b22aeb0c12828d1",
  measurementId: "G-093BW2MJ53",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, "notification_received");
export const storage = getStorage(app);

export const auth = getAuth(app);

export const db = getFirestore(app);

export const passwordReset = async (email) => {
  return await sendPasswordResetEmail(auth, email);
};
