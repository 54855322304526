import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import AuthContext from "../../context/User/AuthContext";

const CookieConsentBanner = ({ onAccept }) => {
  const [consentGiven, setConsentGiven] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [necessaryCookies, setNecessaryOnly] = useState(true);
  const [analyticsCookies, setAnalyticsEnabled] = useState(false);
  const [marketingCookies, setMarketingEnabled] = useState(false);
  const { user } = useContext(AuthContext);

  const handleAccept = () => {
    // Save consent preferences to session storage
    // sessionStorage.setItem(
    //   "cookieConsent",
    //   JSON.stringify({
    //     necessaryCookies,
    //     analyticsCookies,
    //     marketingCookies,
    //   })
    // );

    // Save individual preferences to session storage
    sessionStorage.setItem("necessaryCookies", necessaryCookies);
    sessionStorage.setItem("analyticsCookies", analyticsCookies);
    sessionStorage.setItem("marketingCookies", marketingCookies);

    onAccept();
  };

  const handleAcceptAll = () => {
    // Save consent preferences to session storage
    // sessionStorage.setItem(
    //   "cookieConsent",
    //   JSON.stringify({
    //     necessaryCookies,
    //     analyticsCookies,
    //     marketingCookies,
    //   })
    // );

    // Save individual preferences to session storage
    sessionStorage.setItem("necessaryCookies", necessaryCookies);
    sessionStorage.setItem("analyticsCookies", true);
    sessionStorage.setItem("marketingCookies", true);

    onAccept();
  };

  const handleSetPreferences = () => {
    setShowPreferences(true); // Toggle the showPreferences state
  };

  const handleToggleNecessaryOnly = () => {
    setNecessaryOnly(!necessaryCookies);
    // Save preference to session storage
  };

  const handleToggleAnalytics = () => {
    setAnalyticsEnabled(!analyticsCookies);
    // Save preference to session storage
  };

  const handleToggleMarketing = () => {
    setMarketingEnabled(!marketingCookies);
    // Save preference to session storage
  };

  useEffect(() => {
    // sessionStorage.setItem(
    //   "cookieConsent",
    //   JSON.stringify({
    //     necessaryCookies,
    //     analyticsCookies,
    //     marketingCookies,
    //   })
    // );

    // Save individual preferences to session storage
    console.log(user);
    if (user) {
      sessionStorage.setItem("necessaryCookies", necessaryCookies);
      sessionStorage.setItem("analyticsCookies", true);
      sessionStorage.setItem("marketingCookies", true);
    }
  }, [user]);

  return (
    !user && (
      <MainWrap show={showPreferences} consent={consentGiven}>
        {!consentGiven && !showPreferences && (
          <CookiesWrap>
            <h2>Cookie Consent</h2>
            <p>
              We use cookies to ensure you get the best experience on our
              website.
            </p>
            <Button onClick={handleAcceptAll}>Accept All</Button>
            <Button onClick={handleAccept}>Accept Necessary Only</Button>
            <Button onClick={handleSetPreferences}>Set Preferences</Button>
          </CookiesWrap>
        )}
        {showPreferences && (
          <CookiesWrap>
            <h2>Cookie Preferences</h2>
            <p>Select your cookie preferences:</p>
            <OptionWrap>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={necessaryCookies}
                    onChange={handleToggleNecessaryOnly}
                    disabled
                  />
                  Necessary Only - These cookies are essential for the website
                  to function properly.
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={analyticsCookies}
                    onChange={handleToggleAnalytics}
                  />
                  Analytics Cookies - These cookies allow us to analyze site
                  traffic and usage.
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={marketingCookies}
                    onChange={handleToggleMarketing}
                  />
                  Marketing Cookies - These cookies are used to track visitors
                  across websites. The intention is to display ads that are
                  relevant and engaging for the individual user.
                </label>
              </div>
            </OptionWrap>
            <button onClick={handleAccept}>Save Preferences</button>
          </CookiesWrap>
        )}
      </MainWrap>
    )
  );
};

export default CookieConsentBanner;

const MainWrap = styled.div`
  display: ${({ show, consent }) => (show || consent ? "none" : "flex")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  boxsizing: border-box;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CookiesWrap = styled.div`
  width: 60%;
  background: black;
  padding: 100px;

  label {
    padding: 10px 0;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

const OptionWrap = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;

  @media screen and (max-width: 768px) {
    height: 250px;
  }
`;

const Button = styled.button`
  width: 200px;
  margin-right: 10px;
  border-radius: 8px;
  padding: 3px 0;

  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
`;
