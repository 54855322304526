const reviewsReducer = (state, action) => {
  let updateType;

  switch (action.type) {
    case "ADD_REVIEW_REQUEST":
      return { ...state, loading: true };

    case "ADD_REVIEW_SUCCESS":
      updateType = action.payload.type === "movie" ? "movies" : "shows";
      return {
        ...state,
        reviews: {
          ...state.reviews,
          [updateType]: {
            ...state.reviews[updateType],
            [action.payload.itemId]: {
              ...state.reviews[updateType][action.payload.itemId],
              [action.payload.reviewId]: action.payload,
            },
          },
        },
        loading: false,
      };

    case "FETCH_REVIEW_SUCCESS":
      updateType = action.payload.type === "movie" ? "movies" : "shows";
      return {
        ...state,
        reviews: {
          ...state.reviews,
          [updateType]: {
            ...state.reviews[updateType],
            [action.payload.movieId]: action.payload.reviews,
          },
        },
      };

    case "ADD_REVIEW_FAILURE":
    case "FETCH_REVIEW_FAILURE":
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
};
export default reviewsReducer;
