import React, { useContext, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import FirstLoaderContext from "../../context/FirstLoaders/FirstLoaders";
import { useNavigate } from "react-router-dom";

const Upcoming = () => {
  const { upcoming } = useContext(FirstLoaderContext);
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    // Function to update the current image index
    const updateImageIndex = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % upcoming.length);
    };

    // Set up an interval to update the image index every 10 seconds
    const intervalId = setInterval(updateImageIndex, 10000);

    // Clean up the interval when the component unmounts or when upcoming data changes
    return () => {
      clearInterval(intervalId);
    };
  }, [upcoming]);

  const goToMoviePage = (id) => {
    navigate(`../detailspage/${"movie"}/${id}`);
  };
  if (upcoming.length !== 0) {
    return (
      <Wrapper
        id="upcoming"
        onClick={() => {
          goToMoviePage(upcoming[currentImageIndex]?.id);
        }}
      >
        <UpcomingWrapper>
          <ComingSoonDiv>
            <h3>
              <span>C</span>
              <span>O</span>
              <span>M</span>
              <span>I</span>
              <span>N</span>
              <span>G</span>
              <span> </span>

              <span>S</span>
              <span>O</span>
              <span>O</span>
              <span>N</span>
            </h3>
          </ComingSoonDiv>
          <TitleAndImageWrapper>
            <TitleWrapper>
              {/* Render the title and release date */}
              <Title>{upcoming[currentImageIndex]?.title}</Title>

              <ReleaseDate>
                {upcoming[currentImageIndex]?.release_date
                  .split("-")
                  .reverse()
                  .join("-")}
              </ReleaseDate>
            </TitleWrapper>
            <BackdropImage
              bg={upcoming[currentImageIndex]?.backdrop_path}
              poster={upcoming[currentImageIndex]?.poster_path}
            >
              {/* Render the backdrop image */}
            </BackdropImage>
          </TitleAndImageWrapper>
        </UpcomingWrapper>
      </Wrapper>
    );
  }
};

export default Upcoming;

const Wrapper = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  flex-direction: column;
  cursor: pointer;
`;

const UpcomingWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 7px;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 0 20px 0;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const TitleAndImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 150px;
  padding: 2px 0;
`;

const loading = keyframes`
to {
    text-shadow: 20px 0 30px #ff0266;
    color: #ff0266;
  }
`;

const ComingSoonDiv = styled.div`
  text-align: center;
  height: auto;
  width: 90%;
  color: red;
  background-color: #000;

  h3 {
    font-size: 20px;
    margin: 0;
    padding: 0;
    font-weight: 100;
    @media (max-width: 768px) {
      font-size: 20px;
    }
    span {
      color: #faebd7;
      text-shadow: 0 0 0 #faebd7;
      animation: ${loading} 2s ease-in-out infinite alternate;
      :nth-child(2) {
        animation-delay: 0.1s;
      }
      :nth-child(3) {
        animation-delay: 0.2s;
      }
      :nth-child(4) {
        animation-delay: 0.3s;
      }
      :nth-child(5) {
        animation-delay: 0.4s;
      }
      :nth-child(6) {
        animation-delay: 0.5s;
      }
      :nth-child(7) {
        animation-delay: 0.6s;
      }
      :nth-child(8) {
        animation-delay: 0.7s;
      }
      :nth-child(9) {
        animation-delay: 0.8s;
      }
      :nth-child(10) {
        animation-delay: 0.9s;
      }
      :nth-child(11) {
        animation-delay: 1s;
      }
      :nth-child(12) {
        animation-delay: 1.1s;
      }
    }
  }
`;

const TitleWrapper = styled.div`
  height: 150px;
  width: 60%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 1s ease-out;
`;

const BackdropImage = styled.div`
  height: 150px;
  width: 30%;

  border-radius: 7px;
  background-image: ${({ bg }) =>
    `url(${`https://www.themoviedb.org/t/p/original${bg}`})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 1s ease-out;

  @media screen and (max-width: 768px) {
    background-image: ${({ poster }) =>
      `url(${`https://www.themoviedb.org/t/p/original${poster}`})`};
  }
`;
const Title = styled.h3`
  /* Add styles for the title */
  margin: 0;
  @media screen and (max-width: 768px) {
    font-size: 15px;
    max-width: 90%;
  }
`;

const ReleaseDate = styled.p`
  /* Add styles for the release date */
  margin: 8px 0 0;
`;
