import React from "react";
import styled from "styled-components";

const Help = () => {
  return (
    <MainWrap>
      <Opening>Help Page</Opening>
    </MainWrap>
  );
};

export default Help;

const MainWrap = styled.div`
  min-height: 60vh;
  width: 90%;
  padding: 150px 30px;
  li {
    padding-bottom: 30px;
  }
`;

const Opening = styled.div``;
