import React, { useState, useContext } from "react";
import styled, { css } from "styled-components";
import { ImStarEmpty, ImStarHalf, ImStarFull } from "react-icons/im";
import UserContext from "../../context/User/UserContext";
import { IoMdClose } from "react-icons/io";
import AuthContext from "../../context/User/AuthContext";

const Rating = ({ movie, screenType, handleRatingConfirm, allDetails }) => {
  const [rating, setRating] = useState(0);
  const {
    removeDataFromWatchlist,
    closeRatingFunc,
    addToRatings,
    updateWatchlist,
  } = useContext(UserContext);
  const { removeDetailsFromState, addDetailsToState } = useContext(AuthContext);
  const handleStarClick = (clickedRating) => {
    if (rating === clickedRating - 0.5) {
      setRating(clickedRating);
    } else {
      setRating(clickedRating - 0.5);
    }
  };

  const handleConfirmClick = () => {
    if (handleRatingConfirm) {
      handleRatingConfirm(rating);
    }

    addToRatings(movie, screenType, rating);
    removeDataFromWatchlist(movie, screenType, "watchlist");
    updateWatchlist(movie, screenType, "watched");

    removeDetailsFromState("watchlist", screenType, movie);

    // Add the rating to props.allDetails
    const updatedAllDetails = { ...allDetails, rating: rating };
    // Pass the updated object with the rating included
    addDetailsToState("watched", screenType, updatedAllDetails);
    closeRatingFunc();
  };

  return (
    <MainWrap>
      <RatingWrapper>
        <CloseIcon onClick={closeRatingFunc} />
        <TitleWrapper>Choose your rating</TitleWrapper>
        {rating > 0 && (
          <SelectedRatingDisplay>
            <LargeStar />
            <RatingNumber>{rating}</RatingNumber>
          </SelectedRatingDisplay>
        )}

        <StarBox>
          {[...Array(10)].map((_, index) => {
            const starValue = index + 1;
            let starIcon = <EmptyStar />;
            if (starValue <= rating) {
              starIcon = <FullStar />;
            } else if (starValue - 0.5 <= rating) {
              starIcon = <HalfStar />;
            }
            return (
              <StarWrapper
                key={index}
                onClick={() => handleStarClick(starValue)}
              >
                {starIcon}
              </StarWrapper>
            );
          })}
        </StarBox>
        <ConfirmButton onClick={handleConfirmClick}>Confirm</ConfirmButton>
      </RatingWrapper>
    </MainWrap>
  );
};

export default Rating;

const MainWrap = styled.div`
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
`;

const RatingWrapper = styled.div`
  width: 350px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 7px;
  position: relative;
`;

const TitleWrapper = styled.h3`
  margin: 0;
  text-align: center;
  color: #fff;
`;
const StarBox = styled.div`
  display: flex;
`;
const StarWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
`;

const StarStyle = css`
  height: 30px;
  width: 30px;
`;

const EmptyStar = styled(ImStarEmpty)`
  ${StarStyle};
  color: #fff;
`;

const HalfStar = styled(ImStarHalf)`
  ${StarStyle};
  color: gold;
`;

const FullStar = styled(ImStarFull)`
  ${StarStyle};
  color: gold;
`;

const ConfirmButton = styled.button`
  background-color: #000;
  color: #fff;
  border: 1px solid gold;
  border-radius: 4px;
  font-weight: bold;
  padding: 6px 9px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #ffd700;
    color: #000;
    border-color: #fff;
  }
`;

const CloseIcon = styled(IoMdClose)`
  position: absolute;
  top: 2%;
  right: 2%;
  color: white;
  z-index: 500;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    transform: scale(0.9);
  }
`;
const SelectedRatingDisplay = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gold;
  width: 70px;
  height: 70px;
`;

const LargeStar = styled(ImStarFull)`
  /* font-size: 52px; */
  width: 50px;
  height: 50px;
  position: absolute;
`;

const RatingNumber = styled.div`
  position: absolute;
  color: #000;
  font-size: 16px;
  z-index: 1;
  top: 40%;
  font-weight: bold;
`;
