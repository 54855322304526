import React from "react";
import styled from "styled-components";
import { BiCopyright } from "react-icons/bi";
import { BsInstagram, BsFacebook, BsTwitter, BsYoutube } from "react-icons/bs";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const clickHandler = (param) => {
    navigate(`/${param}`);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <FooterContainer>
      <Copyright>
        <BiCopyright />
        <a role="link" onClick={() => openInNewTab("https://gndtdigital.com")}>
          {new Date().getFullYear()} GNDTDIGITAL. All rights reserved.
        </a>
      </Copyright>
      <Socials>
        <BsInstagram size={"25px"} style={{ margin: 10, cursor: "pointer" }} />
        <BsFacebook size={"25px"} style={{ margin: 10, cursor: "pointer" }} />
        <BsTwitter size={"25px"} style={{ margin: 10, cursor: "pointer" }} />
        <BsYoutube size={"25px"} style={{ margin: 10, cursor: "pointer" }} />
      </Socials>
      <FooterLinks>
        <a onClick={() => clickHandler("communitypolicy")}>Community Policy</a>
        <a onClick={() => clickHandler("terms")}>Conditions of Use</a>
        <a onClick={() => clickHandler("privacy")}>Privacy Policy</a>
        <a onClick={() => clickHandler("help")}>Help</a>
        <a onClick={() => clickHandler("contact")}>Contact</a>
      </FooterLinks>
      <TmdbRights>
        This product uses the TMDB API but is not endorsed or certified by TMDB.
      </TmdbRights>
      <WhatsappContainer>
        <WhatsAppWidget
          phoneNumber="447511549707"
          companyName="MYCINEMA Support"
          sendButtonText="Open chat on WhatsApp"
        />
      </WhatsappContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  color: white;
  margin-top: 20px;
`;

const Socials = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

const TmdbRights = styled.div`
  color: white;
  font-size: 10px;
  margin-top: 10px;
`;

const Copyright = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  cursor: pointer;

  a {
    padding-left: 2px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const FooterLinks = styled.div`
  font-size: 16px;
  cursor: pointer;

  a {
    text-decoration: none;
    color: white;
    margin: 20px;

    @media screen and (max-width: 768px) {
      margin: 0 10px;
      padding-top: 10px;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const WhatsappContainer = styled.div`
  position: absolute;
  z-index: 9999;
`;

export default Footer;
