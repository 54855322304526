import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/images/noImage.png";
import NewCardModal from "../CardModal/NewCardModal";
import WatchedHandler from "../WatchedHandler/WatchedHandler";
import ToFavouriteButton from "../FavWishAdd/ToFavouriteButton";

const Card = React.memo((props) => {
  const [createOrLogin, setCreateOrLogin] = useState();
  const navigate = useNavigate();

  const navigateToDetailsPage = () => {
    navigate(`../detailspage/${props.type}/${props.id}`);
  };

  const handleModalClose = () => {
    setCreateOrLogin(false);
  };

  const handleWatButtonClick = (event) => {
    event.stopPropagation();

    setCreateOrLogin(true);
  };

  return (
    <>
      {props.title != null && (
        <MainWrapper>
          <Container
            onClick={navigateToDetailsPage}
            bg={props.bg}
            id={props.id}
            rating={props.rating}
            release={props.release}
          ></Container>
          {props.page === "detailsPage" && (
            <Character>
              <span>as</span>
              <h6>{props.character ? props.character : "N/N"}</h6>
            </Character>
          )}
          {props.title && (
            <Title title={props.title} wrapper={props.wrapper}>
              {props.title.length > 19
                ? props.title.slice(0, 19) + "..."
                : props.title}{" "}
            </Title>
          )}
          <Info
            categ={props.categ}
            bg={props.bg}
            id={props.id}
            rating={props.rating}
            release={props.release}
          >
            {props.rating != 0 && (
              <Rating rating={props.rating}>
                <h1>{props.rating?.toFixed(1)}</h1>
              </Rating>
            )}
            <h2>{props.release?.slice(0, 4)}</h2>
            <ToFavouriteButton
              location={"favourites"}
              id={props.id}
              type={props.type}
              allDetails={props.allDetails}
              inCard={"true"}
            />

            <WatchedButton>
              <WatchedHandler
                movie={props.id}
                allDetails={props.allDetails}
                screenType={props.type}
                functionWatched={handleWatButtonClick}
              />
            </WatchedButton>
          </Info>
        </MainWrapper>
      )}
      <NewCardModal
        handleModalClose={handleModalClose}
        createOrLogin={createOrLogin}
      />
    </>
  );
});

export default Card;

const MainWrapper = styled.div`
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 0 20px;

  @media screen and (max-width: 768px) {
    width: 140px;
    margin-right: 10px;
  }
`;

const Container = styled.div.attrs((props) => ({
  style: {
    // display: (props.bg === null && props.rating === 0 ? 'none' : 'flex'),
    backgroundImage:
      props.bg != null
        ? `url(https://www.themoviedb.org/t/p/w300${props.bg})`
        : `url(${noImage})`,
  },
}))`
  display: flex;
  width: 180px;
  height: 250px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 200px;
  }
`;

const Title = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
  text-align: center;
  color: ${({ wrapper }) => (wrapper === "blackBg" ? "white" : "black")};

  @media screen and (max-width: 768px) {
    font-size: 11px;
  }
`;

const Info = styled.div.attrs((props) => ({
  style: {
    display: props.bg === null && props.rating === 0 ? "none" : "flex",
    color: props.categ === "categories" ? "white" : "black",
  },
}))`
  justify-content: center;
  align-items: center;

  h2 {
    text-align: center;
    font-size: 18px;
    margin: 10px 10px;
  }

  @media screen and (max-width: 768px) {
    h2 {
      text-align: center;
      font-size: 14px;
      margin: 5px 5px;
    }
  }
`;

const FavButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${({ favClicked }) => (favClicked === true ? "red" : "white")};
  border: 1px solid #000;
  color: black;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`;

const WatchedButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #000;
  color: black;
  cursor: pointer;
  overflow: hidden;
  margin-left: 3px;

  @media screen and (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`;

const Rating = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ rating }) => (rating >= 6 ? "green" : "red")};
  border-radius: 50%;
  height: 30px;
  width: 30px;

  h1 {
    color: white;
    text-align: center;
    font-size: 12px;
  }

  @media screen and (max-width: 768px) {
    width: 25px;
    height: 25px;

    h1 {
      font-size: 10px;
    }
  }
`;

const Character = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h6 {
    margin: 0;
  }
  span {
    font-size: 8px;
  }
`;
