import React from "react";
import styled from "styled-components";

const Privacy = () => {
  const handleContactClick = () => {
    // Add logic to navigate to the contact page
    // For example:
    window.location.href = "/contact";
  };

  return (
    <MainWrap>
      <Opening>Privacy Policy</Opening>
      <ol>
        <li>
          Your privacy is of utmost importance to us at Mycinemahub. We are
          dedicated to safeguarding the confidentiality of your information.
        </li>
        {/* , and via the Mycinemahub API (the “API”). (below)  */}
        <li>
          This Privacy Policy is applicable to all personal data provided by you
          on mycinemahub.com (the “Website”), official Mycinemahub apps (the
          “App”). It also encompasses any information automatically gathered
          through your interactions with these platforms.
        </li>
        {/* maybe API need to be added below*/}
        <li>
          By agreeing to our Terms of Use and this Privacy Policy upon creating
          a Mycinemahub account, and by accessing and using the Website or App,
          you consent to the collection, utilization, disclosure, storage, and
          processing of your data in accordance with this Privacy Policy.
        </li>
        <li>
          We reserve the right to modify or update this Privacy Policy
          periodically, with or without prior notice to you. Your continued
          access and use of the Website or App imply your acceptance of the
          Privacy Policy currently in effect.
        </li>
        <li>
          Certain services may require the submission of personal details such
          as your email address, name, address, telephone number, gender, and
          date of birth. Additional information may be requested as needed.
          {/* When
          purchasing a subscription through Paddle, we may transmit identifying
          information to link your subscription with your account. If you link
          your Facebook or Twitter accounts, we access these platforms to
          identify friends who also use Mycinemahub. */}
        </li>
        <li>
          Cookies are utilized to collect additional usage data and operate our
          services. Session and persistent cookies are employed to keep you
          signed in, analyze interaction patterns, monitor usage, and
          personalize content. Opting out of cookie tracking is possible,
          although it may affect certain service functionalities.
        </li>
        <li>
          Users who do not subscribe may have their device information and
          interests accessed by pre-selected companies for personalized
          advertising. Consent preferences can be adjusted at any time.
        </li>
        <li>
          Log Data, including IP address, browser type, operating system, pages
          visited, and search terms, is received when interacting with our
          services. This data aids in service operation, performance
          enhancement, and security.
        </li>
        {/* <li>
          Payment information is processed by third-party payment processors,
          with identifying details shared to link payments with accounts. We do
          not retain payment data beyond what is necessary for transaction
          purposes.
        </li> */}
        <li>
          Personal data is used exclusively for service provision,
          communication, and collaboration with relevant authorities.
        </li>
        <li>
          Your personal information is kept confidential, with limited
          exceptions as outlined in the Privacy Policy.
        </li>
        <li>
          You have the right to access, correct, or update your personal data at
          any time, and may request permanent deletion of your account and
          associated information.
        </li>
        <li>
          Your personal information may be stored in the United States and other
          countries where we operate, subject to applicable laws and safeguards.
        </li>
        <li>
          We operate globally and may transfer, store, and utilize your data in
          various countries. By using our services, you consent to such global
          data processing.
        </li>
        <li>
          Personal data retention occurs within legal limits, even after account
          deactivation, until such data is lawfully disposed of.
        </li>
        <li>
          Account deactivation and permanent deletion procedures are available
          through{" "}
          <a onClick={handleContactClick} href="/contact">
            contacting us
          </a>
          .
        </li>
        {/* <li>
          Users can download their shared information using the account export
          feature.
        </li> */}
        <li>
          Users are responsible for maintaining the security of their login
          credentials and promptly reporting any unauthorized access or security
          breaches.
        </li>
        <li>
          Our site is protected by reCAPTCHA, with Google's Privacy Policy and
          Terms of Service applying.
        </li>
      </ol>
      <p>This policy was last updated in April 2024 </p>
    </MainWrap>
  );
};

export default Privacy;

const MainWrap = styled.div`
  min-height: 60vh;
  width: 90%;
  padding: 150px 30px;
  li {
    padding-bottom: 30px;
  }

  a {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: blue;
    }
  }
`;

const Opening = styled.div`
  font-weight: bold;
`;
