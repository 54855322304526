import React, { useContext, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import SizeContext from "../../context/SizeContext";
import { BiRightArrow, BiLeftArrow } from "react-icons/bi";

const Photos = (props) => {
  const [isHovering, setIsHovering] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const style = { color: "white", margin: "4px", fontSize: "20px" };
  const contentWrapper = React.useRef(null);

  const handleMouseOver = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setIsHovering(false);
  }, []);

  const sideScroll = (
    element: HTMLDivElement,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const handleOpenModal = (index) => {
    setSelectedPhotoIndex(index);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handlePrevImage = () => {
    setSelectedPhotoIndex(
      selectedPhotoIndex > 0 ? selectedPhotoIndex - 1 : props.photos?.length - 1
    );
  };

  const handleNextImage = () => {
    setSelectedPhotoIndex(
      selectedPhotoIndex < props.photos?.length - 1 ? selectedPhotoIndex + 1 : 0
    );
  };

  return (
    <Wrap>
      <PhotosTitle>Photos</PhotosTitle>
      <Scrolling onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        {isHovering &&
          contentWrapper?.current?.clientWidth <
            contentWrapper?.current?.scrollWidth && (
            <button
              onClick={() => sideScroll(contentWrapper?.current, 1, 1, -300)}
            >
              <BiLeftArrow style={style} />
            </button>
          )}
        <Container id="photos" ref={contentWrapper}>
          {props.photos.map((photo, idx) => (
            <Box key={idx}>
              <Image
                bg={photo.file_path}
                onClick={() => handleOpenModal(idx)}
              />
            </Box>
          ))}
        </Container>
        {isHovering &&
          contentWrapper?.current?.clientWidth <
            contentWrapper?.current?.scrollWidth && (
            <button
              onClick={() => sideScroll(contentWrapper?.current, 1, 1, 300)}
            >
              <BiRightArrow style={style} />
            </button>
          )}
      </Scrolling>
      {modalOpen && (
        <Modal>
          <ModalContent>
            <CloseButton onClick={handleCloseModal}>X</CloseButton>
            <ImageModal bg={props.photos[selectedPhotoIndex].file_path} />
            <ButtonWrapper>
              <ButtonPrev onClick={handlePrevImage}>
                <BiLeftArrow />
              </ButtonPrev>
              <ButtonNext onClick={handleNextImage}>
                <BiRightArrow />
              </ButtonNext>
            </ButtonWrapper>
          </ModalContent>
        </Modal>
      )}
    </Wrap>
  );
};

export default Photos;

const Wrap = styled.div`
  max-width: 96%;
  height: 300px;
  display: flex;
  margin: 20px;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    max-width: 90%;
    margin: 20px 8px;
  }
`;

const PhotosTitle = styled.div`
  font-size: 24px;
  border-left: 4px solid black;
  padding: 0 10px;
`;

const Scrolling = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  button {
    z-index: 1;
    align-self: center;
    border: none;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    margin: 0 -20px;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    overflow: hidden;
  }
`;

const Container = styled.div`
  width: 100%;
  padding-top: 10px;
  overflow: auto;
  white-space: nowrap;
  line-height: 1; /* make bottom padding same as top padding by removing line-height */
  vertical-align: middle;

  ::-webkit-scrollbar {
    height: 5px !important;
    border-radius: 7px !important;
    background-color: #f5f5f5 !important; /* Adjust the background color of the scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888 !important; /* Adjust the color of the scrollbar thumb */
    border-radius: 7px !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555 !important; /* Adjust the color of the scrollbar thumb on hover */
  }

  @media screen and (max-width: 768px) {
    ::-webkit-scrollbar {
      display: none !important;
    }
  }
  /* @media screen and (max-width:1024){
    max-width:80%;
    overflow:hidden;
  } */
`;

const Image = styled.div`
  background: url(https://www.themoviedb.org/t/p/w780${(props) => props.bg});
  background-size: cover;
  min-width: 125px;
  height: 200px;
  border: 1px solid white;
  border-radius: 7px;
  margin: 10px 10px 10px 0;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Box = styled.div`
  max-width: 100%;
  display: inline-block;
  padding: 0.5vh;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: black;
  width: 80%;
  height: 80%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;

  .image-container {
    position: relative;
    width: 100%;
    height: 100%;

    .image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%;
      border-radius: 5px;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #999;
`;

const ImageModal = styled.div`
  background: url(https://www.themoviedb.org/t/p/original${(props) =>
    props.bg});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 80%;
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ButtonPrev = styled.button`
  background-color: #f5f5f5;
  color: #333;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #333;
    color: #f5f5f5;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ButtonNext = styled.button`
  background-color: #333;
  color: #f5f5f5;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
    color: #333;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
