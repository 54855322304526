import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { MdOutlineDoneOutline as WatchedIcon } from "react-icons/md";
import UserContext from "../../context/User/UserContext";
import AuthContext from "../../context/User/AuthContext";

function WatchedHandler({
  screenType,
  movie,
  functionWatched,
  allDetails,
  location,
}) {
  const { showRatingFunc, watchedList, removeDataFromWatchlist } =
    useContext(UserContext);
  const { user, removeDetailsFromState, addDetailsToState } =
    useContext(AuthContext);

  const [watchedClicked, setWatchedClick] = useState(false);

  useEffect(() => {
    checkIfInWatched();
  }, [watchedList, movie]);

  const checkIfInWatched = () => {
    if (watchedList !== undefined) {
      if (screenType === "movie") {
        if (watchedList.movies.includes(movie)) {
          setWatchedClick(true);
        } else {
          setWatchedClick(false);
        }
      } else {
        if (watchedList.shows.includes(movie)) {
          setWatchedClick(true);
        } else {
          setWatchedClick(false);
        }
      }
    }
  };

  const handleWatched = (event) => {
    event.stopPropagation();
    if (user) {
      if (watchedClicked) {
        removeDataFromWatchlist(movie, screenType, "watched");
      } else {
        showRatingFunc(true, movie, screenType, allDetails);
      }
    } else {
      functionWatched(event);
    }
  };

  return (
    <>
      <ToWatchedButton
        onClick={handleWatched}
        watched={watchedClicked}
        location={location}
      >
        <StyledWatchedIcon />
      </ToWatchedButton>
    </>
  );
}

export default WatchedHandler;

const ToWatchedButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  right: 5px;
  bottom: 10px;
  z-index: 2;
  background-color: ${({ watched }) => (watched ? "green" : "white")};
`;

const StyledWatchedIcon = styled(WatchedIcon)`
  font-size: 15px;
`;
