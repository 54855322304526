import React, { useEffect, useContext, useState } from "react";
import TmdbContext from "../../context/TmdbContext";
import UserContext from "../../context/User/UserContext";
import DetailsWrapper from "../CardWrapper/DetailsWrapper";
import styled from "styled-components";
import { UniversalButtonStyles } from "../../UniversalStyles";
import LoadingCircle from "../Loading/LoadingCircle";
import FavouritePersonCard from "../CardWrapper/FavouritePerson-card";
import AuthContext from "../../context/User/AuthContext";

const Favourites = ({ show, lists }) => {
  const { updateFavouriteActors } = useContext(UserContext);
  const { userEntertDetails } = useContext(AuthContext);
  const { favouritePeopleDetails } = useContext(TmdbContext);
  const [movieOrTv, setMovieOrTv] = useState("movie");
  const [peopleData, setPeopleData] = useState([]);

  const movieOrTvHandler = (input) => {
    setMovieOrTv(input);
  };

  const handleFavouriteActorRemove = (personid) => {
    updateFavouriteActors(Number(personid));
  };

  const [showList, setShowList] = useState(null);
  const [movieList, setMovieList] = useState(null);

  useEffect(() => {
    setShowList(userEntertDetails.favourites.shows);
    setMovieList(userEntertDetails.favourites.movies);
    setPeopleData(userEntertDetails.favouriteActor);
  }, [userEntertDetails, favouritePeopleDetails]);

  if (show) {
    if (movieList === null && showList === null) {
      return <LoadingCircle />;
    } else {
      return (
        <UserListsWrapper>
          <ListSelector show={movieOrTv}>
            <ListButton
              onClick={() => {
                movieOrTvHandler("movie");
              }}
              show={movieOrTv === "movie"}
            >
              MOVIES({movieList.length})
            </ListButton>
            <ListButton
              onClick={() => {
                movieOrTvHandler("tv");
              }}
              show={movieOrTv === "tv"}
            >
              TV({showList.length})
            </ListButton>
            <ListButton
              onClick={() => {
                movieOrTvHandler("person");
              }}
              show={movieOrTv === "person"}
            >
              PEOPLE({peopleData.length})
            </ListButton>
          </ListSelector>
          <FavouritesWrapper show={movieOrTv === "movie"}>
            {movieList.length > 0 && (
              <DetailsWrapper
                movies={movieList}
                type="movie"
                page="detailsPage"
                where={"favourites"}
              />
            )}
          </FavouritesWrapper>
          <FavouritesWrapper show={movieOrTv === "tv"}>
            {showList.length > 0 && (
              <DetailsWrapper
                movies={showList}
                type="tv"
                page="detailsPage"
                where={"favourites"}
              />
            )}
          </FavouritesWrapper>
          <FavouritesWrapper show={movieOrTv === "person"}>
            <PeopleWrapper>
              {peopleData.length > 0 &&
                peopleData.map((person, idx) => (
                  <FavouritePersonCard
                    handleFavouriteActorRemove={handleFavouriteActorRemove}
                    person={person}
                    key={idx}
                  />
                ))}
            </PeopleWrapper>
          </FavouritesWrapper>
        </UserListsWrapper>
      );
    }
  }
};

export default Favourites;

const UserListsWrapper = styled.div`
  min-height: 600px;
  width: 80%;
  display: flex;
  flex-direction: column;
  h1 {
    margin-left: 20px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    justify-content: center;
    align-items: center;
    h1 {
      margin: 0;
    }
  }
`;

const ListSelector = styled.div`
  width: 100%;
  height: 40px;
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  @media screen and (max-width: 768px) {
    margin: 0;
    justify-content: center;
    margin-top: 20px;
  }
`;

const ListButton = styled.button`
  color: ${({ show }) => (show ? "powderblue" : "black")};
  background: ${({ show }) => (show ? "black" : "white")};
  ${UniversalButtonStyles};
`;

const FavouritesWrapper = styled.div`
  display: ${({ show }) => !show && "none"};
  margin-top: 20px;
  width: 90%;
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 7px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 7px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const PeopleWrapper = styled.div`
  width: 100%;
  margin-left: 50px;

  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    margin: 0;
    align-items: center;
    justify-content: center;
  }
`;
