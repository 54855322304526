import React from "react";
import styled from "styled-components";
import ReportBug from "../components/ReportBug/ReportBug";

const Contact = () => {
  return (
    <MainWrap>
      <Opening>Contact Us</Opening>
      <ReportBug />
    </MainWrap>
  );
};

export default Contact;

const MainWrap = styled.div`
  min-height: 60vh;
  width: 100vw;
  padding: 150px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  li {
    padding-bottom: 30px;
  }
`;

const Opening = styled.div`
  width: 90%;
  font-weight: bold;
  text-align: left;
`;
