import React from "react";
import styled from "styled-components";

const CommunityPolicy = () => {
  return (
    <MainWrap>
      <Opening>Community Policy</Opening>
      <Intro>
        By using mycinemahub.com or its associated apps (the “Service”), you
        agree to abide by the terms and conditions outlined in these Terms,
        which are extracted from our broader terms of use.
      </Intro>
      <ul>
        <li>
          Our community at Mycinemahub is brought together by a shared passion
          for film. While our members come from diverse backgrounds and have
          varied tastes and experiences, we expect everyone to show courtesy and
          respect towards others' opinions.
        </li>
        <li>
          You must refrain from posting any unwelcome, aggressive, suggestive,
          or otherwise inappropriate remarks directed at fellow members or our
          team.
        </li>
        <li>
          The Service should not be used for malicious purposes or to harass,
          threaten, intimidate, or impersonate other members.
        </li>
        <li>
          You are prohibited from soliciting personal or identifying information
          from other members or our team.
        </li>
        <li>
          While creativity is encouraged, hate speech and discriminatory
          behavior are not tolerated on Mycinemahub. You must not promote or
          engage in any form of hate, violence, discrimination, or intolerance
          based on protected attributes.
        </li>
        <li>
          Spoiler alerts are mandatory for reviews containing significant plot
          details. Failure to do so may result in the removal of your content.
        </li>
        <li>
          Plagiarism is strictly prohibited. You must write original reviews and
          properly attribute sources where necessary.
        </li>
        <li>
          Deliberate misinformation or vandalism of TMDb.org data, which is
          imported to Mycinemahub, will result in content removal and potential
          account suspension.
        </li>
        <li>
          We reserve the right to remove any content deemed offensive, unlawful,
          explicit, or in breach of our terms. This includes content that
          supports or promotes ideologies contrary to our values or disseminates
          misinformation.
        </li>
        <li>
          Off-platform behavior may also be considered in our moderation
          process.
        </li>
        <li>
          Non-compliance with our Terms may lead to account suspension or
          termination.
        </li>
        <li>
          Our policy is continuously updated to reflect evolving trends and
          evidence. Our moderation team aims to understand evolving behaviors,
          stereotypes, and forms of expression to maintain a positive community
          environment.
        </li>
        <li>
          In essence, we encourage everyone to treat each other with respect and
          kindness.
        </li>
      </ul>
      <p>This policy was last updated in April 2024 </p>
    </MainWrap>
  );
};

export default CommunityPolicy;

const MainWrap = styled.div`
  min-height: auto;
  width: 90%;
  padding: 150px 5%;
  li {
    padding-bottom: 30px;
  }
`;

const Opening = styled.div`
  font-weight: bold;
  padding-bottom: 30px;
`;

const Intro = styled.div``;
