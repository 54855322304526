const firstLoadersReducer = (state, action) => {
  switch (action.type) {
    case "POPULAR_MOVIES":
      return {
        ...state,
        movies: action.payload,
        loading: false,
      };
    case "GETBESTLAST20_MOVIES":
      return {
        ...state,
        bestLast20: action.payload,
        loading: false,
      };

    case "GETBEST_MOVIES":
      return {
        ...state,
        best: action.payload,
        loading: false,
      };
    case "POPULAR_SHOWS":
      return {
        ...state,
        series: action.payload,
        loading: false,
      };
    case "TOP_SHOWS":
      return {
        ...state,
        topSeries: action.payload,
        loading: false,
      };
    case "TOP_MOVIES":
      return {
        ...state,
        topMovies: action.payload,
        loading: false,
      };

    case "GET_LATEST_MOVIES":
      return {
        ...state,
        latestMovies: action.payload,
      };

    case "GET_UPCOMING_MOVIES":
      return {
        ...state,
        upcoming: action.payload,
        upcomingSeries: action.upcomingSeries,
      };
    case "GET_TRENDING":
      return {
        ...state,
        trendingMovies: action.movies,
        trendingShows: action.shows,
        trendingPeople: action.people,
      };
    default:
      return state;
  }
};

export default firstLoadersReducer;
