import { useState, useRef } from "react";
import styled from "styled-components";
import { BiSearchAlt2, BiX } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const SearchBox = ({ theme }) => {
  const [value, setValue] = useState("");
  const [showClearIcon, setShowClearIcon] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value?.length < 1) {
      alert("Please type in what you would like to search for!!");
    } else {
      navigate(`/searchresult/${value}`);
      handleClear();
      inputRef.current.blur();
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    setShowClearIcon(event.target.value !== "");
  };

  const handleClear = () => {
    setValue("");
    setShowClearIcon(false);
  };

  return (
    <StyledForm theme={theme} onSubmit={handleSubmit}>
      <SearchIcon theme={theme} onClick={handleSubmit} />
      <InputContainer>
        <input
          theme={theme}
          type="text"
          id="search"
          name="search"
          onChange={handleChange}
          value={value}
          placeholder="Search"
          ref={inputRef}
        />
        {showClearIcon && <ClearIcon theme={theme} onClick={handleClear} />}
      </InputContainer>
    </StyledForm>
  );
};

export default SearchBox;

const InputContainer = styled.div`
  position: relative;
`;

const ClearIcon = styled(BiX)`
  position: absolute;
  top: 50%;
  right: 5px;
  color: ${({ theme }) => (theme === "true" ? "#000" : "#fff")};
  transform: translateY(-50%);
  cursor: pointer;
`;

const StyledForm = styled.form`
  text-align: center;
  border-radius: 4px;
  position: relative;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: ${({ theme }) =>
    theme === "true" ? "1px solid #000" : "1px solid #fff"};
  transition: outline 0.2s ease-in-out;
  background: ${({ theme }) => (theme === "true" ? "#fff" : "#000")};
  color: black;

  &:focus-within {
    outline: ${({ theme }) =>
      theme === "true" ? "2px solid #000" : "2px solid #fff"};
  }

  input {
    height: 25px;
    width: 150px;
    border: none;
    font-size: 18px;
    font-weight: 100;
    color: ${({ theme }) => (theme === "true" ? "#000" : "#fff")};
    caret-color: transparent;
    background-color: var(--input-background);

    &:focus {
      outline: #000;
      caret-color: #fff;
    }

    &::placeholder {
      color: #999;
      opacity: 1;
      font-weight: 100;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: ${({ theme }) =>
        theme === "true" ? "#000" : "#fff"} !important;
      transition: background-color 5000s ease-in-out 0s;
      background-color: var(--input-background) !important;
    }

    @media (max-width: 768px) {
      width: 100px;
    }
  }

  button:hover {
    transform: scale(1.03);
  }

  @media (max-width: 768px) {
    width: 150px;
  }
`;

const SearchIcon = styled(BiSearchAlt2)`
  font-size: 20px;
  margin: 2px 2px 0 0;
  color: ${({ theme }) => (theme === "true" ? "#000" : "#fff")};
`;
