import React from 'react'
import styled from 'styled-components';

const UserNavButton = ({ btnClicked, list, btnClickHandler, icon }) => {

    return (
        <>
            <Button focused={btnClicked === list && true} onClick={() => { btnClickHandler(list) }}>
                <IconDiv>{icon}</IconDiv> <span>{list}</span>
            </Button>
        </>
    )
}

export default UserNavButton


const Button = styled.div`
display:flex;

justify-content:flex-start ;
align-items: center;
height:50px;
border:1px solid #000;
background-color:${props => props.focused ? '#333' : '#000'} ;
color:#fff;
border-radius:7px;
margin:2px 0;


text-shadow:${props => props.focused ? '2px 2px 2px red' : '2px 2px 2px gray'} ;
cursor:pointer;
&:hover{
  background-color:#333 ;
}

@media screen and (max-width:768px){
    min-width:125px;
}
`;

const IconDiv = styled.div`
    height:100%;
    width:50px;
    display:flex;
    align-items: center;
    justify-content:center;
`