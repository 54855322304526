const tmdbReducer = (state, action) => {
  switch (action.type) {
    case "POPULAR_MOVIES":
      return {
        ...state,
        movies: action.payload,
        loading: false,
      };
    case "GETBESTLAST20_MOVIES":
      return {
        ...state,
        bestLast20: action.payload,
        loading: false,
      };
    case "GET_REVIEWS":
      return {
        ...state,
        reviews: action.payload,
        loading: false,
      };
    case "GET_SIMILAR":
      return {
        ...state,
        similar: action.payload,
        loading: false,
      };
    case "GETBEST_MOVIES":
      return {
        ...state,
        best: action.payload,
        loading: false,
      };
    case "POPULAR_SHOWS":
      return {
        ...state,
        series: action.payload,
        loading: false,
      };
    case "TOP_SHOWS":
      return {
        ...state,
        topSeries: action.payload,
        loading: false,
      };
    case "TOP_MOVIES":
      return {
        ...state,
        topMovies: action.payload,
        loading: false,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "SET_DETAILS_LOADING":
      return {
        ...state,
        detailsLoading: true,
      };

    case "SET_PROVIDER_LOADING":
      return {
        ...state,
        providerLoading: true,
      };

    case "SET_SEARCH_LOADING":
      return {
        ...state,
        searchLoading: true,
      };
    case "SET_ACTOR_LOADING":
      return {
        ...state,
        actorLoading: true,
      };
    case "VIDEOS_LOADING":
      return {
        ...state,
        videosLoading: true,
      };
    case "CREDITS_LOADING":
      return {
        ...state,
        creditsLoading: true,
      };

    case "GET_DETAILS":
      return {
        ...state,
        details: action.payload,
        detailsLoading: false,
        movieAndTvID: action.id,
        similar: action.similar,
        social: action.social,
        rekon: action.rekon,
        releaseDate: action.releaseDate,
        credits: action.credits,
        crew: action.crew,
        dirID: action.dirID,
        creditsSeries: action.creditsSeries,
        dirID: action.dirID,
        cast: action.cast,
      };

    case "GET_SEARCH":
      return {
        ...state,
        searchMovies: action.searchMovies,
        searchTV: action.searchTV,
        searchPeople: action.searchPeople,
        searchLoading: false,
      };
    case "SEARCH_MOVIES":
      return {
        ...state,
        searchMovies: action.payload,
      };
    case "SEARCH_SHOWS":
      return {
        ...state,
        searchTV: action.payload,
      };
    case "SEARCH_PEOPLE":
      return {
        ...state,
        searchPeople: action.payload,
      };

    case "GET_ACTOR_DETAILS":
      return {
        ...state,
        actorDetails: action.payload,
        photos: action.photos,
        actorLoading: false,
      };
    case "PROVIDER":
      return {
        ...state,
        provider: action.payload,
        providerLoading: false,
      };
    case "SET_ACTOR_LOADING":
      return {
        ...state,
        actorLoading: true,
      };
    case "MOVIE_VIDEOS":
      return {
        ...state,
        videos: action.payload,
        videoKey: action.videoKey,
        videosLoading: false,
      };
    case "SERACH_STREAMERS":
      return {
        ...state,
        comingSoonData: action.payload,
        comingSoonMoviesData: action.payload,
      };
    case "GET_LATEST_MOVIES":
      return {
        ...state,
        latestMovies: action.payload,
      };
    case "ACTOR_CREDITS":
      return {
        ...state,
        actorTvCredits: action.tvCredits,
        actorMovieCredits: action.movieCredits,
        director: action.director,
        creditsLoading: false,
        crewDetails: action.crewDetails,
        socialActor: action.socialActor,
      };

    case "GET_UPCOMING_MOVIES":
      return {
        ...state,
        upcoming: action.payload,
      };

    case "SET_VIDEO_TYPE":
      return {
        ...state,
        videoType: action.videoType,
      };
    case "NETWORK_ID":
      return {
        ...state,
        networkId: action.payload,
      };
    case "GET_FAVOURITE_PEOPLES_DETAILS":
      return {
        ...state,
        favouritePeopledetails: action.payload,
      };
    case "ADD_FAVOURITE_PERSON_DETAILS":
      return {
        ...state,
        favouritePeopleDetails: [
          ...state.favouritePeopleDetails,
          action.payload,
        ],
      };

    // Handle removing favourite person details
    case "REMOVE_FAVOURITE_PERSON_DETAILS":
      return {
        ...state,
        favouritePeopleDetails: action.payload,
      };

    default:
      return state;
  }
};

export default tmdbReducer;
