import React, { useState } from "react";
import Main from "../components/Main/Main";
import styled from "styled-components";
import TmdbContext from "../context/TmdbContext";

const Home = () => {
  return (
    <HomeWrapper>
      <Main />
    </HomeWrapper>
  );
};

export default Home;

const HomeWrapper = styled.div`
  max-width: 100%;
  background: #999 lightgray;
  ${"" /* padding-top:20px; */}
`;
