import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const OscarCard = ({ data }) => {
  const navigate = useNavigate();

  const navigateToActor = () => {
    navigate(`../actorDetails/${data.personId}`);
  };
  const navigateToMovie = () => {
    navigate(`../detailspage/movie/${data.id}`);
  };
  const navigateToDetailsPage = () => {
    if (data.type === "movie") {
      navigateToMovie();
    } else {
      navigateToActor();
    }
  };

  return (
    <MainWrapper>
      <Award>{data.award}</Award>
      <Box>
        <iframe
          width="560"
          height="405"
          src={`https://www.youtube.com/embed/${data.trailer}?start=3`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          frameBorder="0"
          allowFullScreen
        ></iframe>
        <Poster bg={data.poster_path} onClick={navigateToDetailsPage} />
      </Box>
      <Title onClick={navigateToMovie}>{data.original_title}</Title>
      {data?.name ? (
        <NameWrap onClick={navigateToActor}>{data.name} </NameWrap>
      ) : (
        <NameWrap onClick={navigateToMovie}>Movie</NameWrap>
      )}
    </MainWrapper>
  );
};

export default OscarCard;

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 60vw;
  }
`;
const Title = styled.div`
  font-size: 22px;
  color: white;
  margin: 10px 0 0 0;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  @media screen and (min-width: 900px) {
    font-size: 14px;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 10px;
    margin: 0;
  }
`;

const Box = styled.div`
  width: 100%;
  height: 300px;
  margin: 10px 0 20px 0;
  /* position:relative; */

  iframe {
    opacity: 0.8;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 900px) {
      top: 10%;
      left: 10%;
    }

    @media screen and (max-width: 768px) {
      top: 0;
      left: 0;
    }
  }

  @media screen and (max-width: 768px) {
    height: 200px;
    width: 280px;
  }
`;

const Poster = styled.div.attrs((props) => ({
  style: {
    display: "flex",
    backgroundImage: `url(https://www.themoviedb.org/t/p/original${props.bg})`,
  },
}))`
  position: absolute;
  z-index: 200;
  top: 45%;
  left: 75%;
  width: 120px;
  height: 180px;
  background-size: cover;
  border-radius: 8px;
  border: 1px solid #fff;

  @media screen and (max-width: 1000px) {
    width: 80px;
    height: 120px;
  }
  @media screen and (max-width: 500px) {
    width: 60px;
    height: 90px;
    top: 50%;
    left: 70%;
  }
`;

const Award = styled.div`
  font-size: 20px;
  color: rgb(255, 0, 0);
  margin: 10px 0 20px 0;

  @media screen and (max-width: 900px) {
    font-size: 14px;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 11px;
    margin: -10px 0 20px 0;
  }
`;

const NameWrap = styled.span`
  color: green;
  margin-bottom: 10px;
  cursor: pointer;
`;
