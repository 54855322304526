import React, { useState, useCallback, useContext, useEffect } from "react";
import FirstLoadersContext from "../../context/FirstLoaders/FirstLoaders";
import SizeContext from "../../context/SizeContext";
import styled from "styled-components";
import Card from "./Card";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useLocalStorage from "../../hooks/useLocalStorage";
import { UniversalButtonStyles } from "../../UniversalStyles";

const CategoriesSelectionWrapper = (props) => {
  const { cardItems, isSmall } = useContext(SizeContext);
  const [isHovering, setIsHovering] = useState(false);
  const style = { color: "white", margin: "4px", fontSize: "20px" };
  const contentWrapper = React.useRef(null);
  const { getBest, best } = useContext(FirstLoadersContext);
  const [cat, setCat] = useLocalStorage("category", " Horror");
  const categories = [
    " Horror",
    " History",
    " Action",
    " Adventure",
    " Animation",
    " Comedy",
    " Crime",
    " Drama",
    " Fantasy",
    " Family",
    " Music",
    " Mistery",
    " Romance",
    " Sci-Fi",
    " Thriller",
    " War",
  ];

  useEffect(() => {
    setCat(" Horror");
  }, []);

  const buttonHandler = async (category) => {
    const categories = {
      " History": { genre: "36", noGenre: "" },
      " Action": { genre: "28", noGenre: "16" },
      " Adventure": { genre: "12", noGenre: "16" },
      " Animation": { genre: "16", noGenre: "" },
      " Comedy": { genre: "35", noGenre: "" },
      " Crime": { genre: "80", noGenre: "" },
      " Drama": { genre: "18", noGenre: "" },
      " Fantasy": { genre: "14", noGenre: "16" },
      " Family": { genre: "10751", noGenre: "16" },
      " Music": { genre: "10402", noGenre: "16" },
      " Mistery": { genre: "9648", noGenre: "16" },
      " Romance": { genre: "10749", noGenre: "16" },
      " Sci-Fi": { genre: "878", noGenre: "16" },
      " Thriller": { genre: "53", noGenre: "16" },
      " War": { genre: "10752", noGenre: "16" },
      default: { genre: "27", noGenre: "16" },
    };

    setCat(category);
    const { genre, noGenre } = categories[category] || categories.default;
    await getBest(genre, noGenre);
  };

  const handleMouseOver = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setIsHovering(false);
  }, []);

  const sideScroll = (
    element: HTMLDivElement,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const settings = {
    className: "center",
    infinite: false,
    centerPadding: "60px",
    slidesToShow: cardItems,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
  };

  // if (!isSmall) {
  return (
    <>
      <CatButtonWrap>
        {categories.map((category, idx) => (
          <CategorySelectorButton
            onClick={() => {
              buttonHandler(category);
            }}
            cat={cat}
            category={category}
            key={idx}
          >
            {category}
          </CategorySelectorButton>
        ))}
      </CatButtonWrap>
      <Wrap side="center" id="cardWrapper">
        <Title side="center" type="movie">
          {cat} ||
        </Title>
        <Scrolling onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          {isHovering == true &&
            contentWrapper?.current?.clientWidth <
              contentWrapper?.current?.scrollWidth && (
              <button
                onClick={() => sideScroll(contentWrapper.current, 1, 1, -300)}
              >
                <IoIosArrowBack style={style} />
              </button>
            )}
          <Container ref={contentWrapper}>
            {best?.map((movie, idx) => (
              <Box key={idx}>
                <Card
                  categ="categories"
                  bg={movie.poster_path}
                  id={movie.id}
                  type="movie"
                  rating={movie.vote_average}
                  character={movie.character}
                  release={movie.release_date || movie.first_air_date}
                  title={movie.title}
                  wrapper="blackBg"
                  allDetails={movie}
                />
              </Box>
            ))}
          </Container>
          {isHovering == true &&
            contentWrapper?.current?.clientWidth <
              contentWrapper?.current?.scrollWidth && (
              <button
                onClick={() => sideScroll(contentWrapper.current, 1, 1, 300)}
              >
                <IoIosArrowForward style={style} />
              </button>
            )}
        </Scrolling>
      </Wrap>
    </>
  );
};

export default CategoriesSelectionWrapper;

const MainWrap = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrap = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0 80px 0;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 40px 0;
  }
`;

const Title = styled.h3`
  background-color: black;
  color: white;
  width: 97%;
  margin: 0;
  text-align: ${(props) => (props.side === "left" ? "left" : "right")};
  border-radius: 8px 8px 0 0;
  align-self: center;
  font-size: 18px;
  padding: 5px 0;

  &:before {
    color: red;
    font-size: 18px;
    padding-left: 10px;
    content: "${(props) =>
      props.side === "left" || props.side === "other" ? "SHOWS " : ""}";
  }

  &:after {
    color: red;
    font-size: 18px;
    padding-right: 10px;
    content: "${(props) =>
      props.side === "right" || props.side === "center" ? " MOVIES" : ""}";
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 16px;
    max-width: 100%;

    &:before {
      font-size: 16px;
    }
    &:after {
      font-size: 16px;
    }
  }
`;

const Scrolling = styled.div`
  display: flex;
  width: 97%;
  align-items: center;
  background-color: black;
  border-radius: 0 0 8px 8px;

  button {
    z-index: 1;
    border: none;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    margin: 0 -20px;
    cursor: pointer;
  }
`;

const Container = styled.div`
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  text-align: center;
  line-height: 1; /* make bottom padding same as top padding by removing line-height */
  vertical-align: middle;
  ::-webkit-scrollbar {
    border: 1px solid #000;
    height: 10px;
    border-radius: 7px;
    background-color: #000; /* Adjust the background color of the scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #fff; /* Adjust the color of the scrollbar thumb */
    border-radius: 7px;
    border: 1px solid #000;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #eee; /* Adjust the color of the scrollbar thumb on hover */
  }
  @media screen and (max-width: 768px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Box = styled.div`
  display: inline-block;
  padding: 0.5vh;
  white-space: normal;
`;

const CatButtonWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0 40px 0;
`;

const CategorySelectorButton = styled.button`
  color: ${(props) => (props.cat === props.category ? "powderblue" : "#000")};
  background-color: ${(props) =>
    props.cat === props.category ? "#000" : "#fff"};
  ${UniversalButtonStyles};
  min-width: 100px;
`;
