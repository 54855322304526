import React, { useState, useContext, useEffect, useCallback } from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import UserContext from "../../context/User/UserContext";
import CardDetails from "../CardWrapper/CardDetails";
import TmdbContext from "../../context/TmdbContext";
import LoadingCircle from "../Loading/LoadingCircle";

const DragDrop = ({ detailedList, type, location }) => {
  const { updateWatchlistAfterDrop, deleted } = useContext(UserContext);

  const [movieList, setMovieList] = useState(detailedList || []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMovieList(detailedList || []);
  }, [detailedList]);

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
      const copyListItems = [...movieList];
      const [removed] = copyListItems.splice(result.source.index, 1);
      copyListItems.splice(result.destination.index, 0, removed);

      // Update the watchlist only if the order has changed
      if (JSON.stringify(copyListItems) !== JSON.stringify(movieList)) {
        setMovieList(copyListItems);
        console.log(copyListItems);
        updateWatchlistAfterDrop(type, copyListItems);
      }
    },
    [movieList, updateWatchlistAfterDrop]
  );
  if (movieList !== null) {
    return (
      <Container>
        {loading ? (
          <LoadingCircle />
        ) : (
          <>
            <DragWrapper>
              <DragDropContext onDragEnd={onDragEnd}>
                {movieList?.length > 0 && (
                  <Droppable droppableId={movieList[0]?.id.toString()}>
                    {(provided, snapshot) => (
                      <ItemList
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {movieList?.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item?.id?.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <Item
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ItemHeader>{index + 1}</ItemHeader>
                                <CardDetails
                                  key={item.id}
                                  bg={item.poster_path}
                                  id={item.id}
                                  rating={item.vote_average}
                                  character={item.character}
                                  release={
                                    item.release_date || item.first_air_date
                                  }
                                  title={item.name || item.title}
                                  where={"watchlist"}
                                  type={type}
                                  allDetails={item}
                                  location={location}
                                />
                              </Item>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ItemList>
                    )}
                  </Droppable>
                )}
              </DragDropContext>
            </DragWrapper>
            <BigScreenWrapper>
              {movieList.map((item, idx) => (
                <CardDetails
                  key={item.id}
                  bg={item.poster_path}
                  id={item.id}
                  rating={item.vote_average}
                  character={item.character}
                  release={item.release_date || item.first_air_date}
                  title={item.name || item.title}
                  where={"watchlist"}
                  type={type}
                  allDetails={item}
                  location={location}
                />
              ))}
            </BigScreenWrapper>
          </>
        )}
      </Container>
    );
  }
};

export default DragDrop;

const Container = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  margin: 10px;
  padding: 10px;
  @media screen and (max-width: 768px) {
    width: 100vw;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    padding: 0;
  }
`;
const DragWrapper = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
const ItemList = styled.div`
  display: flex;
  flex-direction: column;
`;
const Item = styled.div`
  margin: 5px;
  padding: 5px;
  height: 160px;
  cursor: pointer;
`;

const ItemHeader = styled.h3`
  margin: 0;
  width: 20px;
  height: 20px;
  text-align: center;
`;
const BigScreenWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
