import React, { useState, useRef } from "react";
import styled from "styled-components";
import { UniversalButtonStyles } from "../../UniversalStyles";

const ReportBug = () => {
  const [bugDescription, setBugDescription] = useState("");
  const [filePreview, setFilePreview] = useState(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const fileInputRef = useRef(null);

  const handleBugDescriptionChange = (e) => {
    setBugDescription(e.target.value);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Check file size
      const fileSizeInMB = selectedFile.size / (1024 * 1024);
      if (fileSizeInMB > 5) {
        alert("Maximum file size allowed is 5 MB");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(droppedFile);
    }
  };

  const handleClickToUpload = () => {
    fileInputRef.current.click();
  };

  const handleClickToRemove = (e) => {
    e.stopPropagation(); // Prevent event propagation to parent elements
    setFilePreview(null);
  };

  const handlePhoneClick = () => {
    window.location.href = "tel:+447511549707";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:info@gndtdigital.com";
  };

  return (
    <MainWrap>
      <Contact onClick={handleEmailClick}>
        Email: <span>info@gndtdigital.com</span>
      </Contact>
      <Contact onClick={handlePhoneClick}>
        Phone: <span>+44 751 1549707</span>
      </Contact>
      <Title>Report a Bug:</Title>
      <DescWrap>
        <label htmlFor="bugDescription">Bug Description:</label>
        <textarea
          id="bugDescription"
          value={bugDescription}
          onChange={handleBugDescriptionChange}
          rows="4"
          cols="50"
          required
        />
      </DescWrap>
      <DescWrap>
        <label htmlFor="email">Email Address:</label>
        <input type="email" id="email" required />
      </DescWrap>
      <DropZone
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        isDraggingOver={isDraggingOver}
        onClick={handleClickToUpload}
      >
        {filePreview ? (
          <FilePreview>
            {filePreview.startsWith("data:image/") ? (
              <img src={filePreview} alt="Preview" />
            ) : (
              <video controls>
                <source src={filePreview} type="video/mp4" />
                <Text>Your browser does not support the video tag.</Text>
              </video>
            )}
            <RemoveButton onClick={handleClickToRemove}>
              Remove File
            </RemoveButton>
          </FilePreview>
        ) : isDraggingOver ? (
          <Text>Drop Here</Text>
        ) : (
          <Text>Drag & Drop File Here or Click to Upload ( max 5 MB )</Text>
        )}
        <FileInput
          type="file"
          accept="image/*, video/mp4"
          onChange={handleFileChange}
          ref={fileInputRef}
        />
      </DropZone>
      <Button type="submit">Submit</Button>
    </MainWrap>
  );
};

export default ReportBug;

const MainWrap = styled.div`
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const Contact = styled.div`
  text-align: left;
  margin: 50px 0 10px 0;
  font-weight: bold;

  span {
    font-weight: normal;
    cursor: pointer;

    &:hover {
      color: blue;
    }
  }
`;

const Title = styled.h3`
  text-align: center;
  margin-bottom: 20px;
`;

const DescWrap = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    padding-bottom: 5px;
  }

  textarea,
  input {
    margin-bottom: 10px;
    padding: 5px;
  }
`;

const DropZone = styled.div`
  width: 300px;
  height: 300px;
  border: 2px dashed ${(props) => (props.isDraggingOver ? "green" : "#aaaaaa")};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  cursor: pointer;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 250px;
    height: 250px;
  }
`;

const FilePreview = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  img,
  video {
    max-height: 100%;
    max-width: 100%;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const Text = styled.div`
  width: 70%;
  text-align: center;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
  border: none;
  cursor: pointer;
`;

const Button = styled.button`
  ${UniversalButtonStyles};
  background: white;
  color: black;
`;
