import React, { useContext, useState, useEffect } from "react";
import TmdbContext from "../../context/TmdbContext";
import SizeContext from "../../context/SizeContext";
import styled from "styled-components";
import Card from "./Card";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoadingCircle from "../Loading/LoadingCircle";
import CardPeople from "./CardPeople";
import TrendingCard from "./TrendingCard";

const MasterCardWrapper = (props) => {
  const { detailsLoading } = useContext(TmdbContext);
  const { isSmall } = useContext(SizeContext);
  const [isHovering, setIsHovering] = useState(false);
  const style = { color: "white", margin: "4px", fontSize: "20px" };
  const [createOrLogin, setCreateOrLogin] = useState();
  const contentWrapper = React.useRef(null);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleModalOpen = () => {
    setCreateOrLogin(true);
  };
  const handleModalClose = () => {
    setCreateOrLogin(false);
  };

  const sideScroll = (
    element: HTMLDivElement,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (contentWrapper.current) {
        contentWrapper.current.scrollLeft += step;
      }
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  useEffect(() => {
    // Example function that needs to access the ref after component mounts
    const checkScrollWidth = () => {
      if (
        contentWrapper.current &&
        contentWrapper.current.scrollWidth > contentWrapper.current.clientWidth
      ) {
        // Element is overflowed
      }
    };

    checkScrollWidth();
  }, []); // Empty dependency array means this effect runs once after the initial render

  if (!detailsLoading) {
    return (
      <Wrap side={props.side} id="cardWrapper">
        <TitleWrapper>
          {props.category && <Square />}
          <CategoryWrapper>{props.category}</CategoryWrapper>
        </TitleWrapper>
        <Scrolling onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          {!isSmall &&
            isHovering == true &&
            contentWrapper?.current?.clientWidth <
              contentWrapper.current.scrollWidth && (
              <ScrollButton
                onClick={() => sideScroll(contentWrapper.current, 1, 1, -300)}
              >
                <IoIosArrowBack style={style} />
              </ScrollButton>
            )}

          <Container ref={contentWrapper}>
            {props.movieCard === "movie"
              ? props.movies?.map((movie, idx) => (
                  <Box key={idx}>
                    <Card
                      page={props.page}
                      bg={movie.poster_path}
                      id={movie.id}
                      type={props.type}
                      rating={movie.vote_average}
                      character={movie.character}
                      release={movie.release_date || movie.first_air_date}
                      title={movie.title || movie.name}
                      allDetails={movie}
                    />
                  </Box>
                ))
              : props.movieCard === "new"
              ? props.movies?.map((movie, idx) => (
                  <NewBox key={idx}>
                    <TrendingCard
                      modalHandle={handleModalOpen}
                      data={movie}
                      type={props.type}
                      category={props.category}
                      release={props.release}
                      theme={props.theme}
                      allDetails={movie}
                    />
                  </NewBox>
                ))
              : props.people &&
                props.people.map((person, idx) => (
                  <Box key={idx}>
                    <CardPeople
                      bio={person.biograpy}
                      bg={person.profile_path}
                      id={person.id}
                      type={props.type}
                      name={person.name}
                      character={person.character}
                    />
                  </Box>
                ))}
          </Container>
          {!isSmall &&
            isHovering == true &&
            contentWrapper?.current?.clientWidth <
              contentWrapper.current.scrollWidth && (
              <ScrollButton
                onClick={() => sideScroll(contentWrapper.current, 1, 1, 300)}
              >
                <IoIosArrowForward style={style} />
              </ScrollButton>
            )}
        </Scrolling>
      </Wrap>
    );
  } else {
    return <LoadingCircle />;
  }
};

export default MasterCardWrapper;

const Wrap = styled.div`
  width: 100%;
  height: 380px;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 20px 0;
  }
`;

const Title = styled.h4`
  width: 80%;
  margin: 0;
  text-align: ${(props) => (props.side === "left" ? "left" : "right")};
  padding: 5px 60px;
  border-bottom: 1px solid #000;
  font-family: "PT Sans Narrow", sans-serif;
  align-self: center;

  &:before {
    color: red;
    font-size: 20px;
    content: "${(props) =>
      props.side === "left" || props.side === "other" ? "SHOWS " : ""}";
  }

  &:after {
    color: red;
    font-size: 20px;
    content: "${(props) =>
      props.side === "right" || props.side === "center" ? " MOVIES" : ""}";
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    width: 85%;
    padding: 0;
    margin: 40px 0 10px 0;
  }
`;

const Scrolling = styled.div`
  display: flex;
  width: 95%;
  align-items: center;
`;

const ScrollButton = styled.button`
  z-index: 1;
  border: none;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  margin: 0 -20px;
  cursor: pointer;
`;

const Container = styled.div`
  min-width: 95%;
  padding-top: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  text-align: center;
  line-height: 1; /* make bottom padding same as top padding by removing line-height */
  vertical-align: middle;

  ::-webkit-scrollbar {
    height: 5px;
    border-radius: 7px;
    background-color: #f5f5f5; /* Adjust the background color of the scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Adjust the color of the scrollbar thumb */
    border-radius: 7px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Adjust the color of the scrollbar thumb on hover */
  }
  @media screen and (max-width: 768px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Box = styled.div`
  display: inline-block;
  padding: 0.5vh;
  white-space: normal;
`;

const NewBox = styled.div`
  height: 240px;
  display: inline-block;
  padding: 0.5vh;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Square = styled.div`
  width: 15px;
  height: 15px;
  margin: 0 10px;
  border-radius: 4px;
  background: rgb(255, 0, 0);
`;

const CategoryWrapper = styled.div`
  ${"" /* align-self:flex-start;  */}
  ${"" /* margin:0 0 5px 10px; */}
  color: #000;
  font-weight: bold;
  font-size: 20px;
  ${"" /* position: relative; */}
  ${"" /* padding-left: 5px; */}
  
  ${
    "" /* ::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 80%;
    width: 3px;
    background-color: red;
  } */
  }
`;
