import React, { useContext, useState, memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import TmdbContext from "../../context/TmdbContext";
import CardDetails from "./CardDetails";
import LoadingCircle from "../Loading/LoadingCircle";
import useLocalStorage from "../../hooks/useLocalStorage";

const DetailsWrapper = (props) => {
  const { loading } = useContext(TmdbContext);
  const [showing, setShowing] = useState(false);
  const [show, setShow] = useLocalStorage("actordetails", "");
  const [person] = useLocalStorage("person", "");
  const MemoizedCardDetails = memo(CardDetails);
  const { personid } = useParams();

  useEffect(() => {
    if (person !== undefined) {
      if (person === personid) {
        if (show === undefined || show === "") {
          setShowing(false);
        } else if (show === false) {
          setShowing(false);
        } else {
          setShowing(true);
        }
      }
    } else {
      setShowing(false);
    }
  }, [personid]);

  const handleButtonClick = () => {
    setShowing(!showing);
    setShow(!showing);
  };

  if (!loading) {
    return (
      <MainContainer id="detailsWrapper">
        <Container>
          <Title side={props.side}>{props.name}</Title>
          {props.movies
            ?.slice(0, showing ? props.length : 15)
            ?.map((movie, idx) => (
              <MemoizedCardDetails
                director={props.crew === "director"}
                episodes={movie.episode_count}
                job={props.crew === "crew" && movie.job}
                key={idx}
                bg={movie.poster_path}
                id={movie.id}
                type={props.type || movie.media_type}
                rating={movie.vote_average}
                character={movie.character}
                release={movie.release_date || movie.first_air_date}
                title={movie.name || movie.title}
                where={props.where}
                page={props.page}
                personid={personid}
                allDetails={movie}
                location={props.location}
              />
            ))}
        </Container>
        {props.movies?.length > 15 && (
          <Button onClick={handleButtonClick}>
            {showing ? "SHOW LESS" : "SHOW ALL"}
          </Button>
        )}
      </MainContainer>
    );
  } else {
    return <LoadingCircle />;
  }
};

export default DetailsWrapper;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  @media screen and (min-width: 768px) {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 15px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const Title = styled.h3`
  width: 80%;
  margin: 0;
  text-align: ${(props) =>
    props.side === "left"
      ? "left"
      : props.side === "center"
      ? "center"
      : "right"};
  padding: 5px 10%;
  border-bottom: 1px solid #000;

  &:before {
    color: red;
    font-size: 18px;
    content: "${(props) =>
      props.side === "left" || props.side === "other" ? "SHOWS " : ""}";
  }

  &:after {
    color: red;
    font-size: 18px;
    content: "${(props) =>
      props.side === "center"
        ? " MOVIES"
        : props.side === "right"
        ? " DIRECTOR"
        : props.side === "rightright"
        ? " CREW"
        : ""}";
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Button = styled.button`
  color: black;
  background: white;
  border: 1px solid black;
  width: 150px;
  height: 25px;
  border-radius: 8px;
  margin: 50px;
  cursor: pointer;
  align-self: center;

  &:hover {
    background: lightgray;
  }

  @media screen and (max-width: 768px) {
    width: 130px;
    font-size: 12px;
  }
`;
