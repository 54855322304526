import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../components/CardWrapper/Card";
import styled from "styled-components";
import HeaderBackdrop from "../components/HeaderBackdrop/HeaderBackdropcomponent";
import LoadingCircle from "../components/Loading/LoadingCircle";
import { UniversalButtonStyles } from "../UniversalStyles";
import {
  MdOutlineArrowBackIosNew as BackArrow,
  MdOutlineArrowForwardIos as ForwardArrow,
} from "react-icons/md";
import SearchParameters from "../components/SearchParameters/SearchParameters";
const TMDB_KEY = process.env.REACT_APP_TMDB_KEY;
const URL = "https://api.themoviedb.org/3/";

const params = new URLSearchParams({
  api_key: TMDB_KEY,
});

const SearchResultParameters = () => {
  const { fromyear, toyear, genre, minrating, provider, sortby } = useParams();
  const [type, setType] = useState("MOVIES");
  const [moviesPageNumber, setMoviesPageNumber] = useState(1);
  const [showsPageNumber, setShowsPageNumber] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [totalResultsTv, setTotalResultsTv] = useState(0);
  const [totalPageNumber, setTotalPageNumber] = useState();
  const [totalPageNumberTv, setTotalPageNumberTv] = useState();
  const [moviesList, setMoviesList] = useState([]);
  const [tvList, setTvList] = useState([]);

  const handleClick = (button) => {
    setType(button);
    setMoviesPageNumber(1);
  };

  const handlePage = (action) => {
    if (action === "sub") {
      setMoviesPageNumber((prevPageNumber) => prevPageNumber - 1);
    } else if (action === "add") {
      if (moviesPageNumber != totalPageNumber) {
        setMoviesPageNumber((prevPageNumber) => prevPageNumber + 1);
      } else {
        setMoviesPageNumber(totalPageNumber);
      }
    }
  };

  const moveToPage = (page) => {
    if (type === "MOVIES") {
      setMoviesPageNumber(page);
    } else if (type === "SHOWS") {
      setShowsPageNumber(page);
    }
  };

  useEffect(() => {
    // console.log(fromyear, toyear, genre, minrating, provider);
    const categories = {
      History: { genreId: "36" },
      Action: { genreId: "28" },
      Adventure: { genreId: "12" },
      Animation: { genreId: "16" },
      Comedy: { genreId: "35" },
      Crime: { genreId: "80" },
      Drama: { genreId: "18" },
      Fantasy: { genreId: "14" },
      Family: { genreId: "10751" },
      Music: { genreId: "10402" },
      Mistery: { genreId: "9648" },
      Romance: { genreId: "10749" },
      "Sci-Fi": { genreId: "878" },
      Thriller: { genreId: "53" },
      War: { genreId: "10752" },
      Horror: { genreId: "27" },
      Any: {
        genreId:
          "27|10752|53|878|10749|9648|10402|10751|14|18|80|35|16|12|28|36",
      },
    };

    const providers = {
      Netflix: { providerId: "8" },
      DisneyPlus: { providerId: "337" },
      AppleTV: { providerId: "2" },
      AppleTVPLUS: { providerId: "350" },
      AmazonPrime: { providerId: "9|10" },
      Paramount: { providerId: "531" },
      Channel4: { providerId: "103" },
      SkyGo: { providerId: "29" },
      BBCiPlayer: { providerId: "38" },
      SkyStore: { providerId: "130" },
      Chili: { providerId: "40" },
      Youtube: { providerId: "192" },
      RakutenTV: { providerId: "35" },
      // Any: {
      //   genreId:
      //     "27|10752|53|878|10749|9648|10402|10751|14|18|80|35|16|12|28|36",
      // }
    };

    const sort = {
      MostPopular: { sortId: "popularity.desc", sortIdTv: "popularity.desc" },
      Highest: { sortId: "vote_average.desc", sortIdTv: "vote_average.desc" },
      Lowest: { sortId: "vote_average.asc", sortIdTv: "vote_average.asc" },
      Newest: {
        sortId: "primary_release_date.desc",
        sortIdTv: "first_air_date.desc",
      },
      Oldest: {
        sortId: "primary_release_date.asc",
        sortIdTv: "first_air_date.asc",
      },
    };

    const { genreId } = categories[genre] || categories.default;
    let providerId = "";

    if (provider && providers[provider]) {
      providerId = providers[provider].providerId;
    } else {
      console.log("Provider not found or is 'Any'");
    }

    const { sortId } = sort[sortby] || sort.default;
    const { sortIdTv } = sort[sortby] || sort.default;

    const fetchData = async () => {
      let searchParametersResponseTv;
      if (provider !== "Any") {
        searchParametersResponseTv = await fetch(
          `${URL}discover/tv?${params}&include_adult=false&include_video=false&language=en-US&page=${moviesPageNumber}&first_air_date.gte=${fromyear}-01-01&first_air_date.lte=${toyear}-12-31&sort_by=${sortIdTv}&vote_average.gte=${minrating}&vote_average.lte=10&vote_count.gte=1000&vote_count.lte=1000000&watch_region=GB&with_genres=${genreId}&with_watch_providers=${providerId}`
        );
      } else {
        searchParametersResponseTv = await fetch(
          `${URL}discover/tv?${params}&include_adult=false&include_video=false&language=en-US&page=${moviesPageNumber}&first_air_date.gte=${fromyear}-01-01&first_air_date.lte=${toyear}-12-31&sort_by=${sortIdTv}&vote_average.gte=${minrating}&vote_average.lte=10&vote_count.gte=1000&vote_count.lte=1000000&watch_region=en&with_genres=${genreId}`
        );
      }
      let searchParametersResponse;
      if (provider !== "Any") {
        searchParametersResponse = await fetch(
          `${URL}discover/movie?${params}&include_adult=false&include_video=false&language=en-US&page=${moviesPageNumber}&primary_release_date.gte=${fromyear}-01-01&primary_release_date.lte=${toyear}-12-31&sort_by=${sortId}&vote_average.gte=${minrating}&vote_average.lte=10&vote_count.gte=1000&vote_count.lte=1000000&watch_region=GB&with_genres=${genreId}&with_watch_providers=${providerId}`
        );
      } else {
        searchParametersResponse = await fetch(
          `${URL}discover/movie?${params}&include_adult=false&include_video=false&language=en-US&page=${moviesPageNumber}&primary_release_date.gte=${fromyear}&primary_release_date.lte=${toyear}-12-31&sort_by=${sortId}&vote_average.gte=${minrating}&vote_average.lte=10&vote_count.gte=1000&vote_count.lte=1000000&watch_region=en&with_genres=${genreId}`
        );
      }
      const resultList = await searchParametersResponse.json();
      const resultListTv = await searchParametersResponseTv.json();

      setTotalResults(resultList.total_results);
      setTotalPageNumber(resultList.total_pages);
      setTotalResultsTv(resultListTv.total_results);
      setTotalPageNumberTv(resultListTv.total_pages);
      setMoviesList(resultList.results);
      setTvList(resultListTv.results);
    };

    fetchData();
  }, [fromyear, toyear, genre, minrating, moviesPageNumber, provider, sortby]);

  if (fromyear === undefined) {
    return <LoadingCircle />;
  } else {
    return (
      <>
        <HeaderBackdrop />

        <Master>
          <SearchWrap>
            <SearchParameters />
          </SearchWrap>
          <Standard>
            <ResultParameters>
              Result for {genre === "Any" ? "any genre" : genre} with a minimun
              rating of {minrating} from {fromyear.slice(0, 4)} to{" "}
              {toyear.slice(0, 4)} on{" "}
              {provider !== "DEFAULT" ? provider : "any provider"}.
            </ResultParameters>
            <ButtonWrap>
              <Button
                focus={type === "MOVIES"}
                onClick={() => handleClick("MOVIES")}
              >
                MOVIES ({totalResults})
              </Button>
              <Button
                focus={type === "SHOWS"}
                onClick={() => handleClick("SHOWS")}
              >
                SHOWS ({totalResultsTv})
              </Button>
            </ButtonWrap>
            <PosterWrap>
              {type === "MOVIES"
                ? moviesList.length > 0 &&
                  moviesList.map((movie, idx) => (
                    <Card
                      key={idx}
                      bg={movie.poster_path}
                      id={movie.id}
                      rating={movie.vote_average}
                      character={movie.character}
                      release={movie.release_date || movie.first_air_date}
                      title={movie.title}
                      type="movie"
                      allDetails={movie}
                    />
                  ))
                : tvList.map((show, idx) => (
                    <Card
                      key={idx}
                      bg={show.poster_path}
                      id={show.id}
                      rating={show.vote_average}
                      character={show.character}
                      release={show.release_date || show.first_air_date}
                      title={show.original_name}
                      type="tv"
                      allDetails={show}
                    />
                  ))}
            </PosterWrap>
            <PagesWrap>
              {totalPageNumber > 1 && (
                <>
                  <FirstPageButton
                    onClick={() => {
                      moveToPage(1);
                    }}
                  >
                    1{totalPageNumber > 2 && moviesPageNumber != 2 && "..."}
                  </FirstPageButton>
                  {moviesPageNumber != 1 && (
                    <>
                      <PreviousPageButton onClick={() => handlePage("sub")} />
                      <CurrentPage>{moviesPageNumber}</CurrentPage>
                    </>
                  )}
                </>
              )}
              {moviesPageNumber < totalPageNumber && (
                <NextPage onClick={() => handlePage("add")} />
              )}
              {type === "MOVIES" &&
                moviesPageNumber < totalPageNumber &&
                moviesPageNumber != totalPageNumber && (
                  <LastPageButton
                    onClick={() => setMoviesPageNumber(totalPageNumber)}
                  >
                    {moviesPageNumber < totalPageNumber - 3 ? "..." : ""}(
                    {totalPageNumber})
                  </LastPageButton>
                )}
              {type === "SHOWS" &&
                moviesPageNumber < totalPageNumberTv &&
                moviesPageNumber != totalPageNumberTv && (
                  <LastPageButton
                    onClick={() => setMoviesPageNumber(totalPageNumberTv)}
                  >
                    {moviesPageNumber < totalPageNumber - 3 ? "..." : ""}(
                    {totalPageNumberTv})
                  </LastPageButton>
                )}
            </PagesWrap>
          </Standard>
        </Master>
      </>
    );
  }
};

export default SearchResultParameters;

const Master = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const SearchWrap = styled.div`
  width: 97%;
  margin-bottom: 20px;
`;
const Standard = styled.div`
  width: 1180px;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #edeef2;

  @media screen and (max-width: 1024px) {
    width: 90%;
  }
`;

const ResultParameters = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 25px 0;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    text-align: center;
  }
`;

const PosterWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const People = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: row;
  }
`;

const NoResult = styled.h1`
  min-height: 600px;
  text-align: center;
  font-size: 22px;
`;

const ButtonWrap = styled.div`
  width: 40%;
  height: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    width: 40%;
  }
`;

const Button = styled.div`
  background: ${({ focus }) => (focus ? "black" : "white")};
  color: ${({ focus }) => (focus ? "powderblue" : "black")};
  ${UniversalButtonStyles};
  min-width: 100px;
`;

// const PagesWrap = styled.div`
//   width: 300px;
//   display: flex;
//   justify-content: space-evenly;

//   p {
//     cursor: pointer;

//     &:hover {
//       color: red;
//     }
//   }
// `

const Box = styled.div`
  width: 10px;
  height: 10px;
  border: 1px solid black;
  background: lightgray;
  padding: 5px;
  margin: 3px;
  cursor: pointer;

  &:hover {
    background: darkgray;
  }
`;
const PagesWrap = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  p {
    cursor: pointer;

    &:hover {
      color: red;
    }
  }

  @media screen and (max-width: 768px) {
    width: 350px;
  }
`;
const FirstPageButton = styled.p``;

const NextPagesButton = styled.p`
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
`;

const LastPageButton = styled.p`
  cursor: pointer;
`;

const PreviousPageButton = styled(BackArrow)`
  cursor: pointer;
`;

const NextPage = styled(ForwardArrow)`
  cursor: pointer;
`;

const CurrentPage = styled.p`
  color: green;
`;
