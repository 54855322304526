import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import AuthContext from "../../context/User/AuthContext";
import { UniversalButtonStyles } from "../../UniversalStyles";

const NewCardModal = ({ createOrLogin, handleModalClose }) => {
  const { handleRegisterClick } = useContext(AuthContext);
  // closing modal and opening login or register
  const handleModalFunction = (input) => {
    handleModalClose();
    handleRegisterClick(input);
  };

  useEffect(() => {
    if (createOrLogin) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [createOrLogin]);

  return (
    <ModalContainer show={createOrLogin}>
      <CloseChecker onClick={handleModalClose} />
      <LoginCreate>
        <CloseIcon onClick={handleModalClose} />
        <MessageContainer>
          <MessageWrapper style={{ color: "#000" }}>
            Hello! Please create a free account or log in to use all our
            features.
          </MessageWrapper>
        </MessageContainer>
        <Options>
          <LoginorCloseButton
            onClick={() => {
              handleModalFunction("login");
            }}
          >
            Login
          </LoginorCloseButton>
          <LoginorCloseButton
            onClick={() => {
              handleModalFunction("register");
            }}
          >
            {" "}
            Create an account
          </LoginorCloseButton>
          <LoginorCloseButton onClick={handleModalClose}>
            {" "}
            Maybe later
          </LoginorCloseButton>
        </Options>
      </LoginCreate>
    </ModalContainer>
  );
};

export default NewCardModal;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.61);
  backdrop-filter: blur(5px);
  display: ${({ show }) => (show === true ? "flex" : "none")};
  position: fixed;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 999;

  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 100vh;
  }
`;
const MessageContainer = styled.div`
  width: 100%;
  height: 100px;

  text-align: center;
`;

const MessageWrapper = styled.h4`
  color: #000;
`;

const LoginorCloseButton = styled.div`
  ${UniversalButtonStyles};
  width: 120px;
  padding: 0 20px;
  background: white;
`;
const CloseChecker = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const CloseIcon = styled(IoMdClose)`
  position: absolute;
  top: 2%;
  right: 2%;
  color: black;
  z-index: 500;
  font-size: 20px;
  cursor: pointer;
`;

const LoginCreate = styled.div`
  width: 600px;
  height: 150px;
  position: relative;
  display: flex;
  background: #edeef2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border: 1px solid black;
  border-radius: 8px;
  z-index: 600;

  @media screen and (max-width: 768px) {
    text-align: center;
    width: 90%;
    height: 300px;
    padding: 0;
  }
  div {
    span {
      margin: 0 10px;
    }
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  just-content: center;
  align-items: center;
`;
