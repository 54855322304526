const userReducer = (state, action) => {
  switch (action.type) {
    case "SET_REGISTER_CLICKED":
      return {
        ...state,
        register: action.payload,
        loginstate: action.loginstate,
      };

    case "REGISTER_USER":
      return {
        ...state,
        userRegister: action.payload,
      };

    case "SHOWRATING":
      return {
        ...state,
        showrating: action.payload,
        ratingobj: action.newratingobj,
      };

    case "CLOSERATING":
      return {
        ...state,
        showrating: action.payload,
      };

    case "SET_LOGINSTATE":
      return {
        ...state,
        loginstate: action.payload,
      };

    case "LOGIN":
      return {
        ...state,
        user: action.payload,
        showNavButtons: false,
        loginError: action.error,
        watchlist: action.watchlist,
        watched: action.watched,
        ratings: action.ratings,
        favourites: action.favourites,
        favouriteactor: action.favouriteactor,
        lists: action.lists,
        imgUrl: action.imgUrl,
        userEntertDetails: action.userEntertDetails,
      };
    case "UPDATE_USER_ENTERT_DETAILS":
      return {
        ...state,
        userEntertDetails: action.payload,
      };

    case "UPDATE_USER_ENTERT_DETAILS":
      return {
        ...state,
        userEntertDetails: action.payload,
      };
    case "UPDATE_FAVOURITE_ACTORS":
      return {
        ...state,
        favouriteActor: action.payload,
      };

    case "LOGOUT":
      return {
        ...state,
        user: action.payload,
        showNavButtons: true,
      };

    case "USER_LOGGED_IN":
      return {
        ...state,
        user: action.payload,
        showNavButtons: false,
      };

    case "LOGIN_ERROR":
      return {
        ...state,
        loginError: action.payload,
      };

    case "REGISTER_ERROR":
      return {
        ...state,
        registerError: action.error,
      };

    case "GET_DOC_ID":
      return {
        ...state,
        id: action.id,
      };

    case "UPDATE_MOVIE_STATE":
      return {
        ...state,
        watchlist: action.payload,
      };

    case "UPDATE_WATCHLIST":
      return {
        ...state,
        watchlist: action.payload,
        deleted: action.deleted,
      };

    case "UPDATE_WATCHED":
      return {
        ...state,
        watched: action.payload,
      };

    case "UPDATE_FAVOURITES":
      return {
        ...state,
        favourites: action.payload,
      };

    case "ADD_NEW_LIST":
      return {
        ...state,
        lists: action.payload,
      };

    case "ADD_TO_LIST":
      return {
        ...state,
        lists: action.payload,
      };

    case "ADD_TO_RATINGS":
      return {
        ...state,
        ratings: action.payload,
      };

    case "UPDATE_IMG_URL":
      return {
        ...state,
        imgUrl: action.payload,
      };

    case "UPDATE_USER_DATA":
      return {
        ...state,
        state: action.payload,
        user: action.user,
        id: action.id,
        favouriteActor: action.favouriteActor,
        favourites: action.favourites,
        lists: action.lists,
        ratings: action.ratings,
        watched: action.watched,
        watchlist: action.watchlist,
      };

    case "RESET_USER_DATA":
      return {
        ...state,
        user: action.user,
        id: action.id,
        favouriteActor: action.favouriteActor,
        favourites: action.favourites,
        lists: action.lists,
        ratings: action.ratings,
        watched: action.watched,
        watchlist: action.watchlist,
      };
    case "ADD_FAVOURITE_PERSON_DETAILS":
      return {
        ...state,
        userEntertDetails: {
          ...state.userEntertDetails,
          favouriteActor: [
            ...state.userEntertDetails.favouriteActor,
            action.payload,
          ],
        },
      };

    case "REMOVE_FAVOURITE_PERSON_DETAILS":
      return {
        ...state,
        userEntertDetails: {
          ...state.userEntertDetails,
          favouriteActor: state.userEntertDetails.favouriteActor.filter(
            (actor) => actor.id !== action.payload
          ),
        },
      };

    case "GET_FAVOURITE_PEOPLES_DETAILS":
      return {
        ...state,
        userEntertDetails: {
          ...state.userEntertDetails,
          favouriteActor: action.payload,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
