import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import DisneyLogo from "../assets/images/Networks/DisneyLogo.png";
import PrimeLogo from "../assets/images/Networks/PrimeLogo.png";
import NetflixLogo from "../assets/images/Networks/NetflixLogo.png";
import NowLogo from "../assets/images/Networks/NowLogo.svg";
import AppleLogo from "../assets/images/Networks/AppleLogo.png";
import TmdbContext from "../context/TmdbContext";
import LoadingCircle from "../components/Loading/LoadingCircle";
import TrendingCard from "../components/CardWrapper/TrendingCard";
import { useNavigate } from "react-router-dom";
import { UniversalButtonStyles } from "../UniversalStyles";
import {
  MdOutlineArrowBackIosNew as BackArrow,
  MdOutlineArrowForwardIos as ForwardArrow,
} from "react-icons/md";
import NewCardModal from "../components/CardModal/NewCardModal";
import MasterCardWrapper from "../components/CardWrapper/MasterCardWrapper";

const NetworksPage = () => {
  const {
    getNetworkIds,
    fetchComingSoon,
    fetchComingSoonMovies,
    fetchStreaming,
    fetchStreamingMovies,
  } = useContext(TmdbContext);
  const outNowRef = useRef(null);
  const { channel } = useParams();
  const [type, setType] = useState("MOVIES");
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comingSoon, setComingSoon] = useState(null);
  const [comingSoonMovies, setComingSoonMovies] = useState(null);
  const [outNow, setOutNow] = useState(null);
  const [outNowMovies, setOutNowMovies] = useState(null);
  const [page, setPage] = useState(1);
  const [showsPage, setShowsPage] = useState(1);
  const [moviesPage, setMoviesPage] = useState(1);
  const [createOrLogin, setCreateOrLogin] = useState();
  const [refetch, setRefetch] = useState(false);

  const navigate = useNavigate();
  const handleModalOpen = () => {
    setCreateOrLogin(true);
  };
  const handleModalClose = () => {
    setCreateOrLogin(false);
  };
  // /bwO92lNZstiQHtM7CSD7YNPGYM.png
  const logos = {
    disney: DisneyLogo,
    prime: PrimeLogo,
    netflix: NetflixLogo,
    nowTv: NowLogo,
    apple: AppleLogo,
  };

  const otherNetworks = Object.keys(logos).filter((key) => key !== channel);

  const logoChecker = (value) => {
    setLogo(logos[value]);
  };
  const scrollToRefWithOffset = () => {
    if (outNowRef.current) {
      const topPosition =
        outNowRef.current.getBoundingClientRect().top + window.pageYOffset - 70; // 70px offset from the top
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  const nextPage = () => {
    let showsTotalPages = outNow?.total_pages;
    let moviesTotalPages = outNowMovies.total_pages;

    if (type === "SHOWS" && showsPage < showsTotalPages) {
      setShowsPage((prevPage) => prevPage + 1);
    } else if (type === "MOVIES" && moviesPage < moviesTotalPages) {
      setMoviesPage((prevPage) => prevPage + 1);
    }
    scrollToRefWithOffset();
  };

  const previousPage = () => {
    if (type === "MOVIES") {
      if (moviesPage > 1) {
        setMoviesPage((prevPage) => prevPage - 1);
      }
    } else {
      if (showsPage > 1) {
        setShowsPage((prevPage) => prevPage - 1);
      }
    }
    scrollToRefWithOffset();
  };

  const fetchAndSetData = async () => {
    let networkData = getNetworkIds(channel);

    if (type === "SHOWS") {
      if (!comingSoon || !outNow) {
        const comingSoonShows = await fetchComingSoon(networkData);
        const streamingShows = await fetchStreaming(networkData, showsPage);
        setComingSoon(comingSoonShows);
        setOutNow(streamingShows);
      }
    } else {
      if (!comingSoonMovies || !outNowMovies) {
        const comingSoonMovs = await fetchComingSoonMovies(networkData);
        const streamingMovs = await fetchStreamingMovies(
          networkData,
          moviesPage
        );
        setComingSoonMovies(comingSoonMovs);
        setOutNowMovies(streamingMovs);
      }
    }
    setLoading(false);
  };

  const reFetchAndSetData = async (network) => {
    let networkData = getNetworkIds(network);
    if (network !== "nowTv") {
      const comingSoonShows = await fetchComingSoon(networkData);
      const streamingShows = await fetchStreaming(networkData, 1);
      setComingSoon(comingSoonShows);
      setOutNow(streamingShows);
    }

    const comingSoonMovs = await fetchComingSoonMovies(networkData);
    const streamingMovs = await fetchStreamingMovies(networkData, moviesPage);
    setComingSoonMovies(comingSoonMovs);
    setOutNowMovies(streamingMovs);

    if (network === "nowTv") {
      setType("MOVIES");
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    logoChecker(channel);

    fetchAndSetData();
  }, [channel, type, page]);

  useEffect(() => {
    logoChecker(channel);
    let networkData = getNetworkIds(channel);
    const fetchShowsPage = async () => {
      const streamingShows = await fetchStreaming(networkData, showsPage);
      setOutNow(streamingShows);
    };

    const fetchMoviesPage = async () => {
      const streamingMovs = await fetchStreamingMovies(networkData, moviesPage);
      setOutNowMovies(streamingMovs);
    };
    if (type === "MOVIES") {
      fetchMoviesPage();
    } else {
      fetchShowsPage();
    }
  }, [showsPage, moviesPage]);

  const onNetworkChange = (network) => {
    console.log(network, "change");

    navigate(`/network/${network}/`);
    reFetchAndSetData(network);
  };

  if (!loading) {
    return (
      <Master>
        <LogoDiv>{logo && <LogoImg src={logo} alt="Network Logo" />}</LogoDiv>
        <NetworkSwapperDiv>
          <NetworksChangeWrapperStyled>
            {otherNetworks.map((network) => (
              <NetworkButton
                key={network}
                onClick={() => onNetworkChange(network)}
              >
                <img src={logos[network]} alt={`${network} logo`} />
              </NetworkButton>
            ))}
          </NetworksChangeWrapperStyled>
        </NetworkSwapperDiv>
        <Standard>
          <MainDiv>
            <NewCardModal
              handleModalClose={handleModalClose}
              createOrLogin={createOrLogin}
            />

            <ButtonWrap>
              <ButtonNet
                focus={type === "MOVIES"}
                onClick={() => setType("MOVIES")}
              >
                Movies
              </ButtonNet>
              {channel !== "nowTv" && (
                <ButtonNet
                  focus={type === "SHOWS"}
                  onClick={() => setType("SHOWS")}
                >
                  Shows
                </ButtonNet>
              )}
            </ButtonWrap>
            {loading ? (
              <LoadingCircle />
            ) : (
              <>
                {type === "SHOWS" ? (
                  <>
                    <ComingSoonDiv>
                      {comingSoon && (
                        <MasterCardWrapper
                          movieCard={"new"}
                          movies={comingSoon.results}
                          type="tv"
                          category="Coming Soon"
                          release="show"
                          theme="other"
                          autoplayEnabled={true}
                        />
                      )}
                    </ComingSoonDiv>
                  </>
                ) : (
                  <>
                    <ComingSoonDiv>
                      {comingSoonMovies &&
                        comingSoonMovies.results.length > 0 && (
                          <MasterCardWrapper
                            movieCard={"new"}
                            movies={comingSoonMovies.results}
                            type="movie"
                            category="Coming Soon"
                            release="show"
                            theme="other"
                            autoplayEnabled={true}
                          />
                        )}
                    </ComingSoonDiv>
                  </>
                )}
              </>
            )}
            <TitleWrapper>
              <Square />
              <CategoryWrapper>Out Now</CategoryWrapper>
            </TitleWrapper>
            <Pagination ref={outNowRef}>
              <FirstPageButton
                onClick={() => {
                  type === "MOVIES" ? setMoviesPage(1) : setShowsPage(1);

                  window.scrollTo({ top: "330", behavior: "smooth" });
                }}
                currentPage={type === "MOVIES" ? moviesPage : showsPage}
                firstPage={1}
              >
                1
              </FirstPageButton>

              <PreviousPageButton
                page={type === "MOVIES" ? moviesPage : showsPage}
                onClick={previousPage}
              >
                Previous
              </PreviousPageButton>
              <CurrentPage>
                {type === "MOVIES" ? moviesPage : showsPage}
              </CurrentPage>
              <NextPageButton
                onClick={nextPage}
                page={type === "MOVIES" ? moviesPage : showsPage}
                totalpage={
                  type === "MOVIES"
                    ? outNowMovies.total_pages
                    : outNow?.total_pages
                }
              >
                Next
              </NextPageButton>
              <LastPageButton
                onClick={() => {
                  type === "MOVIES"
                    ? setMoviesPage(
                        outNowMovies.total_pages > 500
                          ? 500
                          : outNowMovies.total_pages
                      )
                    : setShowsPage(outNow?.total_pages);

                  window.scrollTo({ top: "330", behavior: "smooth" });
                }}
                currentPage={type === "MOVIES" ? moviesPage : showsPage}
                totalpage={
                  type === "MOVIES"
                    ? outNowMovies.total_pages > 500
                      ? 500
                      : outNowMovies.total_pages
                    : outNow?.total_pages
                }
              >
                {type === "MOVIES"
                  ? outNowMovies.total_pages > 500
                    ? 500
                    : outNowMovies.total_pages
                  : outNow?.total_pages}
              </LastPageButton>
            </Pagination>
            <NetworksWrapper>
              {type === "SHOWS"
                ? outNow?.results?.map((item, idx) => (
                    <TrendingCard
                      key={idx}
                      modalHandle={handleModalOpen}
                      data={item}
                      type="tv"
                      allDetails={item}
                    />
                  ))
                : outNowMovies?.results?.map((item, idx) => (
                    <TrendingCard
                      key={idx}
                      modalHandle={handleModalOpen}
                      data={item}
                      type="movie"
                      allDetails={item}
                    />
                  ))}
            </NetworksWrapper>

            <Pagination>
              <FirstPageButton
                onClick={() => {
                  type === "MOVIES" ? setMoviesPage(1) : setShowsPage(1);
                  window.scrollTo({ top: "330", behavior: "smooth" });
                }}
                currentPage={page}
                firstPage={1}
              >
                1
              </FirstPageButton>

              <PreviousPageButton
                page={type === "MOVIES" ? moviesPage : showsPage}
                onClick={previousPage}
              >
                Previous
              </PreviousPageButton>
              <CurrentPage>
                {type === "MOVIES" ? moviesPage : showsPage}
              </CurrentPage>
              <NextPageButton
                onClick={nextPage}
                page={type === "MOVIES" ? moviesPage : showsPage}
                totalpage={
                  type === "MOVIES"
                    ? outNowMovies.total_pages > 500
                      ? 500
                      : outNowMovies.total_pages
                    : outNow?.total_pages
                }
              >
                Next
              </NextPageButton>
              <LastPageButton
                onClick={() => {
                  type === "MOVIES"
                    ? setMoviesPage(
                        outNowMovies.total_pages > 500
                          ? 500
                          : outNowMovies.total_pages
                      )
                    : setShowsPage(outNow.total_pages);
                  window.scrollTo({ top: "330", behavior: "smooth" });
                }}
                currentPage={page}
                totalpage={
                  type === "MOVIES"
                    ? outNowMovies.total_pages > 500
                      ? 500
                      : outNowMovies.total_pages
                    : outNow?.total_pages
                }
              >
                {type === "MOVIES"
                  ? outNowMovies.total_pages > 500
                    ? moviesPage !== 500 && 500
                    : moviesPage !== outNowMovies.total_pages &&
                      outNowMovies.total_pages
                  : showsPage !== outNow?.total_pages && outNow?.total_pages}
              </LastPageButton>
            </Pagination>
          </MainDiv>
        </Standard>
      </Master>
    );
  } else {
    return <LoadingCircle />;
  }
};

export default NetworksPage;
const Master = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Standard = styled.div`
  width: 1380px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1380px) {
    max-width: 100%;
  }
`;

const ButtonNet = styled.div`
  background: ${({ focus }) => (focus ? "black" : "white")};
  color: ${({ focus }) => (focus ? "powderblue" : "black")};
  ${UniversalButtonStyles};
  min-width: 100px;
  text-transform: uppercase;
`;

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 50px;
`;

const MainDiv = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
`;

const LogoDiv = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`;

const LogoImg = styled.img`
  max-width: 200px;
  max-height: 200px;
`;

const ComingSoonDiv = styled.div`
  width: 99%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const OutNowDiv = styled.div`
  width: 100%;
`;

const NetworksWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const PreviousPageButton = styled(BackArrow)`
  cursor: pointer;
  display: ${({ page }) => page === 1 && "none"};
  margin: 0 10px;
`;
const NextPageButton = styled(ForwardArrow)`
  cursor: pointer;
  display: ${({ page, totalpage }) => page === totalpage && "none"};
  margin: 0 10px;
`;

const CurrentPage = styled.span`
  font-size: 18px;
  font-weight: bold;
`;
const LastPageButton = styled.p`
  display: ${({ currentPage, totalpage }) =>
    currentPage === totalpage && "none"};
  cursor: pointer;
`;
const FirstPageButton = styled.p`
  display: ${({ currentPage, firstPage }) =>
    currentPage === firstPage && "none"};
  cursor: pointer;
`;
const TitleWrapper = styled.div`
  width: 99%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Square = styled.div`
  width: 15px;
  height: 15px;
  margin: 0 10px;
  border-radius: 4px;
  background: rgb(255, 0, 0);
`;

const CategoryWrapper = styled.div`
  color: #000;
  font-weight: bold;
  font-size: 20px;
`;
const NetworkSwapperDiv = styled.div`
  width: 100%;
  height: 60px;
  background: #000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const NetworkButton = styled.button`
  width: 80.5px;
  height: 52.5px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  border-radius: 7px;

  img {
    width: 45.5px; // Reduced from 65px
    max-height: 28px; // Reduced from 40px
  }
`;

const NetworksChangeWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
