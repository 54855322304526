import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { MdOutlinePlaylistRemove, MdClose } from "react-icons/md";
import { GrDocumentUpdate } from "react-icons/gr";
import UserContext from "../../context/User/UserContext";

const RemoveOrUpdateListComponent = ({ listsData, type }) => {
  const { deleteList, updateListName } = useContext(UserContext);
  const [showValidation, setShowValidation] = useState(false);
  const [newListName, setNewListName] = useState("");
  const [listToRemove, setListToRemove] = useState("");
  const [selectedList, setSelectedList] = useState("");
  const [error, setError] = useState("");

  const toggleValidation = () => setShowValidation(!showValidation);
  const handleListSelection = (listName) => {
    setSelectedList(listName);
    setListToRemove(listName);
    setShowValidation(true);
    setError("");
  };
  useEffect(() => {
    if (showValidation) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showValidation]);
  const handleRemoveOrUpdate = (confirm) => {
    if (confirm) {
      type === "remove"
        ? deleteList(listToRemove)
        : updateListName(listToRemove, newListName);
    }
    setShowValidation(false);
    setListToRemove("");
    setNewListName("");
    setSelectedList("");
    setError("");
  };

  const handleChange = (e) => {
    const inputName = e.target.value;
    setNewListName(inputName);

    if (inputName.length < 3) {
      setError("List name must be at least 3 characters long.");
    } else if (
      Object.keys(listsData)
        .map((name) => name.toLowerCase())
        .includes(inputName.toLowerCase())
    ) {
      setError("A list with this name already exists.");
    } else {
      setError("");
    }
  };

  const canUpdate =
    newListName.length >= 3 &&
    !Object.keys(listsData)
      .map((name) => name.toLowerCase())
      .includes(newListName.toLowerCase()) &&
    selectedList;

  const renderButtonIcon = () =>
    type === "remove" ? <MdOutlinePlaylistRemove /> : <GrDocumentUpdate />;

  return (
    <NewListWrapper>
      <RemoveButton onClick={toggleValidation}>
        {renderButtonIcon()}
        <span>{type === "remove" ? "Remove" : "Update Name"}</span>
      </RemoveButton>
      {showValidation && (
        <Modal>
          <ModalContent>
            <span>
              {type === "remove"
                ? "Select a List to Remove"
                : "Select a List to Update"}
            </span>
            <ValidationCancelButton onClick={toggleValidation}>
              <MdClose />
            </ValidationCancelButton>
            <ListContainer>
              {Object.keys(listsData).map((list, idx) => (
                <ListLi
                  key={idx}
                  onClick={() => handleListSelection(list)}
                  isSelected={list === selectedList}
                >
                  {list}
                </ListLi>
              ))}
            </ListContainer>
            {type !== "remove" && selectedList && (
              <UpdateNameBox>
                <StyledInput
                  type="text"
                  placeholder="New list name"
                  value={newListName}
                  onChange={handleChange}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <FinalizeButton
                  onClick={() => handleRemoveOrUpdate(true)}
                  disabled={!canUpdate}
                  type="confirm"
                >
                  Update
                </FinalizeButton>
              </UpdateNameBox>
            )}
            {type === "remove" && listToRemove && (
              <FinalWarningBox>
                <h3>
                  Are you sure you want to remove the list named "{listToRemove}
                  "?
                </h3>
                <ButtonGroup>
                  <FinalizeButton
                    onClick={() => handleRemoveOrUpdate(true)}
                    type="confirm"
                  >
                    Yes
                  </FinalizeButton>
                  <FinalizeButton onClick={() => handleRemoveOrUpdate(false)}>
                    No
                  </FinalizeButton>
                </ButtonGroup>
              </FinalWarningBox>
            )}
          </ModalContent>
        </Modal>
      )}
    </NewListWrapper>
  );
};

export default RemoveOrUpdateListComponent;

// Styled Components
const NewListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
`;

const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: #1a1a1a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f65261;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
`;

const ModalContent = styled.div`
  padding: 20px;
  background-color: #2d2d2d;
  border-radius: 8px;
  text-align: center;
  color: white;
  width: 90%;
  max-width: 400px;
  position: relative;
`;

const ValidationCancelButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;

const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 10px;
`;

const ListLi = styled.li`
  padding: 8px;
  cursor: pointer;
  color: white;
  background-color: ${({ isSelected }) =>
    isSelected ? "#f65261" : "transparent"};
  &:hover {
    background-color: #f65261;
  }
`;

const UpdateNameBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

const StyledInput = styled.input`
  padding: 8px;
  border: none;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 100%;
  &:focus {
    outline: none;
    border-color: #f65261;
  }
`;

const ErrorMessage = styled.div`
  font-size: 0.8rem;
  color: #f65261;
  margin-bottom: 5px;
`;

const FinalWarningBox = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const FinalizeButton = styled.button`
  padding: 8px 16px;
  background-color: ${({ type }) => (type === "confirm" ? "green" : "#f65261")};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ type }) =>
      type === "confirm" ? "darkgreen" : "#ff7675"};
  }
  &:disabled {
    background-color: #777;
    cursor: not-allowed;
  }
`;
