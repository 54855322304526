import React, { useState, useContext, useEffect, useRef } from "react";
import {
  AiOutlineHeart,
  AiOutlineUnorderedList,
  AiOutlineEye,
} from "react-icons/ai";
import { MdOutlineDoneOutline } from "react-icons/md";
import styled, { css } from "styled-components";
import UserContext from "../../context/User/UserContext";
import AuthContext from "../../context/User/AuthContext";
import NewCardModal from "../CardModal/NewCardModal";
import StarComponent from "../RatingComponent/StarComponent";
import ToFavouriteButton from "./ToFavouriteButton";

const FavWishAdd = ({ showOrMovie, movieId, allDetails, watchChecker }) => {
  const [addClicked, setAddClicked] = useState(false);
  const [watClicked, setWatClicked] = useState(false);
  const [watched, setWatched] = useState(false);
  const [inLists, setInLists] = useState(false);
  const [inListName, setInListName] = useState([]);
  const [showNewListBox, setShowNewListBox] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [newList, setNewList] = useState("");
  const [createOrLogin, setCreateOrLogin] = useState();
  const movieIdNumber = parseInt(movieId);
  const [personalRating, setPersonalRating] = useState(null);
  const { removeDetailsFromState, addDetailsToState, user } =
    useContext(AuthContext);
  const {
    updateWatchlist,
    removeDataFromWatchlist,
    watchlist,
    watchedList,
    lists,
    addToList,
    removeFromList,
    checkIfInLists,
    updateList,
    addToRatings,
    ratings,
    creatingAndAddingToList,
    showrating,
    showRatingFunc,
  } = useContext(UserContext);
  const addButtonRef = useRef();

  useEffect(() => {}, [movieId]);

  const newListNameHandle = (e) => {
    setNewList(e.target.value);

    e.preventDefault();
  };

  useEffect(() => {
    watchChecker(watched, personalRating);
  }, [watched]);
  const newListHandle = (value) => {
    if (value) {
      creatingAndAddingToList(
        newList.charAt(0).toUpperCase() + newList.slice(1),
        movieIdNumber,
        showOrMovie
      );
      setShowNewListBox(false);
      setNewList("");
    }
    setShowValidation(false);
    setNewList("");
    setAddClicked(false);
  };

  const validationHandle = () => {
    let listNames = Object.keys(lists);
    if (newList?.length < 3) {
      alert("Name of the list have to be minimum 3 letters!");
      setNewList("");
    } else {
      if (listNames.includes(newList)) {
        setNewList("");
        alert(`The List with a name ${newList} is already exist!`);
      } else {
        setShowValidation(true);
      }
    }
  };

  const checkIfInWatchlist = () => {
    if (watchlist !== undefined) {
      if (showOrMovie === "movie") {
        if (watchlist.movies.includes(movieIdNumber)) {
          setWatClicked(true);
        } else {
          setWatClicked(false);
        }
      } else {
        if (watchlist.shows.includes(movieIdNumber)) {
          setWatClicked(true);
        } else {
          setWatClicked(false);
        }
      }
    }
  };

  const checkIfInWatched = () => {
    if (watchedList !== undefined) {
      if (showOrMovie === "movie") {
        if (watchedList.movies.includes(movieIdNumber)) {
          setWatched(true);
          const movieObj = ratings.movies.find(
            (movie) => movie.movieId === movieIdNumber
          );
          setPersonalRating(movieObj.rating);
        }
      } else {
        if (watchedList.shows.includes(movieIdNumber)) {
          setWatched(true);
          const showObj = ratings.shows.find(
            (movie) => movie.movieId === movieIdNumber
          );
          setPersonalRating(showObj.rating);
        }
      }
    }
  };

  useEffect(() => {
    if (!user) {
      setWatched(false);
      setAddClicked(false);
      setWatClicked(false);
      setInLists(false);
    }

    if (user) {
      checkIfInWatchlist();
      checkIfInWatched();
      if (lists) {
        setInListName(checkIfInLists(showOrMovie, movieIdNumber));
      }
    }
  }, [watchlist, addToList, watchedList, user, lists]);

  useEffect(() => {
    if (inListName.length > 0) {
      setInLists(true);
    } else {
      setInLists(false);
    }
  }, [inListName]);

  useEffect(() => {
    if (showrating) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to ensure scroll is enabled when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showrating]);

  const handleOutsideClick = (event) => {
    if (addButtonRef.current && !addButtonRef.current.contains(event.target)) {
      setAddClicked(false);
    }
  };

  const handleAdd = () => {
    setAddClicked(!addClicked);
  };
  const handleWat = () => {
    if (watClicked === true) {
      setWatClicked(false);
      removeDataFromWatchlist(movieIdNumber, showOrMovie, "watchlist");
      removeDetailsFromState("watchlist", showOrMovie, movieIdNumber);
    } else {
      setWatClicked(true);
      updateWatchlist(movieIdNumber, showOrMovie, "watchlist");
      addDetailsToState("watchlist", showOrMovie, allDetails);
    }
  };

  const handleWatched = () => {
    if (watched === true) {
      setWatched(false);
      removeDataFromWatchlist(movieIdNumber, showOrMovie, "watched");
      removeDetailsFromState("watched", showOrMovie, movieIdNumber);
    } else {
      showRatingFunc(true, movieIdNumber, showOrMovie, allDetails);
    }
  };

  const handleListAdding = (list) => {
    if (inListName?.includes(list)) {
      removeFromList(list, movieIdNumber, showOrMovie);
      removeDetailsFromState("lists", showOrMovie, movieIdNumber, list);
    } else {
      addToList(list, movieIdNumber, showOrMovie);
      addDetailsToState("lists", showOrMovie, allDetails, list);
    }
  };

  const handleModalClose = () => {
    setCreateOrLogin(false);
  };
  const handleModalOpen = () => {
    setCreateOrLogin(true);
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <MainBar>
      <NewCardModal
        handleModalClose={handleModalClose}
        createOrLogin={createOrLogin}
      />
      {watched && (
        <StarComponent rating={personalRating} location={"details"} />
      )}

      <NewListWrapper show={showNewListBox}>
        <ValidationWrapper show={showValidation}>
          <ValidationBox>
            <h3>
              Are you sure you want to make a new list with a name : {newList}
            </h3>
            <div>
              <button
                onClick={() => {
                  newListHandle(true);
                }}
              >
                <span style={{ fontSize: "20px" }}>Yes</span>
              </button>
              <button
                onClick={() => {
                  newListHandle(false);
                }}
              >
                <span style={{ fontSize: "20px" }}>No</span>
              </button>
            </div>
          </ValidationBox>
        </ValidationWrapper>
        <NewListBox>
          <CloseButton>
            <span
              onClick={() => {
                setShowNewListBox(false);
                setNewList("");
              }}
            >
              X
            </span>
          </CloseButton>
          <Wrapper>
            <h3>Add your new List</h3>
            <InputWrapper>
              <input
                placeholder="Type your new list name"
                onChange={newListNameHandle}
                value={newList}
              />
              <button onClick={validationHandle}>Add</button>
            </InputWrapper>
          </Wrapper>
        </NewListBox>
      </NewListWrapper>
      <ButtonContainer>
        <WatButton
          watClicked={watClicked}
          onClick={user ? handleWat : handleModalOpen}
        >
          <WatchlistIcon />
        </WatButton>
        <Destination>to watchlist</Destination>
      </ButtonContainer>
      <ButtonContainer>
        <AddButton
          ref={addButtonRef}
          show={inLists}
          addClicked={addClicked}
          onClick={user ? handleAdd : handleModalOpen}
        >
          <ListIcon />
        </AddButton>
        <Destination>to a list</Destination>
        {addClicked && (
          <ListWrapper>
            <ul>
              {Object.keys(lists).length > 0 ? (
                Object.keys(lists).map((list, idx) => {
                  return (
                    <ListLi
                      show={inListName.includes(list)}
                      key={idx}
                      onClick={() => {
                        setAddClicked(false);
                        handleListAdding(list);
                      }}
                    >
                      {list}
                    </ListLi>
                  );
                })
              ) : (
                <ListLi>No Lists yet</ListLi>
              )}
            </ul>
            <NewListLi
              onClick={() => {
                setShowNewListBox(true);
                setAddClicked(false);
              }}
            >
              <span>+</span>
            </NewListLi>
          </ListWrapper>
        )}
      </ButtonContainer>
      <ButtonContainer>
        <ToFavouriteButton
          location="details"
          id={allDetails.id}
          type={showOrMovie}
          allDetails={allDetails}
        />
        <Destination>to favourite</Destination>
      </ButtonContainer>
      <ButtonContainer>
        <WatchedButton
          watched={watched}
          onClick={user ? handleWatched : handleModalOpen}
        >
          <WatchedIcon />
        </WatchedButton>
        <Destination>watched</Destination>
      </ButtonContainer>
    </MainBar>
  );
};

export default FavWishAdd;

const sharedButtonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0 10px;
  border: 1px solid #fff;
  color: black;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

const MainBar = styled.div`
  width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  p {
    font-size: 12px;
    color: white;
  }

  @media screen and (max-width: 768px) {
    width: 250px;
    align-items: flex-start;
    margin-left: 0;
  }
`;

const FavButton = styled.div`
  ${sharedButtonStyle};
  background: ${(props) => (props.favClicked === true ? "red" : "black")};
`;

const AddButton = styled.div`
  ${sharedButtonStyle};
  background: ${({ show }) => (show === true ? "purple" : "#000")};
`;

const WatButton = styled.div`
  ${sharedButtonStyle};
  background: ${(props) => (props.watClicked === true ? "green" : "black")};
`;

const WatchedButton = styled.div`
  ${sharedButtonStyle};
  background: ${(props) => (props.watched === true ? "green" : "black")};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100px;

  @media screen and (max-width: 768px) {
    font-size: 10px;
    max-width: 60px;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  border: 1px solid #fff;
  position: absolute;
  top: 40px;
  background-color: #000;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;

  ul {
    margin: 0;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

const Destination = styled.div`
  font-size: 12px;
  color: white;
  margin-top: 5px;

  @media screen and (max-width: 768px) {
    font-size: 10px;
    margin: 5px 0;
    padding: 0;
  }
`;

const ListLi = styled.li`
  list-style: none;
  border-bottom: 0.5px solid #fff;
  color: #fff;
  background-color: ${(props) => props.show && "green"};
  padding: 5px 10px;
  &:hover {
    background-color: purple;
  }
`;

const NewListLi = styled.li`
  color: #fff;
  list-style: none;
  text-align: center;
  padding: 3px;
  &:hover {
    background-color: #323;
    span {
    }
  }
  span {
    color: white;
    font-size: 20px;
  }
`;

const NewListWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ show }) => (show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const NewListBox = styled.div`
  width: 30%;
  height: 300px;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 7px;
  box-shadow: 2px 2px 2px #eee;
  position: absolute;
  top: 40%;

  z-index: 100;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

const CloseButton = styled.div`
  width: 100%;
  height: 30px;
  display: flex;

  position: relative;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    color: #fff;
    margin: 3px;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #fff;
    cursor: pointer;
    position: absolute;
    right: 5px;
    font-weight: bold;
    &:hover {
      color: red;
      border-color: red;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    text-align: center;
    text-transform: uppercase;
  }
`;

const vButtonStyle = css`
  width: 50px;
  height: 40px;
  margin: 10px;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 7px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  &:hover {
    border-color: green;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  height: 50px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 50%;
    height: 30px;
  }

  button {
    ${vButtonStyle}
  }

  @media screen and (max-width: 768px) {
    input {
      font-size: 16px;
    }
  }
`;

const ValidationWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
  display: ${({ show }) => (show ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ValidationBox = styled.div`
  max-width: 100vw;
  height: 300px;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 7px;
  box-shadow: 2px 2px 2px #999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #000;
  text-align: center;
  z-index: 200;
  top: 2%;
  div {
    display: flex;
    margin: 5px;
  }
  button {
    ${vButtonStyle}
  }
  @media screen and (max-width: 768px) {
    width: 85%;

    top: 8%;
  }
`;

const RatingWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  display: ${({ show }) => (show ? "flex" : "none")};

  justify-content: center;

  @media screen and (max-width: 768px) {
    top: 30%;
  }
`;
const iconStyle = css`
  font-size: 15px;
  color: white;
`;

const FavouritesIcon = styled(AiOutlineHeart)`
  ${iconStyle}
`;
const WatchlistIcon = styled(AiOutlineEye)`
  ${iconStyle}
`;
const ListIcon = styled(AiOutlineUnorderedList)`
  ${iconStyle}
`;
const WatchedIcon = styled(MdOutlineDoneOutline)`
  ${iconStyle}
`;
