import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useReducer,
} from "react";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import userReducer from "./UserReducer";
import { db, storage, auth } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  setDoc,
  arrayUnion,
} from "firebase/firestore";

import TmdbContext from "../TmdbContext";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const TMDB_KEY = process.env.REACT_APP_TMDB_KEY;
const URL = "https://api.themoviedb.org/3/";
const lang = "&language=en-US&page=1";

export function AuthProvider({ children }) {
  const initialState = {
    register: false,
    userRegister: {},
    showNavButtons: true,
    user: null,
    loginError: null,
    registerError: null,
    id: null,
    imgUrl: null,
    watchlist: {
      movies: [],
      shows: [],
    },
    watched: {
      movies: [],
      shows: [],
    },
    ratings: {
      movies: [],
      shows: [],
    },
    favourites: {
      movies: [],
      shows: [],
    },
    favouriteActor: {
      actorId: [],
    },
    userEntertDetails: {
      watched: { movies: [], shows: [] },
      watchlist: { movies: [], shows: [] },
      favourites: { movies: [], shows: [] },
      favouriteactor: [],
      lists: { list: { movies: [], shows: [] } },
    },
    deleted: {},
    lists: { firstList: [] },
    loginstate: false,
  };

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getShortCardDetails, getActorDetails } = useContext(TmdbContext);
  const [state, dispatch] = useReducer(userReducer, initialState);
  const params = new URLSearchParams({
    api_key: TMDB_KEY,
  });

  // Get the id of the user
  const getDocId = async (userid) => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", userid));
      const snapshot = await getDocs(q);
      if (snapshot.empty) {
        throw new Error("User document not found");
      }
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      dispatch({
        type: "GET_DOC_ID",
        id: data[0].id,
      });
      return data[0].id;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  // Check if username exists
  const checkUsernameExists = async (username) => {
    const q = query(
      collection(db, "usernamelist"),
      where("list", "array-contains", { name: username })
    );
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  // Add new username to the list
  const addUsernameToList = async (username) => {
    const usernameDocRef = doc(db, "usernamelist", "usernameDocumentId"); // Replace with your actual document ID
    const docSnap = await getDoc(usernameDocRef);

    if (docSnap.exists()) {
      await updateDoc(usernameDocRef, {
        list: arrayUnion({ name: username }),
      });
    } else {
      await setDoc(usernameDocRef, {
        list: [{ name: username }],
      });
    }
  };

  // Get all data of user from firebase
  const getDataOfUser = async (id) => {
    const q = query(doc(db, "users", id));
    const snapshot = await getDoc(q);
    if (!snapshot.exists()) {
      throw new Error("User data not found");
    }
    return snapshot.data();
  };

  // Handling the user data after login or state change
  const actfavFullInfo = async (list) => {
    let peopleData = await getFavouritePeopleDetails(list);
    return peopleData;
  };

  const removeDetailsFromState = (
    path,
    typeOf,
    idToRemove,
    listName = null
  ) => {
    let newUserEntertDetails = { ...state.userEntertDetails };
    let type = typeOf === "movie" ? "movies" : "shows";

    if (path === "lists") {
      if (listName) {
        // If a specific listName is provided, only remove from that list
        newUserEntertDetails.lists[listName][type] = newUserEntertDetails.lists[
          listName
        ][type].filter((item) => item.id !== idToRemove);
      } else {
        // If no specific listName is provided, remove from all lists
        Object.keys(newUserEntertDetails.lists).forEach((listName) => {
          newUserEntertDetails.lists[listName][type] =
            newUserEntertDetails.lists[listName][type].filter(
              (item) => item.id !== idToRemove
            );
        });
      }
    } else {
      // For paths other than 'lists', directly filter out the item from the specified type (movies or shows)
      newUserEntertDetails[path][type] = newUserEntertDetails[path][
        type
      ].filter((item) => item.id !== idToRemove);
    }
    // Dispatch the updated state
    dispatch({
      type: "UPDATE_USER_ENTERT_DETAILS",
      payload: newUserEntertDetails,
    });
  };

  const getFavouritePeopleDetails = async (ids) => {
    const fetchPromises = ids.map((id) =>
      fetch(`${URL}person/${id}?${params}${lang}`).then((response) =>
        response.json()
      )
    );
    try {
      const actorsDetails = await Promise.all(fetchPromises);
      dispatch({
        type: "GET_FAVOURITE_PEOPLES_DETAILS",
        payload: actorsDetails,
      });
      return actorsDetails;
    } catch (error) {
      console.error("Failed to fetch favourite people details:", error);
    }
  };

  const updateFavouritePeopleDetails = async (id, actionType) => {
    if (actionType === "add") {
      // Fetch details for the new actor
      try {
        const response = await fetch(
          `${URL}person/${id}?${params}${lang}`
        ).then((response) => response.json());
        const newActorDetails = response;

        // Dispatch an action to add this actor's details to the state
        dispatch({
          type: "ADD_FAVOURITE_PERSON_DETAILS",
          payload: newActorDetails,
        });
      } catch (error) {
        console.error("Failed to fetch new favourite person details:", error);
      }
    } else if (actionType === "remove") {
      const updatedFavouriteActors =
        state.userEntertDetails.favouriteActor.filter(
          (actor) => actor.id !== id
        );
      dispatch({
        type: "REMOVE_FAVOURITE_PERSON_DETAILS",
        payload: id,
      });
      console.log("Updated Favourite Actors:", updatedFavouriteActors);
    }
  };

  // Refactored to accept a fourth argument conditionally based on the `path`
  const addDetailsToState = (path, typeOf, itemToAdd, listName = null) => {
    let newUserEntertDetails = { ...state.userEntertDetails };
    let type = typeOf === "movie" ? "movies" : "shows";

    if (path === "lists" && listName) {
      // Ensure listName is provided for "lists" path
      if (!newUserEntertDetails.lists[listName]) {
        newUserEntertDetails.lists[listName] = { movies: [], shows: [] }; // Initialize if list doesn't exist
      }
      // Check for existing item to avoid duplicates
      if (
        !newUserEntertDetails.lists[listName][type].some(
          (existingItem) => existingItem.id === itemToAdd.id
        )
      ) {
        newUserEntertDetails.lists[listName][type].push(itemToAdd);
      }
    } else if (path !== "lists") {
      // Handle non-list paths
      // Directly add the item to the specified type (movies or shows), avoiding duplicates
      if (
        !newUserEntertDetails[path][type].some(
          (existingItem) => existingItem.id === itemToAdd.id
        )
      ) {
        newUserEntertDetails[path][type].push(itemToAdd);
      }
    } else {
      console.error("List name must be provided when path is 'lists'.");
      return; // Exit the function if we're supposed to add to a list but no list name is provided
    }

    // Dispatch the updated state
    dispatch({
      type: "UPDATE_USER_ENTERT_DETAILS",
      payload: newUserEntertDetails,
    });
  };

  const handleUserData = async (uid) => {
    const docId = await getDocId(uid);
    if (!docId) {
      return;
    }

    const userData = await getDataOfUser(docId);
    const [watchedFullInfo, watchlistFullInfo, favFullInfo, actorFullDetails] =
      await Promise.all([
        getShortCardDetails(userData.watched),
        getShortCardDetails(userData.watchlist),
        getShortCardDetails(userData.favourites),
        actfavFullInfo(userData.favouriteactor.actorId),
      ]);

    const listPromises = Object.entries(userData.lists).map(
      async ([listName, listContents]) => {
        const listFullData = await getShortCardDetails(listContents || {});

        return [
          listName,
          { movies: listFullData.movies, shows: listFullData.shows },
        ];
      }
    );
    const listsFullInfoEntries = await Promise.all(listPromises);
    const listsFullInfo = Object.fromEntries(listsFullInfoEntries);

    const userEntertDetails = {
      watched: { movies: watchedFullInfo.movies, shows: watchedFullInfo.shows },
      watchlist: {
        movies: watchlistFullInfo.movies,
        shows: watchlistFullInfo.shows,
      },
      favourites: {
        movies: favFullInfo.movies,
        shows: favFullInfo.shows,
      },
      favouriteActor: actorFullDetails,
      lists: listsFullInfo,
    };

    dispatch({
      type: "LOGIN",
      payload: userData,
      watchlist: userData.watchlist,
      watched: userData.watched,
      userEntertDetails: userEntertDetails,
      ratings: userData.ratings,
      favourites: userData.favourites,
      favouriteactor: userData.favouriteactor,
      lists: userData.lists,
      imgUrl: userData.photoUrl,
    });
  };

  // Login
  const login = async (
    loginEmail,
    loginPassword,
    setLoginPassword,
    setLoginEmail
  ) => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );

      const docId = await getDocId(user.user.uid);

      const userData = await getDataOfUser(docId);
      handleUserData(user.user.uid);

      dispatch({
        type: "LOGIN",
        error: null,
        payload: userData,
      });

      setLoginPassword("");
      setLoginEmail("");
    } catch (error) {
      console.log(error.message);

      if (error.message === "Firebase: Error (auth/user-not-found).") {
        dispatch({
          type: "LOGIN_ERROR",
          payload: "User not found",
        });
      } else if (error.message === "Firebase: Error (auth/wrong-password).") {
        dispatch({
          type: "LOGIN_ERROR",
          payload: "Wrong Password",
        });
      } else {
        dispatch({
          type: "LOGIN_ERROR",
          payload: error.message,
        });
      }
    }
  };

  // Google login
  const googleProvider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;

      const q = query(collection(db, "users"), where("uid", "==", user.uid));
      const docs = await getDocs(q);
      if (docs.docs.length === 0) {
        let username;
        let usernameExists = true;

        while (usernameExists) {
          username = prompt("Please enter a username:");
          if (!username) {
            alert("Username cannot be empty. Please try again.");
            continue;
          }
          usernameExists = await checkUsernameExists(username);
          if (usernameExists) {
            alert("Username already in use. Please try again.");
          }
        }
        await addDoc(collection(db, "users"), {
          uid: user.uid,
          name: user.displayName,
          username,
          photoUrl: user.photoURL,
          authProvider: "google",
          email: user.email,
          watchlist: {
            shows: [],
            movies: [],
          },
          watched: {
            shows: [],
            movies: [],
          },
          ratings: {
            shows: [],
            movies: [],
          },
          favourites: {
            shows: [],
            movies: [],
          },
          favouriteactor: {
            actorId: [],
          },
          lists: {},
        });

        await addUsernameToList(username);
      }
      handleUserData(user.uid);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const handleGoogleLogin = async () => {
    const uid = await signInWithGoogle();
    if (uid) {
      handleUserData(uid);
    }
  };

  // Display on Login page register form
  const handleRegisterClick = (name) => {
    if (name === "register") {
      dispatch({
        type: "SET_REGISTER_CLICKED",
        payload: true,
        loginstate: true,
      });
    } else {
      dispatch({
        type: "SET_REGISTER_CLICKED",
        payload: false,
        loginstate: true,
      });
    }
  };

  const handleLoginState = (input) => {
    dispatch({
      type: "SET_LOGINSTATE",
      payload: input,
    });
  };

  // Logout
  const handleLogout = async () => {
    const confirm = window.confirm("Are you sure you want to logout?");
    if (confirm) {
      await signOut(auth);

      dispatch({
        type: "LOGOUT",
        payload: null,
      });
      window.location.href = "/";
    }
  };

  // Register
  const handleRegister = (firstName, sirName, email, password) => {
    const user = {
      firstName: firstName,
      sirName: sirName,
      email: email,
      password: password,
      initials: firstName.charAt(0) + sirName.charAt(0),
    };
    dispatch({
      type: "REGISTER_USER",
      payload: user,
    });
  };

  const registerFunc = async (
    email,
    password,
    setErrorSpan,
    name,
    username,
    setLoading
  ) => {
    try {
      const usernameExists = await checkUsernameExists(username);
      if (usernameExists) {
        setErrorSpan("*Username already in use*");
        setLoading(false);
        return;
      }

      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;

      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name,
        username,
        authProvider: "local",
        email,
        watchlist: {
          shows: [],
          movies: [],
        },
        watched: {
          shows: [],
          movies: [],
        },
        ratings: {
          shows: [],
          movies: [],
        },
        favourites: {
          shows: [],
          movies: [],
        },
        favouriteactor: {
          actorId: [],
        },
        lists: {},
        photoUrl:
          "https://cdn.pixabay.com/photo/2017/07/10/11/28/bulldog-2489829_1280.jpg",
      });

      await addUsernameToList(username);

      dispatch({
        type: "LOGIN",
        error: null,
      });

      await handleUserData(user.uid);
    } catch (error) {
      console.log(error.message);
      if (error.message === "Firebase: Error (auth/email-already-in-use).") {
        setErrorSpan("*Email already in use*");
      } else {
        setErrorSpan(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  // Upload image to firebase
  const handleImageUploadToFirebase = async (file) => {
    const docId = state.id; // get the doc id using the getDocId function

    const storageRef = ref(storage, `images/${docId}/${state.user.uid}`);
    uploadBytes(storageRef, file)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          updPhotoUrl(url);
        });
        console.log("Image uploaded successfully.");
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  // Update PhotoUrl
  const updPhotoUrl = (newData) => {
    const docRef = doc(db, "users", state.id);
    let newPhoto = state.imgUrl;
    newPhoto = newData;
    updateDoc(docRef, {
      photoUrl: newPhoto,
    });
    dispatch({
      type: "UPDATE_IMG_URL",
      payload: newPhoto,
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) {
        handleUserData(user.uid);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    handleRegisterClick,
    handleRegister,
    handleGoogleLogin,
    registerFunc,
    handleLogout,
    login,
    handleLoginState,
    handleImageUploadToFirebase,
    removeDetailsFromState,
    addDetailsToState,
    updateFavouritePeopleDetails,
    imgUrl: state.imgUrl,
    loginError: state.loginError,
    register: state.register,
    user: state.user,
    loginstate: state.loginstate,
    userEntertDetails: state.userEntertDetails,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
