import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import LeaveReviews from "./LeaveReviews";
import { ReviewsContext } from "../../context/Reviews/ReviewsContext";
import AuthContext from "../../context/User/AuthContext";
import NewReviews from "./NewReviews";
import Reviews from "./Reviews";

const ReviewsSection = ({ type, id, title, reviews, watched, ratingScore }) => {
  const { user } = useContext(AuthContext);
  const [expandedReview, setExpandedReview] = useState(null);
  const { checkIfReviewExists, myreviews } = useContext(ReviewsContext);
  const [reviewExist, setReviewExist] = useState(false);
  const [showLeaveReview, setShowLeaveReview] = useState(false);

  useEffect(() => {
    if (user && myreviews) {
      const reviews = myreviews[type === "movie" ? "movies" : "shows"];
      if (reviews && reviews[id]) {
        const includesReview = Object.values(reviews[id]).some(
          (review) => review.userId === user.uid
        );
        setReviewExist(includesReview);
      }
    }

    if (user && !myreviews?.[id]) {
      checkIfReviewExists(id, type);
    }

    // Determine if LeaveReviews should be shown
    if (user && watched && !reviewExist) {
      setShowLeaveReview(true);
    } else {
      setShowLeaveReview(false);
    }
  }, [id, type, myreviews, checkIfReviewExists, user, watched, reviewExist]);

  return (
    <MainWrapper>
      <InsideWrapper
        showLeaveReview={showLeaveReview}
        hasReviews={reviews.length > 0}
      >
        {showLeaveReview && (
          <LeaveReviews
            type={type === "tv" ? "show" : "movie"}
            id={id}
            title={title}
            uid={user.uid}
            ratingScore={ratingScore}
            name={user.username}
          />
        )}
        {user &&
          myreviews[type === "movie" ? "movies" : "shows"][id] &&
          Object.keys(myreviews[type === "movie" ? "movies" : "shows"][id])
            .length > 0 &&
          Object.values(
            myreviews[type === "movie" ? "movies" : "shows"][id]
          ).map((review, idx) => (
            <Reviews
              key={idx}
              review={review}
              username={review.username || "missing"}
              rate={review.ratingScore || 0}
              content={review.review}
              expanded={expandedReview === idx}
              setExpanded={() =>
                setExpandedReview(expandedReview === idx ? null : idx)
              }
            />
          ))}
        <NewReviews reviews={reviews} />
      </InsideWrapper>
    </MainWrapper>
  );
};

export default ReviewsSection;

const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

const InsideWrapper = styled.div`
  height: ${(props) =>
    props.showLeaveReview || props.hasReviews ? "400px" : "0"};
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #444; /* For Firefox */

  /* For WebKit-based browsers (Chrome, Safari, Edge) */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #444; /* Background of the track */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar color */
    border-radius: 10px;
    border: 2px solid #444; /* Space around the scrollbar */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Scrollbar color on hover */
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    height: ${(props) =>
      props.showLeaveReview || props.hasReviews ? "400px" : "0"};
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar on mobile devices */
    }
  }
`;
