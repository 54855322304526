import React from "react";
import styled from "styled-components";

const ReviewsModal = ({ children, onClose }) => {
  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <div>{children}</div>

        <Button onClick={onClose}>OK</Button>
      </ModalContent>
    </ModalBackdrop>
  );
};

export default ReviewsModal;

// Styled Components
const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #2d2d2d;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  color: white;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: green;
  }
`;
