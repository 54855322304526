import React from "react";
import styled from "styled-components";
import ReactShowMoreText from "react-show-more-text";

const Reviews = (props) => {
  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString();
  };
  return (
    <UserContainer onClick={props.setExpanded}>
      <Author>
        Author: <span>{props.username}</span>
      </Author>
      <DateDiv>
        {formattedDate(
          props.review.created_at || props.review.timestamp?.seconds * 1000
        )}
      </DateDiv>
      <ReactShowMoreText lines={props.expanded ? 999 : 1}>
        <Content>{props.content}</Content>
      </ReactShowMoreText>
      <Rate>
        Rate:
        <UserScore rating={props.rate}>
          <h3>{props.rate}</h3>
        </UserScore>
      </Rate>
    </UserContainer>
  );
};

export default Reviews;

const UserContainer = styled.div`
  width: 100%;
  box-sizing: border-box; /* Ensures padding is included in the element's width and height */
  padding: 10px 20px; /* Adjusted padding */
  display: flex;
  flex-direction: column;
  background: transparent;
  border: solid 1px white;
  margin: 10px 0;
  border-radius: 8px;
  color: white;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    margin: 5px 0;
  }
`;

const Author = styled.h3`
  color: white;
  margin: 10px 0;
  color: red;

  span {
    color: white;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin: 6px 0;
  }
`;

const DateDiv = styled.div`
  color: #666;
  margin: 5px 0;
  font-size: 14px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Content = styled.div`
  color: white;
  margin: 10px 0;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const Rate = styled.h3`
  color: red;
  margin: 10px 0;
  display: flex;
  align-items: center;

  span {
    color: white;
    margin-left: 10px;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const UserScore = styled.div`
  height: 20px;
  width: 20px;
  border: 3px solid ${(props) => (props.rating >= 6 ? "green" : "red")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  h3 {
    color: #fff;
    font-size: 12px;
  }
`;
