import React, { useContext, useState, useEffect, useCallback } from "react";
import SizeContext from "../../context/SizeContext";
import styled from "styled-components";
import Card from "./Card";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const CategoriesWrapper = (props) => {
  const { handleResize } = useContext(SizeContext);
  const [isHovering, setIsHovering] = useState(false);
  const [loadingA, setLoadingA] = useState(true);
  const style = { color: "white", margin: "4px", fontSize: "20px" };
  const contentWrapper = React.useRef(null);

  const handleMouseOver = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setIsHovering(false);
  }, []);

  useEffect(() => {
    handleResize();
    setLoadingA(false);
  }, []);

  const sideScroll = (
    element: HTMLDivElement,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  return (
    <Wrap side={props.side} id="cardWrapper">
      <Title side={props.side} type={props.type}>
        {props.name}
      </Title>
      <Scrolling onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        {isHovering == true &&
          contentWrapper?.current?.clientWidth <
            contentWrapper?.current?.scrollWidth && (
            <button
              onClick={() => sideScroll(contentWrapper?.current, 1, 1, -300)}
            >
              <IoIosArrowBack style={style} />
            </button>
          )}
        <Container ref={contentWrapper}>
          {props.movies?.map((movie, idx) => (
            <Box key={idx}>
              <Card
                wrapper="blackBg"
                categ={props.categ}
                page={props.page}
                bg={movie.poster_path}
                id={movie.id}
                type={props.type}
                rating={movie.vote_average}
                character={movie.character}
                release={movie.release_date || movie.first_air_date}
                title={movie.title || movie.name}
                allDetails={movie}
              />
            </Box>
          ))}
        </Container>
        {isHovering == true &&
          contentWrapper?.current?.clientWidth <
            contentWrapper?.current?.scrollWidth && (
            <button
              onClick={() => sideScroll(contentWrapper?.current, 1, 1, 300)}
            >
              <IoIosArrowForward style={style} />
            </button>
          )}
      </Scrolling>
    </Wrap>
  );
};

export default CategoriesWrapper;

const Wrap = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 100px 0;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 40px 0;
  }
`;

const Title = styled.h3`
  background-color: black;
  color: white;
  width: 97%;
  margin: 0;
  text-align: ${(props) => (props.side === "left" ? "left" : "right")};
  border-radius: 8px 8px 0 0;
  align-self: center;
  font-size: 18px;
  padding: 5px 0;

  &:before {
    color: red;
    font-size: 18px;
    padding-left: 10px;
    content: "${(props) =>
      props.side === "left" || props.side === "other" ? "SHOWS " : ""}";
  }

  &:after {
    color: red;
    font-size: 18px;
    padding-right: 10px;
    content: "${(props) =>
      props.side === "right" || props.side === "center" ? " MOVIES" : ""}";
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 16px;
    max-width: 100%;
    ${
      "" /* margin: 16px 45px 16px 0;
    padding:5px 80px; */
    }
    &:before {
      font-size: 16px;
    }
    &:after {
      font-size: 16px;
    }
  }
`;

const Scrolling = styled.div`
  display: flex;
  width: 97%;
  align-items: center;
  background-color: black;
  border-radius: 0 0 8px 8px;

  button {
    z-index: 1;
    border: none;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    margin: 0 -20px;
    cursor: pointer;
  }
`;

const Container = styled.div`
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  text-align: center;
  line-height: 1; /* make bottom padding same as top padding by removing line-height */
  vertical-align: middle;

  ::-webkit-scrollbar {
    border: 1px solid #000;
    height: 10px;
    border-radius: 7px;
    background-color: #000; /* Adjust the background color of the scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #fff; /* Adjust the color of the scrollbar thumb */
    border-radius: 7px;
    border: 1px solid #000;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #eee; /* Adjust the color of the scrollbar thumb on hover */
  }
  @media screen and (max-width: 768px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Box = styled.div`
  display: inline-block;
  padding: 0.5vh;
  white-space: normal;
`;

const Slick = styled(Slider)`
  max-width: 97%;

  ${"" /* height:350px; */}
  overflow:hidden;
  background-color: black;
`;
