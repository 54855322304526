import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { createGlobalState } from "react-use";

const FavouritePersonCard = ({ person, handleFavouriteActorRemove }) => {
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);

  const navigateToActorDetails = (id) => {
    navigate(`/actordetails/${id}`);
  };
  const mouseEnterHandler = () => {
    setShowMessage(true);
  };
  const mouseLeaveHandler = () => {
    setShowMessage(false);
  };

  return (
    <FavouritePersonWrapper>
      <FavPersonRemoveButton
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
        onClick={() => handleFavouriteActorRemove(person.id)}
      >
        <RemoveInfo showMessage={showMessage}>
          Remove {person.name} from favourites
        </RemoveInfo>
        X
      </FavPersonRemoveButton>

      <FavouritePerson onClick={() => navigateToActorDetails(person.id)}>
        <FavouriteImage
          src={`https://www.themoviedb.org/t/p/original${person.profile_path}`}
        />
        <FavouriteNameWrapper>
          <FavouriteName>{person.name}</FavouriteName>
          <FavouriteDOB>
            <Desc>Date of Birth </Desc>

            <Info>{person.birthday.split("-").reverse().join("-")}</Info>
            <Desc>Place of Birth </Desc>
            <Info>{person.place_of_birth}</Info>
          </FavouriteDOB>
        </FavouriteNameWrapper>
      </FavouritePerson>
    </FavouritePersonWrapper>
  );
};

export default FavouritePersonCard;

const FavouritePerson = styled.div`
  display: flex;
  height: 140px;
  width: 300px;
  margin: 10px;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: 8px;
  background: white;
  box-shadow: rgb(136, 136, 136) 2px 2px 2px;
  color: #000;
  cursor: pointer;

  &:hover {
    background: ${({ isRemoveButtonHovered }) =>
      isRemoveButtonHovered ? "white" : "gray"};
  }
`;

const FavPersonRemoveButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #000;
  font-size: 13px;
  z-index: 50;
  cursor: pointer;
  color: #000;

  &:hover {
    border: 1px solid red;
    color: red;
  }
`;

const FavouritePersonWrapper = styled.div`
  position: relative;
  &:hover ${FavPersonRemoveButton} {
    border: 1px solid red;
    color: red;
  }
`;

const FavouriteImage = styled.img`
  width: 35%;
  height: 100%;
  margin-right: 5%;
`;

const FavouriteNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    color: red;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const FavouriteName = styled.h4`
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 3px 0;
`;

const Desc = styled.div`
  font-weight: bold;
  margin: 4px 0;
`;

const Info = styled.div`
  font-size: 14px;
  margin: 2px 0;
`;

const FavouriteDOB = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: black;
`;

const RemoveInfo = styled.span`
  position: absolute;
  display: ${(props) => !props.showMessage && "none"};
  z-index: 60;
  top: ${({ watched }) => (watched ? "90px" : "45px")};
  right: -40px;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 7px;
  font-weight: bold;
  font-size: 12px;
  padding: 3px;
  color: #000;
`;
