import React, { useEffect, useContext, useState } from "react";
import TmdbContext from "../../context/TmdbContext";
import UserContext from "../../context/User/UserContext";
import DetailsWrapper from "../CardWrapper/DetailsWrapper";
import styled from "styled-components";
import { UniversalButtonStyles } from "../../UniversalStyles";
import AuthContext from "../../context/User/AuthContext";

const WatchedMovies = ({ show }) => {
  const { watchedList, ratings } = useContext(UserContext);
  const { userEntertDetails } = useContext(AuthContext);
  const [movieList, setMovieList] = useState([]);
  const [showList, setShowList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState("DEFAULT");
  const [movieOrTv, setMovieOrTv] = useState("movie");
  const [movieOrShowList, setMovieOrShowList] = useState([]);

  const movieOrTvHandler = (input) => {
    setMovieOrTv(input);
  };

  useEffect(() => {
    // Add ratings to movies
    const moviesWithRatings = userEntertDetails.watched.movies.map((movie) => {
      const movieRating = ratings.movies.find(
        (rating) => rating.movieId === movie.id
      );
      return { ...movie, rating: movieRating ? movieRating.rating : null };
    });

    // Add ratings to shows
    const showsWithRatings = userEntertDetails.watched.shows.map((show) => {
      const showRating = ratings.shows.find(
        (rating) => rating.movieId === show.id
      );

      return { ...show, rating: showRating ? showRating.rating : null };
    });

    setMovieList(moviesWithRatings);
    setShowList(showsWithRatings);
  }, [userEntertDetails]);

  useEffect(() => {
    // Combine movies and shows into a single list with a common structure

    let combinedList = [
      ...movieList.map((movie) => ({
        ...movie,
        titleOrName: movie.title, // Unified title field for movies
        type: "movie",
        date: movie.release_date, // Using release_date for movies
      })),
      ...showList.map((show) => ({
        ...show,
        titleOrName: show.name, // Unified title field for shows
        type: "tv",
        date: show.first_air_date, // Using first_air_date for shows
      })),
    ];

    // Apply search filter to the combined list if there's a query
    if (searchQuery) {
      combinedList = combinedList.filter((item) =>
        item.titleOrName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply additional sorting based on user selection
    combinedList.sort((a, b) => {
      switch (sortType) {
        case "ratingLowToHigh":
          return (a.vote_average || 0) - (b.vote_average || 0);
        case "ratingHighToLow":
          return (b.vote_average || 0) - (a.vote_average || 0);
        case "personalRatingLowToHigh":
          return (a.rating || 0) - (b.rating || 0);
        case "personalRatingHighToLow":
          return (b.rating || 0) - (a.rating || 0);
        case "aToZ":
          return a.titleOrName.localeCompare(b.titleOrName);
        case "zToA":
          return b.titleOrName.localeCompare(a.titleOrName);
        case "releaseLowToHigh":
          return new Date(a.date) - new Date(b.date);
        case "releaseHighToLow":
          return new Date(b.date) - new Date(a.date);
        default:
          return 0; // No additional sorting if 'DEFAULT' or unrecognized sortType
      }
    });

    // Update the state to hold the filtered and sorted combined list
    setMovieOrShowList(combinedList);
  }, [movieList, showList, sortType, searchQuery]); // Dependency array includes all relevant state

  const sortedMovieList = movieList.slice().sort((a, b) => {
    if (sortType === "ratingLowToHigh") {
      return a.vote_average - b.vote_average;
    } else if (sortType === "ratingHighToLow") {
      return b.vote_average - a.vote_average;
    } else if (sortType === "personalRatingHighToLow") {
      const ratingA = a.rating || 0; // Assume unrated movies have a rating of 0
      const ratingB = b.rating || 0;
      return ratingB - ratingA;
    } else if (sortType === "personalRatingLowToHigh") {
      const ratingA = a.rating || 0; // Assume unrated movies have a rating of 0
      const ratingB = b.rating || 0;
      return ratingA - ratingB;
    } else if (sortType === "aToZ") {
      return a.title < b.title ? -1 : 1;
    } else if (sortType === "zToA") {
      return b.title < a.title ? -1 : 1;
    } else if (sortType === "releaseLowToHigh") {
      return (
        new Date(a.release_date).getTime() - new Date(b.release_date).getTime()
      );
    } else if (sortType === "releaseHighToLow") {
      return (
        new Date(b.release_date).getTime() - new Date(a.release_date).getTime()
      );
    } else {
      return 0;
    }
  });

  const sortedShowList = showList.slice().sort((a, b) => {
    if (sortType === "ratingLowToHigh") {
      return a.vote_average - b.vote_average;
    } else if (sortType === "ratingHighToLow") {
      return b.vote_average - a.vote_average;
    } else if (sortType === "personalRatingHighToLow") {
      const ratingA = a.rating || 0; // Assume unrated shows have a rating of 0
      const ratingB = b.rating || 0;
      return ratingB - ratingA;
    } else if (sortType === "personalRatingLowToHigh") {
      const ratingA = a.rating || 0; // Assume unrated shows have a rating of 0
      const ratingB = b.rating || 0;
      return ratingA - ratingB;
    } else if (sortType === "aToZ") {
      return a.name < b.name ? -1 : 1;
    } else if (sortType === "zToA") {
      return b.name < a.name ? -1 : 1;
    } else if (sortType === "releaseLowToHigh") {
      return (
        new Date(a.first_air_date).getTime() -
        new Date(b.first_air_date).getTime()
      );
    } else if (sortType === "releaseHighToLow") {
      return (
        new Date(b.first_air_date).getTime() -
        new Date(a.first_air_date).getTime()
      );
    } else {
      return 0;
    }
  });

  // Filter movieList and showList based on searchQuery
  const filteredMovieList = sortedMovieList.filter(
    (movie) =>
      movie.title &&
      movie?.title?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredShowList = sortedShowList.filter(
    (show) =>
      show.name && show.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  if (show) {
    return (
      <WatchedMoviesWrapper>
        <TopWrapper>
          <SearchInput
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search in watched"
          />
          <SortByWrapper>
            <span>Sort By:</span>
            <SortBySelect
              value={sortType}
              onChange={(e) => setSortType(e.target.value)}
            >
              <option value="DEFAULT" disabled>
                Select
              </option>
              <option value="ratingLowToHigh">Rating Low to High</option>
              <option value="ratingHighToLow">Rating High to Low</option>
              <option value="personalRatingLowToHigh">
                Personal Rating Low to High
              </option>
              <option value="personalRatingHighToLow">
                Personal Rating High to Low
              </option>
              <option value="aToZ">Title A to Z</option>
              <option value="zToA">Title Z to A</option>
              <option value="releaseLowToHigh">Release Oldest to Latest</option>
              <option value="releaseHighToLow">Release Latest to Oldest</option>
            </SortBySelect>
          </SortByWrapper>
          <ListSelector>
            <ListButton
              onClick={() => movieOrTvHandler("movie")}
              show={movieOrTv === "movie"}
            >
              MOVIES ({watchedList.movies.length})
            </ListButton>
            <ListButton
              onClick={() => movieOrTvHandler("tv")}
              show={movieOrTv === "tv"}
            >
              SHOWS ({watchedList.shows.length})
            </ListButton>
            <ListButton
              onClick={() => movieOrTvHandler("all")}
              show={movieOrTv === "all"}
            >
              ALL ({watchedList.shows.length + watchedList.movies.length})
            </ListButton>
          </ListSelector>
        </TopWrapper>
        <WatchedMoviesList>
          {watchedList.movies.length === 0 &&
            watchedList.shows.length === 0 && (
              <span>Your Watched List is Empty</span>
            )}
          {movieOrTv === "all" && ( // Updated rendering logic for 'all' option
            <>
              {watchedList.movies.length !== 0 && (
                <DetailsWrapper
                  movies={movieOrShowList}
                  type="movie"
                  page="detailsPage"
                  where="watchedMovies"
                />
              )}
            </>
          )}
          {movieOrTv === "movie" && watchedList.movies.length !== 0 && (
            <DetailsWrapper
              movies={filteredMovieList}
              type="movie"
              page="detailsPage"
              where="watchedMovies"
              location="watched"
            />
          )}
          {movieOrTv === "tv" && watchedList.shows.length !== 0 && (
            <DetailsWrapper
              movies={filteredShowList}
              type="tv"
              page="detailsPage"
              where="watchedMovies"
              location="watched"
            />
          )}
        </WatchedMoviesList>
      </WatchedMoviesWrapper>
    );
  }
};

export default WatchedMovies;

const WatchedMoviesWrapper = styled.div`
  min-height: 600px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    width: 100vw;
    justify-content: center;
    align-items: center;
  }
`;

const TopWrapper = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 25px 0 0 40px;

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    margin: 20px 0 0 0;
  }
`;

const WatchedMoviesList = styled.div`
  margin: 20px;
  height: 500px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 7px;
    background-color: #f5f5f5; /* Adjust the background color of the scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Adjust the color of the scrollbar thumb */
    border-radius: 7px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Adjust the color of the scrollbar thumb on hover */
  }
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const SearchInput = styled.input`
  width: 200px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const SortByWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }
`;

const SortBySelect = styled.select`
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-left: 10px;
`;

const ListSelector = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

const ListButton = styled.button`
  color: ${({ show }) => (show ? "powderblue" : "black")};
  background: ${({ show }) => (show ? "black" : "white")};
  ${UniversalButtonStyles};
  margin: 0 20px 0 0;
`;
