import React, {
  useContext,
  useEffect,
  useRef,
  useCallback,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { BiSearchAlt2 } from "react-icons/bi";
import SearchBox from "../SearchBox/SearchBox";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/User/AuthContext";
import { NavLink } from "react-router-dom";
import SizeContext from "../../context/SizeContext";
import LoginComponent from "../LoginComponent/LoginComponent";
import logoRed from "../../assets/images/logoRed.png";
import NavbarLinks from "./NavbarLinks";
import Rating from "../RatingComponent/Rating";
import UserContext from "../../context/User/UserContext";

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const { showrating, ratingobj } = useContext(UserContext);
  const { handleRegisterClick, user, handleLogout, imgUrl, loginstate } =
    useContext(AuthContext);
  const { isSmall } = useContext(SizeContext);
  const sidebarRef = useRef(null);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    handleLogout();
    setIsSidebarOpen(false);
  }, [handleLogout]);

  const handleToggleSidebar = useCallback(() => {
    setIsSidebarOpen((prevState) => !prevState);
  }, []);

  const handleLinkClick = useCallback(
    (link) => {
      setIsSidebarOpen(false);
      navigate(`/user/${link}`);
    },
    [navigate]
  );

  useEffect(() => {
    if (showrating || (isSmall && isSidebarOpen)) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showrating, isSidebarOpen, isSmall]);

  useEffect(() => {
    // add click listener to document when the sidebar is open
    if (isSidebarOpen) {
      function handleClickOutside(event) {
        if (!sidebarRef.current.contains(event.target)) {
          setIsSidebarOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    // add click listener to document when the search bar is open

    if (isSearchClicked) {
      function handleClickOutside(event) {
        if (!searchRef.current.contains(event.target)) {
          setIsSearchClicked(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isSearchClicked]);

  const handleSearchClick = () => {
    setIsSearchClicked(!isSearchClicked);
  };

  return (
    <NavbarContainer ref={sidebarRef}>
      {loginstate && <LoginComponent />}
      {showrating && (
        <RatingWrapper show={showrating}>
          <Rating
            movie={ratingobj.id}
            screenType={ratingobj.type}
            allDetails={ratingobj.details}
          />
        </RatingWrapper>
      )}
      <AllContainer>
        <LogoWrapper>
          <Logo
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
              setIsSidebarOpen(false);
            }}
          />
        </LogoWrapper>
        <NavbarLinksWrapper>
          <NavbarLinks />
        </NavbarLinksWrapper>

        <ButtonsContainer>
          {user ? (
            <LoggedContainer id="loggedContainer">
              {isSmall && !isSearchClicked ? (
                <span>
                  <SearchBox />
                </span>
              ) : (
                <>
                  <SearchBoxWrapper
                    id="searchBoxWrapper"
                    user={user}
                    ref={searchRef}
                  >
                    <SearchBox />
                  </SearchBoxWrapper>
                </>
              )}
              {user?.photoUrl && (
                <UserImage
                  content="no-referrer"
                  onClick={handleToggleSidebar}
                  image={imgUrl}
                >
                  {user.initials}
                </UserImage>
              )}
            </LoggedContainer>
          ) : (
            <LoggedContainer>
              {isSmall && !isSearchClicked ? (
                <span>
                  <SearchBox />
                </span>
              ) : (
                <>
                  <SearchWrapSpan>
                    <SearchIcon onClick={handleSearchClick} />
                  </SearchWrapSpan>{" "}
                  <SearchBoxWrapper
                    id="searchBoxWrapper"
                    user={user}
                    ref={searchRef}
                  >
                    <SearchBox />
                  </SearchBoxWrapper>
                </>
              )}
              <LoginOrRegister
                loginorreg={"true"}
                onClick={() => {
                  handleRegisterClick("login");
                }}
              >
                Login
              </LoginOrRegister>
              <LoginOrRegister
                loginorreg={"true"}
                onClick={() => {
                  handleRegisterClick("register");
                }}
              >
                Register
              </LoginOrRegister>
            </LoggedContainer>
          )}
        </ButtonsContainer>
      </AllContainer>
      <SidebarWrapper open={isSidebarOpen}>
        <LinksOptions>
          <PageLink
            sidebar="true"
            to="/user/Watchlist"
            onClick={() => {
              handleLinkClick("Watchlist");
            }}
          >
            My Page
          </PageLink>

          <Sidelinks
            onClick={() => {
              handleLinkClick("Watchlist");
            }}
          >
            Watchlist
          </Sidelinks>
          <Sidelinks
            onClick={() => {
              handleLinkClick("Lists");
            }}
          >
            Lists
          </Sidelinks>
          <Sidelinks
            onClick={() => {
              handleLinkClick("Favourites");
            }}
          >
            Favourites
          </Sidelinks>
          <Sidelinks
            onClick={() => {
              handleLinkClick("Watched");
            }}
          >
            Watched
          </Sidelinks>
          <Sidelinks
            onClick={() => {
              handleLinkClick("Account");
            }}
          >
            Account
          </Sidelinks>

          <Button onClick={handleClick}>Logout</Button>
        </LinksOptions>
      </SidebarWrapper>
    </NavbarContainer>
  );
};

export default Navbar;

const NavbarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  z-index: 300;
  position: fixed;

  span {
    height: 34px;
    color: black;
    background: #000;
    cursor: pointer;
    font-size: 24px;
  }

  @media (max-width: 768px) {
    position: relative;
  }
`;

const LogoWrapper = styled.div`
  top: 40px;
  left: 60px;
  position: absolute;
  z-index: 301;

  @media (max-width: 768px) {
    top: 30px;
    left: 30px;
  }
`;

const Logo = styled.div`
  background: url(${logoRed});
  width: 80px;
  height: 80px;
  color: #fff;
  cursor: pointer;
  background-size: cover;

  @media screen and (max-width: 400px) {
    width: 60px;
    height: 60px;
  }
`;

const NavbarLinksWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1170px) {
    width: 85%;
  }
`;

const ButtonsContainer = styled.div`
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;

  span {
    margin: 0 10px;
  }

  @media screen and (max-width: 768px) {
    min-width: 50%;
    max-width: 100%;
    margin: 0;
  }

  @media screen and (max-width: 400px) {
    margin-right: 0;

    span {
      margin: 0 5px;
    }
  }
`;

const LoggedContainer = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;

  @media screen and (max-width: 768px) {
    margin-right: 15px;
  }
`;

const ImageCss = css`
  background-image: url(${(props) => props.image});
  background-size: cover;
`;

const UserImage = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  ${ImageCss}
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  cursor: pointer;
`;

const SidebarWrapper = styled.div`
  width: 150px;
  background: rgb(208, 46, 38);
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  padding: 80px 0 5px 20px;
  right: ${(props) => (props.open ? "0px" : "-200px")};
  z-index: 60;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: 0.3s ease-in;

  ul {
    width: 80%;
    padding-left: 20px;
    margin: 0;
    display: flex;
    flex-direction: column;
    color: #fff;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    left: 0;
    top: ${(props) => (props.open ? "0px" : "-105vh")};
    padding: 0;

    ul {
      padding: 0;
      align-items: center;
      justify-content: center;
    }
  }
`;

const SearchStyleWhenNoUser = css`
  width: 100vw;
  height: 110px;
  right: -10px;
  top: 58px;
  position: absolute;
  background: rgb(208, 46, 38);
  padding: 0;
`;

const SearchBoxWrapper = styled.div`
  width: 200px;
  height: 40px;
  background-color: #000;
  padding: 5px 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  @media screen and (max-width: 768px) {
    ${SearchStyleWhenNoUser}
  }
`;

const SearchIcon = styled(BiSearchAlt2)`
  font-size: 28px;
  color: #fff;
  background: ${({ theme }) => (theme === "true" ? "#fff" : "#000")};
`;

const SearchWrapSpan = styled.span`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const SidebarButtonStyle = css`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;

  @media screen and (max-width: 768px) {
    justify-content: center;
    height: ${({ loginorreg }) => (loginorreg === "true" ? "30px" : "50px")};
    font-size: ${({ loginorreg }) => (loginorreg === "true" ? "20px" : "32px")};
    text-decoration: ${({ loginorreg }) =>
      loginorreg === "true" ? "none" : "underline"};
  }
`;

const Button = styled.div`
  ${SidebarButtonStyle}
  transition:ease-in 0.2;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    font-size: 22px;
    text-decoration: none;
    margin-top: 20px;
  }
`;

const LoginOrRegister = styled.div`
  font-size: 1.2rem;
  color: #fff;
  margin: 0 5px;
  cursor: pointer;
  &:hover {
    color: red;
  }

  @media screen and (max-width: 1300px) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 400px) {
    margin-right: 5px;
  }
`;

const PageLink = styled(NavLink)`
  ${SidebarButtonStyle}
  text-decoration:none;
  margin: ${({ sidebar }) => (sidebar === "true" ? "0" : "0 10px")};
  transition: ease-in 0.2;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    text-transform: uppercase;
    font-size: 22px;
    text-decoration: none;
    margin-top: 20px;
  }
`;

const LinksOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    align-items: center;
  }
`;

const Sidelinks = styled.div`
  text-decoration: none;
  color: white;
  margin: 5px 0;
  cursor: pointer;
  transition: ease-in 0.2s;

  &:hover {
    ${"" /* color: red; */}
    margin-left:10px;
  }

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
`;

const AllContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000;
  margin-bottom: 0;
  z-index: 300;
`;

const RatingWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: ${({ show }) => (show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
`;
