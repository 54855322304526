import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import HeaderBackdrop from "../components/HeaderBackdrop/HeaderBackdropcomponent";
import { UniversalButtonStyles } from "../UniversalStyles";

const TMDB_KEY = process.env.REACT_APP_TMDB_KEY;
const URL = 'https://api.themoviedb.org/3/'
const lang = '&language=en-US&page=1';

const params = new URLSearchParams({
    api_key: TMDB_KEY,

})

const SeasonsEpisodes=()=>{
    const {title,id,numberseason} = useParams();
    const selectors = [];
    const [seasonNumber,setSeasonNumber]=useState(1);
    const [result,setResult]=useState([]);
    const [type,setType]=useState(1)
    const [css,setCss]=useState(1)

    
    for (let i=0;i<numberseason;i++){
      selectors.push(i+1);
    }
    const fetchData= async()=>{
        const searchEpisodes= await fetch (`${URL}tv/${id}/season/${seasonNumber}?${params}${lang}`);
        const resultSearchEpisodes= await searchEpisodes.json();
        setResult(resultSearchEpisodes)
        console.log(resultSearchEpisodes)}

        const selectorHandle = (selector) => {
            setCss(selector)
            setType(selector)
            setSeasonNumber(selector);
        };
        

    useEffect(()=>{
        fetchData()
      }
   ,[seasonNumber])
    
    
    return(

        <>
        <HeaderBackdrop/>
        <SelectorWrap>
          <ShowTitle>{title}</ShowTitle>
          <Season>SEASON</Season>
          <Selector>
              {selectors.map((type, idx) => (
                    <SelectorButton  type={type} css={css} key={idx} onClick={() => selectorHandle(type)}>
                        {type}
                    </SelectorButton>
                ))}
          </Selector>
        </SelectorWrap>
      <MainWrapper>
        {result?.episodes?.map((episode,idx)=>(
            <Wrap key={idx}>
              <Poster bg={episode.still_path}/>
              <InfoWrap>
                   <Title>{episode.episode_number} - {episode.name}</Title>
                   <Overview> {episode.overview} </Overview>
                   <UserScoreContainer>
                   <ScoreVote>
                     <UserScore rating={episode.vote_average}>
                        <h3>{episode?.vote_average.toFixed(1)}</h3>
                     </UserScore>
                     
                     <UserVote>{episode?.vote_count}<br/> votes</UserVote></ScoreVote>
                     {episode?.runtime && <Det><p>Runtime:  </p><Runtime> {episode.runtime} mins</Runtime></Det>}
                     {episode?.runtime && <Det><p>Air Date:  </p><Runtime> {episode.air_date?.split("-").reverse().join("-")} </Runtime></Det>}
                   </UserScoreContainer>
              </InfoWrap>

            </Wrap>
        )

        )}
     </MainWrapper>
     </>


    )


}

export default SeasonsEpisodes

const ShowTitle=styled.div`
    text-transform:uppercase;
    font-weight:bold;
    font-size:30px;
    margin-bottom:15px;

    @media screen and (max-width: 768px){
     font-size:20px;
     text-align:center;
     }
`

const Season=styled.div`
  width: 100px;
  height:80%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  font-size:18px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: solid 2px black;
  margin-bottom:10px;
`

const SelectorWrap=styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  margin-left:20px;

  @media screen and (max-width: 768px){
    height:auto;
    align-items:center;
     }
`

const Selector = styled.div`
  width: 100%;
  ${'' /* height:40px; */}
  /* border: 1px solid red; */
  display: flex;
  margin:0 10px;
  flex-wrap:wrap;

  @media screen and (max-width: 768px){
       flex-wrap:wrap;
     }
`;

const SelectorButton = styled.button`
  
   background:${({ type, css }) => type === css ? 'black' : 'white'};
   color:${({ type, css }) => type === css ? 'powderblue' : 'black'};
  ${UniversalButtonStyles};
   width:150px;

 @media screen and (max-width: 768px){
       width:50px;
     }
`;

const MainWrapper=styled.div`
  min-height:200px;
`

const Wrap=styled.div`
  width:98%;
  background:black;
  display:flex;
  margin:10px;
  border-radius:8px;

  @media screen and (max-width: 768px){
    width:97%;
       flex-direction:column;
     }
`

const Poster=styled.div`
    min-width:300px;
    height:180px;
    border:1px solid white;
    border-radius:7px;
    background:url(https://www.themoviedb.org/t/p/original${props => props.bg});
    background-size:cover;
    margin:20px;

    @media screen and (max-width: 768px){
        min-width:180px;
        height:120px;
     }
`

const InfoWrap=styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:flex-start;;
    margin:10px;
`

const Title=styled.div`
    color:white;
    text-transform:uppercase;
    font-weight:bold;
    font-size:20px;
    margin-bottom:15px;
`

const Overview=styled.div`
    color:white;
    font-size:16px;
`

const UserScoreContainer = styled.div`
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  margin:20px 0;

@media screen and (max-width: 1025px){
   justify-content:space-between;
   align-items:space-between;
  }
`
const ScoreVote=styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
`


const UserScore = styled.div`
  height:40px;
  width:40px;
  border:3px solid ${props => props.rating >= 6 ? 'green' : 'red'};
  border-radius:50%;
  display:flex;
  align-items:center;
  justify-content:center;

  h3{
      color:#fff;
      font-size:20px;
  }

  @media screen and (max-width: 1025px){
      height:40px;
      width:40px;

      h3{
          font-size:12px;
      }
    }
`;

const UserVote=styled.div`
    color:#fff;
    font-size:14px;
    margin-left:10px;
`

const Det = styled.div`
    display:flex;
    flex-direction:row;
    margin: 0 15px;
    p{
        color:red;
        margin:5px 0;
    }
    
@media screen and (max-width: 769px){
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-left:30px;
}
`

const Runtime = styled.div`
    display:flex;
    justify-content: center;
    align-items:center;
    margin-left:5px;
    color:white;

    @media screen and (max-width: 769px){
  
    margin-left:0;
}
`