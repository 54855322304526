import { createContext, useReducer, useCallback, useEffect } from "react";
import tmdbReducer from "./TmdbReducer";
const TmdbContext = createContext();
const TMDB_KEY = process.env.REACT_APP_TMDB_KEY;
const URL = "https://api.themoviedb.org/3/";
const lang = "&language=en-US&page=1";

export const TmdbProvider = ({ children }) => {
  const initialState = {
    movies: [],
    series: [],
    details: [],
    rekon: [],
    detailsLoading: false,
    videosLoading: false,
    providerLoading: false,
    loading: false,
    movieAndTvID: "",
    videoKey: null,
    releaseDate: "2022",
    credits: [],
    creditsSeries: [],
    dirID: [],
    social: [],
    searchMovies: [],
    searchTV: [],
    searchPeople: [],
    searchLoading: false,
    cast: [],
    crew: [],
    crewDetails: [],
    actorDetails: [],
    photos: [],
    actorLoading: false,
    actorTvCredits: [],
    actorMovieCredits: [],
    socialActor: [],
    director: [],
    creditsLoading: false,
    latestMovies: [],
    reviews: [],
    similar: [],
    videos: [],
    provider: [],
    videoType: "Trailer",
    comingSoonData: [],
    networkId: null,
    favouritePeopleDetails: [],
  };
  const [state, dispatch] = useReducer(tmdbReducer, initialState);

  // Set Loading

  const setLoadingState = (loadingType) => {
    return () => {
      dispatch({ type: loadingType });
    };
  };

  const setLoading = setLoadingState("SET_LOADING");
  const setDetailsLoading = setLoadingState("SET_DETAILS_LOADING");
  const setSearchLoading = setLoadingState("SET_SEARCH_LOADING");
  const setActorDetailsLoading = setLoadingState("SET_ACTOR_LOADING");
  const setCreditsLoading = setLoadingState("CREDITS_LOADING");
  const setVideosLoading = setLoadingState("VIDEOS_LOADING");
  const setProviderLoading = setLoadingState("SET_PROVIDER_LOADING");

  const params = new URLSearchParams({
    api_key: TMDB_KEY,
  });
  //Search
  const fetchData = async (url) => {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  };

  const searchMoviesFunc = async (value, page) => {
    const movies = await fetchData(
      `${URL}search/movie?${params}&query=${value}&include_adult=false&language=en-US&page=${page}`
    );

    dispatch({
      type: "SEARCH_MOVIES",
      payload: movies.results,
    });

    return movies;
  };

  const searchShowsFunc = async (value, page) => {
    const shows = await fetchData(
      `${URL}search/tv?${params}&query=${value}&include_adult=false&language=en-US&page=${page}`
    );

    dispatch({
      type: "SEARCH_SHOWS",
      payload: shows.results,
    });

    return shows;
  };

  const getNetworkIds = (value) => {
    const networks = {
      netflix: { networkId: 213, networkMovieId: 8 },
      prime: { networkId: 1024, networkMovieId: 9 },
      apple: { networkId: 2552, networkMovieId: 350 },
      nowTv: { networkId: 591, networkMovieId: 591 },
      // this is now tv cinema
      disney: { networkId: 2739, networkMovieId: 337 },
      // Default values if network is not found
      default: { networkId: 213, networkMovieId: 8 },
    };

    dispatch({ type: "NETWORK_ID", payload: networks[value] });
    return networks[value];
  };

  const fetchComingSoon = async (networkId) => {
    const currentDate = new Date(); // Today's date
    currentDate.setDate(currentDate.getDate() + 1);
    const formattedDate = currentDate.toISOString().split("T")[0];
    const url = `${URL}discover/tv?${params}&with_networks=${networkId.networkId}&first_air_date.gte=${formattedDate}&sort_by=first_air_date.asc&page=1`;
    return await fetchData(url);
  };

  const fetchComingSoonMovies = async (networkIdMovies) => {
    const currentDate = new Date(); // Today's date
    currentDate.setDate(currentDate.getDate() + 1);
    const formattedDate = currentDate.toISOString().split("T")[0];
    const url = `${URL}discover/movie?${params}&include_adult=false&include_video=false&language=en-US&page=1&primary_release_date.gte=${formattedDate}&sort_by=primary_release_date.desc&watch_region=GB&with_watch_providers=${networkIdMovies.networkMovieId}`;
    return await fetchData(url);
  };

  const fetchStreaming = async (networkId, page) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0]; // Simplified date formatting
    const url = `${URL}discover/tv?${params}&first_air_date.lte=${formattedDate}&include_null_first_air_dates=false&with_networks=${networkId.networkId}&sort_by=first_air_date.desc&page=${page}`;
    return await fetchData(url);
  };

  const fetchStreamingMovies = async (networkIdMovies, page) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0]; // Keeps the date formatting
    const url = `${URL}discover/movie?${params}&include_adult=false&include_video=false&language=en-US&page=${page}&primary_release_date.lte=${formattedDate}&sort_by=release_date.desc&watch_region=GB&with_watch_providers=${networkIdMovies.networkMovieId}`;
    return await fetchData(url);
  };

  const searchStreamers = async (value, page) => {
    const networks = {
      netflix: { networkId: 213, networkMovieId: 8 },
      prime: { networkId: 1024, networkMovieId: 9 },
      apple: { networkId: 2552, networkMovieId: 350 },
      nowTv: { networkId: 39, networkMovieId: 39 },
      disney: { networkId: 2739, networkMovieId: 337 },
    };

    let networkId = networks[value].networkId;
    let networkIdMovies = networks[value].networkMovieId;

    const currentYear = new Date().getFullYear();
    const fetchData = async (url) => {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    };

    const comingSoon = await fetchData(
      `${URL}discover/tv?${params}&with_networks=${networkId}&first_air_date_year=${currentYear}&sort_by=primary_release_date.desc&page=1`
    );

    const comingSoonMovies = await fetchData(
      `${URL}discover/movie?${params}&include_adult=false&include_video=false&language=en-US&page=1&primary_release_year=${currentYear}&sort_by=primary_release_date.desc&watch_region=GB&with_watch_providers=${networkIdMovies}`
    );

    const currentDate = new Date();
    var twelveMonthsAgo = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 12,
      currentDate.getDate()
    );

    // Format the date for display in YYYY-MM-DD format
    var formattedDate =
      twelveMonthsAgo.getFullYear() +
      "-" +
      (twelveMonthsAgo.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      twelveMonthsAgo.getDate().toString().padStart(2, "0");

    const comingSoonData = comingSoon.results.filter(
      (data) => new Date(data.first_air_date) > currentDate
    );

    const comingSoonMoviesData = comingSoonMovies.results.filter(
      (data) => new Date(data.release_date) > currentDate
    );

    const streaming = await fetchData(
      `${URL}discover/tv?${params}&first_air_date.gte=${formattedDate}&with_networks=${networkId}&sort_by=popularity.desc&page=${page}`
    );

    const streamingMovies = await fetchData(
      `${URL}discover/movie?${params}&include_adult=false&include_video=false&language=en-US&page=${page}&primary_release_date.gte=${formattedDate}&sort_by=primary_release_date.desc&watch_region=GB&with_watch_providers=${networkIdMovies}`
    );

    dispatch({
      type: "SEARCH_STREAMERS",
      payload: comingSoonData,
      comingSoonMoviesData,
    });
    return [streaming, comingSoonData, comingSoonMoviesData, streamingMovies];
  };

  const searchPeopleFunc = async (value, page) => {
    const people = await fetchData(
      `${URL}search/person?${params}&language=en-US&query=${value}&page=${page}`
    );

    dispatch({
      type: "SEARCH_PEOPLE",
      payload: people.results,
    });

    return people;
  };

  const getSearch = async (value, page) => {
    if (value.length > 0) {
      setSearchLoading(true);

      const movies = await searchMoviesFunc(value, page);
      const shows = await searchShowsFunc(value, page);
      const people = await searchPeopleFunc(value, page);

      setSearchLoading(false);

      return {
        movies,
        shows,
        people,
      };
    }
  };

  // get videos of movies and tv
  const getVideos = useCallback(
    async (id, type) => {
      const getfunc = async (id, type) => {
        setVideosLoading();
        const url = `${URL}${type}/${id}/videos?${params}${lang}`;
        const data = await fetchData(url);

        let videoKey = null;
        const trailer = data.results.find(
          (element) => element.type === "Trailer"
        );
        const teaser = data.results.find(
          (element) => element.type === "Teaser"
        );
        if (trailer) {
          videoKey = trailer.key;
        } else if (teaser) {
          videoKey = teaser.key;
        }

        dispatch({
          type: "MOVIE_VIDEOS",
          payload: data.results,
          videoKey,
        });
      };

      if (type === "movie") {
        await getfunc(id, "movie");
      } else {
        await getfunc(id, "tv");
      }
    },
    [setVideosLoading]
  );

  //get provider for a movie or shows

  const getProvider = async (id, channel) => {
    setProviderLoading();
    const providerResponse = await fetch(
      `${URL}${channel}/${id}/watch/providers?${params}${lang}`
    );
    const providerData = await providerResponse.json();

    dispatch({
      type: "PROVIDER",
      payload: providerData.results,
    });
  };

  // get reviews for a show or a movie

  const getReviews = async (id, type) => {
    setLoading();
    const reviewsResponse = await fetch(
      `${URL}${type}/${id}/reviews?${params}${lang}`
    );
    const reviewsData = await reviewsResponse.json();

    dispatch({
      type: "GET_REVIEWS",
      payload: reviewsData.results,
    });
  };

  // get movie and tv credits of an actor
  const getActorCredits = async (id) => {
    setCreditsLoading();
    const combinedCredits = await fetch(
      `${URL}person/${id}/combined_credits?${params}${lang}`
    );
    const combinedCreditsData = await combinedCredits.json();
    const movies = combinedCreditsData.cast.filter(
      (item) => item.media_type === "movie"
    );
    const shows = combinedCreditsData.cast.filter(
      (item) => item.media_type === "tv"
    );
    const crew = combinedCreditsData.crew.filter(
      (item) => item.job !== "Director"
    );
    const direct = combinedCreditsData.crew.filter(
      (element) => element.job === "Director"
    );
    const socialFetch = await fetch(
      `${URL}person/${id}/external_ids?${params}${lang}`
    );
    const socialActor = await socialFetch.json();

    dispatch({
      type: "ACTOR_CREDITS",
      movieCredits: movies,
      tvCredits: shows,
      crewDetails: crew,
      director: direct,
      socialActor: socialActor,
    });
  };

  // get the details of the actor
  const getActorDetails = async (id) => {
    setActorDetailsLoading();
    const actorFetch = await fetch(`${URL}person/${id}?${params}${lang}`);
    const actorDetails = await actorFetch.json();
    const photosFetch = await fetch(
      `${URL}person/${id}/images?${params}${lang}`
    );
    const photosResult = await photosFetch.json();
    const photos = photosResult.profiles;

    dispatch({
      type: "GET_ACTOR_DETAILS",
      payload: actorDetails,
      photos: photos,
    });
    return actorDetails;
  };

  // get the details of the actor
  const getFavouritePeopleDetails = async (ids) => {
    const fetchPromises = ids.map((id) =>
      fetch(`${URL}person/${id}?${params}${lang}`).then((response) =>
        response.json()
      )
    );
    try {
      const actorsDetails = await Promise.all(fetchPromises);
      dispatch({
        type: "GET_FAVOURITE_PEOPLES_DETAILS",
        payload: actorsDetails,
      });

      return actorsDetails;
    } catch (error) {
      console.error("Failed to fetch favourite people details:", error);
    }
  };

  const updateFavouritePeopleDetails = async (id, actionType) => {
    if (actionType === "add") {
      // Fetch details for the new actor
      try {
        const response = await fetch(`${URL}person/${id}?${params}${lang}`);
        const newActorDetails = await response.json();

        // Dispatch an action to add this actor's details to the state
        dispatch({
          type: "ADD_FAVOURITE_PERSON_DETAILS",
          payload: newActorDetails,
        });
      } catch (error) {
        console.error("Failed to fetch new favourite person details:", error);
      }
    } else if (actionType === "remove") {
      // Assuming you have access to the current state or can get the favourite actors' list
      const currentFavouritePeople = state.favouritePeopleDetails; // This is an illustrative example; adjust according to your state management
      console.log(currentFavouritePeople);
      const actorToRemove = currentFavouritePeople.find(
        (actor) => actor.id === id
      );
      const newActorDetails = state.favouritePeopleDetails.filter(
        (person) => person.id !== actorToRemove
      );
      if (actorToRemove) {
        // Dispatch an action to remove the actor's details from the state
        dispatch({
          type: "REMOVE_FAVOURITE_PERSON_DETAILS",
          payload: newActorDetails,
        });
      }
    }
  };

  const setVideo = (videoT) => {
    const videoType = videoT;

    dispatch({
      type: "SET_VIDEO_TYPE",
      videoType: videoType,
    });
  };
  // get card Short details

  async function getShortCardDetails(listOfId) {
    const movies = [];
    const shows = [];
    const chunkSize = 50;
    // Helper function to delay execution
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // Function to fetch show details in batches
    async function fetchShowsBatch(startIndex) {
      const endIndex = Math.min(startIndex + chunkSize, listOfId.shows.length);
      const currentBatch = listOfId.shows.slice(startIndex, endIndex);

      try {
        const batchPromises = currentBatch.map(async (showId) => {
          const response = await fetch(`${URL}tv/${showId}?${params}${lang}`);
          if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
          const showResponse = await response.json();
          shows.push(showResponse);
        });

        await Promise.all(batchPromises);

        if (endIndex < listOfId.shows.length) {
          await delay(1000); // Wait for 1 second before fetching the next batch
          await fetchShowsBatch(endIndex);
        }
      } catch (error) {
        console.error("Failed to fetch show details:", error);
        // Handle the error appropriately, e.g., retry or skip
      }
    }

    // Fetch shows in batches
    await fetchShowsBatch(0);

    // Fetch movie details in parallel
    try {
      await Promise.all(
        listOfId.movies.map(async (movieId) => {
          const response = await fetch(
            `${URL}movie/${movieId}?${params}${lang}`
          );
          if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
          const movieResponse = await response.json();
          movies.push(movieResponse);
        })
      );
    } catch (error) {
      console.error("Failed to fetch movie details:", error);
      // Handle the error appropriately
    }

    return {
      movies: movies,
      shows: shows,
    };
  }

  // get the details of a show, movie or actor

  async function getDetails(id, channel) {
    if (state.details.id === id) {
      return [state.details, state.similar];
    }

    // setDetailsLoading();

    getReviews(id, channel);
    getProvider(id, channel);

    const [
      detailsResponse,
      creditsResponse,
      similarResponse,
      socialResponse,
      recommendations,
    ] = await Promise.all([
      fetch(`${URL}${channel}/${id}?${params}${lang}`),
      fetch(`${URL}${channel}/${id}/credits?${params}${lang}`),
      fetch(`${URL}${channel}/${id}/similar?${params}${lang}`),
      fetch(`${URL}${channel}/${id}/external_ids?${params}${lang}`),
      fetch(`${URL}${channel}/${id}/recommendations?${params}${lang}`),
    ]);

    const { first_air_date, release_date } = detailsResponse;
    const details = await detailsResponse.json();
    const credits = await creditsResponse.json();
    const similar = await similarResponse.json();
    const social = await socialResponse.json();
    const rekon = await recommendations.json();
    const airDate = first_air_date ? first_air_date.slice(0, 4) : "";
    const releaseDate = release_date ? release_date.slice(0, 4) : "";
    const dir =
      credits.crew?.find((element) => element.job === "Director")?.name ??
      credits.crew?.find((element) => element.job === "Executive Producer")
        ?.name;
    const dirSeries = credits.crew?.filter(
      (element) => element.job === "Executive Producer"
    );
    const dirID =
      credits.crew?.find((element) => element.job === "Director")?.id ??
      credits.crew?.find((element) => element.job === "Executive Producer")?.id;

    dispatch({
      type: "GET_DETAILS",
      payload: details,
      similar: similar.results,
      rekon: rekon.results,
      social: social,
      id,
      releaseDate: channel === "tv" ? airDate : releaseDate,
      credits: dir,
      creditsSeries: dirSeries,
      dirID: dirID,
      cast: credits.cast,
      crew: credits.crew,
    });

    return [details];
  }

  return (
    <TmdbContext.Provider
      value={{
        getVideos,
        getSearch,
        searchMoviesFunc,
        searchShowsFunc,
        searchPeopleFunc,
        getDetails,
        getActorDetails,
        getActorCredits,
        getProvider,
        getReviews,
        setVideo,
        searchStreamers,
        getShortCardDetails,
        fetchComingSoon,
        fetchComingSoonMovies,
        getNetworkIds,
        fetchStreaming,
        fetchStreamingMovies,
        getFavouritePeopleDetails,
        updateFavouritePeopleDetails,
        videos: state.videos,
        videosLoading: state.videosLoading,
        creditsLoading: state.creditsLoading,
        actorLoading: state.actorLoading,
        providerLoading: state.providerLoading,
        searchMovies: state.searchMovies,
        cast: state.cast,
        crew: state.crew,
        crewDetails: state.crewDetails,
        searchPeople: state.searchPeople,
        searchTV: state.searchTV,
        movies: state.movies,
        loading: state.loading,
        searchLoading: state.searchLoading,
        detailsLoading: state.detailsLoading,
        series: state.series,
        provider: state.provider,
        details: state.details,
        reviews: state.reviews,
        similar: state.similar,
        rekon: state.rekon,
        social: state.social,
        socialActor: state.socialActor,
        mandtid: state.movieAndTvID,
        rDate: state.releaseDate,
        credits: state.credits,
        creditsSeries: state.creditsSeries,
        dirID: state.dirID,
        bestLast20: state.bestLast20,
        best: state.best,
        actorDetails: state.actorDetails,
        photos: state.photos,
        actorTvCredits: state.actorTvCredits,
        actorMovieCredits: state.actorMovieCredits,
        director: state.director,
        videoKey: state.videoKey,
        upcoming: state.upcoming,
        videoType: state.videoType,
        comingSoonData: state.comingSoonData,
        networkId: state.networkId,
        favouritePeopleDetails: state.favouritePeopleDetails,
      }}
    >
      {children}
    </TmdbContext.Provider>
  );
};

export default TmdbContext;
