import React, { useState, useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import FirstLoaderContext from "../../context/FirstLoaders/FirstLoaders";
import TrendingCard from "../CardWrapper/TrendingCard";
import {
  MdOutlineArrowBackIosNew as BackArrow,
  MdOutlineArrowForwardIos as ForwardArrow,
} from "react-icons/md";
import NewCardModal from "../CardModal/NewCardModal";

const TrendingComponent = () => {
  const { trendingPeople } = useContext(FirstLoaderContext);
  const [currentTrendingIndex, setCurrentTrendingIndex] = useState(0);

  const navigate = useNavigate();

  const [createOrLogin, setCreateOrLogin] = useState();

  const handleModalOpen = () => {
    setCreateOrLogin(true);
  };
  const handleModalClose = () => {
    setCreateOrLogin(false);
  };

  const handleLeftArrowClick = () => {
    setCurrentTrendingIndex(
      (currentTrendingIndex - 1 + trendingPeople.length) % trendingPeople.length
    );
  };

  const handleRightArrowClick = () => {
    setCurrentTrendingIndex((currentTrendingIndex + 1) % trendingPeople.length);
  };

  const navigateToActorDetails = (person) => {
    navigate(`/actordetails/${person.id}`);
  };

  // Cycle through the trendingPeople array every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTrendingIndex(
        (currentTrendingIndex + 1) % trendingPeople.length
      );
    }, 10000);
    return () => clearInterval(interval);
  }, [currentTrendingIndex, trendingPeople]);

  if (!trendingPeople) {
    return null;
  }

  return (
    <Main id="trPeople">
      <NewCardModal
        handleModalClose={handleModalClose}
        createOrLogin={createOrLogin}
      />
      <TitleWrapper>
        <Square />
        <CategoryWrapper>People Trending Today</CategoryWrapper>
      </TitleWrapper>
      <TrendingWrapper>
        <LeftArrow onClick={handleLeftArrowClick} />

        <KnownForTitle>Known For</KnownForTitle>
        <KnownForWrapper>
          {trendingPeople[currentTrendingIndex]?.known_for.map((movie, idx) => (
            <TrendingCard
              modalHandle={handleModalOpen}
              key={idx}
              data={movie}
              type={movie.media_type}
              theme={"dark"}
            />
          ))}
        </KnownForWrapper>

        <BackdropImageWrapper
          onClick={() => {
            navigateToActorDetails(trendingPeople[currentTrendingIndex]);
          }}
        >
          <h3>{trendingPeople[currentTrendingIndex]?.name}</h3>
          <BackdropImage
            src={`https://www.themoviedb.org/t/p/original${trendingPeople[currentTrendingIndex]?.profile_path}`}
          />
        </BackdropImageWrapper>
        <TrendingList>
          {[...Array(3)].map((_, index) => {
            const nextIndex =
              (currentTrendingIndex + index + 1) % trendingPeople?.length;
            const person = trendingPeople[nextIndex];
            return (
              <TrendingPerson
                key={index}
                onClick={() => {
                  navigateToActorDetails(person);
                }}
              >
                <TrendingImage
                  src={`https://www.themoviedb.org/t/p/original${person?.profile_path}`}
                />
                <TrendingNameWrapper>
                  {index === 0 && <p>Next up:</p>}
                  <TrendingName>{person?.name}</TrendingName>
                </TrendingNameWrapper>
              </TrendingPerson>
            );
          })}
        </TrendingList>
        <RightArrow onClick={handleRightArrowClick} />
      </TrendingWrapper>
    </Main>
  );
};

export default TrendingComponent;

const Main = styled.div`
  width: 100%;
  height: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CategoryWrapper = styled.div`
  ${"" /* margin: 1.5rem 0 0; */}

  color: #000;
  font-weight: bold;
  font-size: 1.25rem;
  ${"" /* position: relative; */}
  ${"" /* padding-left: 0.3125rem; */}

  ${
    "" /* ::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 80%;
    width: 0.8rem;
    background-color: red;
  } */
  }
`;

const Square = styled.div`
  width: 15px;
  height: 15px;
  margin: 0 10px;
  border-radius: 4px;
  background: rgb(255, 0, 0);
`;

const TrendingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 30rem;
  margin: 0.3125rem 0 3rem;
  border-radius: 0.4375rem;
  position: relative;
  background-color: #000;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    height: 40rem;
  }
`;

const KnownForTitle = styled.h3`
  height: 100%;
  font-size: 1.125rem;
  writing-mode: vertical-rl;
  text-orientation: upright;
  line-height: 0;
  padding-top: 15px;
  color: #fff;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const KnownForWrapper = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  padding: 1rem 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    width: 40%;
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    height: 30%;
    width: 90%;
    padding: 0;
  }
`;

const BackdropImageWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  h3 {
    ${"" /* display: flex; */}
    font-weight: bold;
    height: 90%;
    text-align: center;
    font-size: 24px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    line-height: 0px;
    color: #fff;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    word-break: break-all; /* Add this line */
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    background-color: #000;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  &:hover:before {
    opacity: 0.8;
  }

  @media screen and (max-width: 768px) {
    height: 60%;
    width: 100%;
    h3 {
      font-size: 18px;
    }
  }
`;

const BackdropImage = styled.img`
  width: 70%;
  height: 90%;
  border: 1px solid #000;
  border-radius: 7px;
  object-fit: cover;
  position: relative;
  z-index: 2;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 60%;
  }
`;

const TrendingList = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  justify-content: space-between;
  padding: 2%;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const TrendingPerson = styled.div`
  display: flex;
  height: 30%;
  border: 1px solid #fff;
  border-radius: 7px;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
`;

const TrendingImage = styled.img`
  width: 30%;
  height: 100%;
  margin-right: 5%;
`;

const TrendingNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    color: red;
    margin-bottom: 5px;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const TrendingName = styled.h4`
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
`;

const ArrowStyle = css`
  margin-left: 3%;
  position: absolute;
  color: #fff;
  font-size: 3vw;
  z-index: 500;
  top: 50%;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    top: 30%;
    font-size: 10vw;
    margin-left: 0;
  }
`;

const RightArrow = styled(ForwardArrow)`
  ${ArrowStyle};
  right: 27%;
  @media screen and (max-width: 1024px) {
    right: 10px;
  }
  @media screen and (max-width: 768px) {
    right: 5px;
  }
`;

const LeftArrow = styled(BackArrow)`
  ${ArrowStyle};
  left: 29%;
  @media screen and (max-width: 1024px) {
    left: 50%;
  }

  @media screen and (max-width: 768px) {
    left: 5px;
  }
`;
