import React, { useContext, useRef, useState } from "react";
import { BsLink as Link } from "react-icons/bs";
import { AiOutlineShareAlt as ShareIcon } from "react-icons/ai";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share";
import styled, { keyframes } from "styled-components";
import SizeContext from "../../context/SizeContext";

const ShareComponent = ({ url, title }) => {
  const appId = "904548273968601";
  const { isSmall } = useContext(SizeContext);
  const [share, setShare] = useState(false);
  const [isLinkClicked, setIsLinkClicked] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url);
    setIsLinkClicked(true);
    setTimeout(() => {
      setIsLinkClicked(false);
    }, 1000);
  };

  return (
    <Share>
      <StyledShareIcon onClick={() => setShare(!share)} />
      {share && (
        <ShareWrapper>
          <Title>
            Share
            <br />
            on
          </Title>

          <FacebookShareButton url={url} quote={title}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <WhatsappShareButton url={url} title={title}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <TwitterShareButton url={url} title={title}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          {!isSmall && (
            <FacebookMessengerShareButton
              url={url}
              title={title}
              appId={appId}
              quote="Check out this link"
            >
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
          )}
          <EmailShareButton url={url} quote={title}>
            <EmailIcon size={32} round />
          </EmailShareButton>
          <LinkWrap isClicked={isLinkClicked} onClick={handleCopyLink}>
            <StyledLink />
          </LinkWrap>
        </ShareWrapper>
      )}
    </Share>
  );
};

export default ShareComponent;

const StyledShareIcon = styled(ShareIcon)`
  font-size: 30px;
  color: #fff;
  border-radius: 50%;
  border: 1px solid white;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 200;
  background: black;
`;

const linkAnimation = keyframes`
  0% {
    background-color: gray;
  }
  50% {
    background-color: lightblue;
  }
  100% {
    background-color: gray;
  }
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  color: #fff;
  margin: 5px;
  cursor: pointer;
`;

const Share = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: 100px 0;
  top: 85px;
  right: 65px;
  z-index: 200;

  @media screen and (max-width: 48rem) {
    top: 50px;
    right: 15px;
    margin-top: 20px;
  }
`;

const ShareWrapper = styled.div`
  width: auto;
  position: absolute;
  top: 1px;
  right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  border-radius: 7px;
  background: black;

  @media screen and (max-width: 48rem) {
    width: 250px;
    flex-direction: row;
    right: 70px;
    position: relative;
    padding: 5px 10px;
  }
`;

const Title = styled.div`
  align-self: center;
  font-size: 14px;
  color: white;
  text-align: center;
  padding: 4px;
`;

const LinkWrap = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  animation: ${({ isClicked }) => (isClicked ? linkAnimation : "none")} 1s
    linear;
`;
