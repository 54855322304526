import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { MdFormatListBulletedAdd } from "react-icons/md";
import UserContext from "../../context/User/UserContext";

const NewListComponent = ({ listNames }) => {
  const { updateList } = useContext(UserContext);
  const [newList, setNewList] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [inputError, setInputError] = useState("");

  const handleInputChange = (e) => {
    const newName = e.target.value;
    setNewList(newName);
    if (newName.length > 0 && newName.length < 3) {
      setInputError("List name must be at least 3 characters long.");
    } else if (
      Object.keys(listNames).some(
        (key) => key.toLowerCase() === newName.toLowerCase()
      )
    ) {
      setInputError(`A list named "${newName}" already exists.`);
    } else {
      setInputError("");
    }
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal]);

  const confirmNewList = (confirm) => {
    if (confirm && !inputError) {
      updateList(
        newList.charAt(0).toUpperCase() + newList.slice(1).toLowerCase()
      );
      setNewList("");
    }
    setShowModal(false);
  };

  return (
    <NewListWrapper>
      <AddButton onClick={() => setShowModal(true)}>
        <MdFormatListBulletedAdd />
        <span>Add New List</span>
      </AddButton>
      {showModal && (
        <Modal>
          <ModalContent>
            <h4>Create a new list:</h4>
            <input
              placeholder="Type new list name"
              value={newList}
              onChange={handleInputChange}
            />
            {inputError && <ErrorMessage>{inputError}</ErrorMessage>}
            <ButtonGroup>
              <Button
                onClick={() => confirmNewList(true)}
                disabled={!newList || newList.length < 3 || !!inputError}
                type="confirm"
              >
                Confirm
              </Button>
              <Button
                onClick={() => {
                  confirmNewList(false);
                  setNewList("");
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ModalContent>
        </Modal>
      )}
    </NewListWrapper>
  );
};

export default NewListComponent;

const NewListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #1a1a1a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: blue;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
`;

const ModalContent = styled.div`
  padding: 20px;
  background-color: #2d2d2d;
  border-radius: 8px;
  text-align: center;
  color: white;
  width: 90%;
  max-width: 400px;
`;

const ErrorMessage = styled.div`
  color: #f65261;
  margin-top: 10px;
`;

const ButtonGroup = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color:${({ type }) => (type === "confirm" ? "green" : "#f65261")} ;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color:${({ type }) =>
      type === "confirm" ? "darkgreen" : "#ff7675"} ;
  }
  &:disabled {
    background-color: #777;
    cursor: not-allowed;
  }

  @media screen and (max-width: 768px) {
    input {
      font-size: 16px;
    }
`;
