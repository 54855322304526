import React, { useContext, useEffect, useState } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import styled, { css } from "styled-components";
import NewCardModal from "../CardModal/NewCardModal";
import AuthContext from "../../context/User/AuthContext";
import UserContext from "../../context/User/UserContext";
const ToFavouriteButton = ({ location, id, type, allDetails, inCard }) => {
  const { removeDetailsFromState, addDetailsToState, user } =
    useContext(AuthContext);
  const [favClicked, setFavClicked] = useState(false);
  const [createOrLogin, setCreateOrLogin] = useState();
  const {
    updateFavouriteActors,
    updateFavourites,
    removeDataFromFavourites,
    favourites,
    favouriteActor,
  } = useContext(UserContext);

  const handleModalClose = () => {
    setCreateOrLogin(false);
  };

  const checkIfInFavourites = () => {
    const isFavourite =
      location === "person"
        ? favouriteActor.actorId.includes(Number(id))
        : type === "movie"
        ? favourites.movies.includes(id)
        : favourites.shows.includes(id);

    setFavClicked(isFavourite);
  };

  useEffect(() => {
    if (favourites && favouriteActor) {
      checkIfInFavourites();
    }
  }, [id, favourites, favouriteActor]);

  const handleFavClicked = () => {
    setFavClicked(!favClicked);
  };

  const handleFavouriteAdding = (event) => {
    event.stopPropagation();
    if (!user) {
      setCreateOrLogin(true);
    } else {
      if (location === "person") {
        updateFavouriteActors(Number(id));
      } else {
        if (favClicked) {
          removeDataFromFavourites(id, type);
          removeDetailsFromState("favourites", type, id);
          handleFavClicked();
        } else {
          updateFavourites(id, type);
          addDetailsToState("favourites", type, allDetails);
          handleFavClicked();
        }
      }
    }
  };

  return (
    <>
      <NewCardModal
        handleModalClose={handleModalClose}
        createOrLogin={createOrLogin}
      />
      <FavWrapper>
        <FavButton
          onClick={(e) => {
            handleFavouriteAdding(e);
          }}
          inFav={favClicked.toString()}
          location={location}
          inCard={inCard}
        >
          {favClicked ? (
            <WhiteHearthIcon />
          ) : location === "details" ? (
            <WhiteHearthIcon />
          ) : (
            <BlackHearthIcon />
          )}
        </FavButton>
      </FavWrapper>
    </>
  );
};

export default ToFavouriteButton;

const WhiteHearthIcon = styled(AiOutlineHeart)`
  font-size: 15px;
  color: #fff;
`;
const BlackHearthIcon = styled(AiOutlineHeart)`
  font-size: 15px;
  color: #000;
`;
const FavWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${
    "" /* width: 40px;
  height: 40px; */
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;
const detailsStyle = css`
  background-color: ${({ inFav }) => (inFav === "true" ? "red" : "#000")};
  border: 1px solid #fff;
  width: 35px;
  height: 35px;
`;

const normalStyle = css`
  width: 30px;
  height: 30px;
  border: 1px solid #000;
  background-color: ${({ inFav }) => (inFav === "true" ? "red" : "#fff")};
`;

const FavButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  ${({ location }) => (location === "details" ? detailsStyle : normalStyle)}

  @media screen and (max-width: 768px) {
    width: ${({ inCard }) => (inCard === "true" ? "25px" : "30px")};
    height: ${({ inCard }) => (inCard === "true" ? "25px" : "30px")};
  }
`;
