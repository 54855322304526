import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TmdbContext from "../context/TmdbContext";
import UserContext from "../context/User/UserContext";
import AuthContext from "../context/User/AuthContext";
import styled, { css } from "styled-components";
import Photos from "../components/Photos/Photos";
import DetailsWrapper from "../components/CardWrapper/DetailsWrapper";
import ReactShowMoreText from "react-show-more-text";
import LoadingCircle from "../components/Loading/LoadingCircle";
import useLocalStorage from "../hooks/useLocalStorage";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import ShareComponent from "../components/ShareComponent/ShareComponent";
import { AiOutlineHeart } from "react-icons/ai";
import ToFavouriteButton from "../components/FavWishAdd/ToFavouriteButton";

// test

const ActorDetails = () => {
  const {
    actorDetails,
    actorLoading,
    getActorDetails,
    getActorCredits,
    actorMovieCredits,
    actorTvCredits,
    crewDetails,
    director,
    photos,
    creditsLoading,
    socialActor,
  } = useContext(TmdbContext);
  const { ratings } = useContext(UserContext);
  const { user } = useContext(AuthContext);
  const [person, setPerson] = useLocalStorage("person", "");

  const [details, setDetails] = useState("movies");
  const [value, setValue] = useState("");
  const { personid } = useParams();

  const [updatedActorMovieCredits, setUpdatedActorMovieCredits] =
    useState(actorMovieCredits);
  const [updatedActorShowCredits, setUpdatedActorShowCredits] =
    useState(actorTvCredits);
  const [updatedCrewDetails, setUpdatedCrewDetails] = useState(crewDetails);
  const [updatedDirector, setUpdatedDirector] = useState(director);

  const shareUrl = `https://myhubcinema.com/actordetails/${personid}`;

  const settingPersonToLocal = () => {
    setPerson(personid);
  };
  useEffect(() => {
    settingPersonToLocal();
  }, []);
  const handleChange = (value) => {
    if (value === "Newest") {
      updatedActorMovieCredits.sort(
        (a, b) => new Date(b.release_date) - new Date(a.release_date)
      );
      updatedActorShowCredits.sort(
        (a, b) => new Date(b.first_air_date) - new Date(a.first_air_date)
      );
      updatedDirector.sort(
        (a, b) =>
          new Date(b.release_date || b.first_air_date) -
          new Date(a.release_date || a.first_air_date)
      );
      updatedCrewDetails.sort(
        (a, b) =>
          new Date(b.release_date || b.first_air_date) -
          new Date(a.release_date || a.first_air_date)
      );
    } else if (value === "Oldest") {
      updatedActorMovieCredits.sort(
        (a, b) => new Date(a.release_date) - new Date(b.release_date)
      );
      updatedActorShowCredits.sort(
        (a, b) => new Date(a.first_air_date) - new Date(b.first_air_date)
      );
      updatedDirector.sort(
        (a, b) =>
          new Date(a.release_date || a.first_air_date) -
          new Date(b.release_date || b.first_air_date)
      );
      updatedCrewDetails.sort(
        (a, b) =>
          new Date(a.release_date || a.first_air_date) -
          new Date(b.release_date || b.first_air_date)
      );
    } else {
      updatedActorMovieCredits.sort((a, b) => b.vote_average - a.vote_average);
      updatedActorShowCredits.sort((a, b) => b.vote_average - a.vote_average);
      updatedDirector.sort((a, b) => b.vote_average - a.vote_average);
      updatedCrewDetails.sort((a, b) => b.vote_average - a.vote_average);
    }

    return [
      updatedActorMovieCredits,
      updatedActorShowCredits,
      updatedCrewDetails,
    ];
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const startEffect = (person) => {
    return Promise.all([getActorCredits(person), getActorDetails(person)]);
  };

  useEffect(() => {
    startEffect(personid).then(() => {
      if (actorMovieCredits?.length > 0) {
        setDetails("movies");
      } else if (actorTvCredits?.length > 0) {
        setDetails("shows");
      } else if (director != 0) {
        setDetails("director");
      } else if (crewDetails != 0) {
        setDetails("crew");
      }
    });
  }, [personid]);

  // set all the ratings to the movies/shows
  useEffect(() => {
    if (user) {
      const appendRatings = (items, ratings) => {
        return items.map((item) => {
          const isMovie = !!item.release_date;

          const itemRating = ratings[isMovie ? "movies" : "shows"].find(
            (rating) => rating.movieId === item.id
          );
          if (itemRating) {
            return { ...item, rating: itemRating.rating };
          }
          return item;
        });
      };

      const updatedActorMovieCredits = appendRatings(
        actorMovieCredits,
        ratings
      );
      const updatedActorTvCredits = appendRatings(actorTvCredits, ratings);
      const updatedCrewDetails = appendRatings(crewDetails, ratings);
      const updatedDirector = appendRatings(director, ratings);
      setUpdatedActorMovieCredits(updatedActorMovieCredits);
      setUpdatedActorShowCredits(updatedActorTvCredits);
      setUpdatedCrewDetails(updatedCrewDetails);
      setUpdatedDirector(updatedDirector);
    } else {
      setUpdatedActorMovieCredits(actorMovieCredits);
      setUpdatedActorShowCredits(actorTvCredits);
      setUpdatedCrewDetails(crewDetails);
      setUpdatedDirector(director);
    }
  }, [actorMovieCredits, actorTvCredits, crewDetails, director, ratings, user]);

  if (!creditsLoading && !actorLoading) {
    return (
      <>
        <Master>
          <Standard>
            <Wrapper>
              <ShareComponent url={shareUrl} />
              <HeaderDetails>
                <ActorBackground />
                <DetWrapper>
                  <PosterWrap>
                    <Poster bg={actorDetails.profile_path} />
                    <Social>
                      {socialActor.facebook_id != null && (
                        <FaFacebookSquare
                          onClick={() =>
                            openInNewTab(
                              `https://www.facebook.com/${socialActor.facebook_id}`
                            )
                          }
                          size={"35px"}
                          style={{
                            margin: 10,
                            cursor: "pointer",
                            color: "#3b5998",
                            background: "white",
                            borderRadius: 6,
                          }}
                        />
                      )}
                      {socialActor.instagram_id != null && (
                        <FaInstagramSquare
                          onClick={() =>
                            openInNewTab(
                              `https://www.instagram.com/${socialActor.instagram_id}/`
                            )
                          }
                          size={"35px"}
                          style={{
                            margin: 10,
                            cursor: "pointer",
                            background: "white",
                            color: "#d62976",
                            borderRadius: 6,
                          }}
                        />
                      )}
                      {socialActor.twitter_id != null && (
                        <FaTwitterSquare
                          onClick={() =>
                            openInNewTab(
                              `https://www.twitter.com/${socialActor.twitter_id}`
                            )
                          }
                          size={"35px"}
                          style={{
                            margin: 10,
                            cursor: "pointer",
                            color: "#1DA1F2",
                            background: "white",
                            borderRadius: 6,
                          }}
                        />
                      )}
                    </Social>
                  </PosterWrap>
                  <Details>
                    <ToFavouriteButton location={"person"} id={personid} />
                    <Title>{actorDetails.name}</Title>
                    {actorDetails.birthday != null && (
                      <DoB>
                        Date of Birth:{" "}
                        <span>
                          {actorDetails.birthday.split("-").reverse().join("-")}
                        </span>
                      </DoB>
                    )}
                    {actorDetails.deathday != null && (
                      <DoB>
                        Died: <span>{actorDetails.deathday}</span>
                      </DoB>
                    )}
                    <DoB>
                      Place of Birth: <span>{actorDetails.place_of_birth}</span>
                    </DoB>
                    <h3>Bio:</h3>
                    <TextWrapper>
                      <ReactShowMoreText lines={4}>
                        <p>{actorDetails.biography}</p>
                      </ReactShowMoreText>
                    </TextWrapper>
                  </Details>
                </DetWrapper>
                <PhotosWrapper id="photosWrapper">
                  <Photos photos={photos} side="center" />
                </PhotosWrapper>
              </HeaderDetails>
              <ButtonsWrapper>
                {actorTvCredits?.length > 0 && (
                  <Button
                    focus={details === "shows"}
                    onClick={(e) => {
                      setDetails("shows");
                    }}
                  >
                    Shows
                  </Button>
                )}
                {actorMovieCredits?.length > 0 && (
                  <Button
                    focus={details === "movies"}
                    onClick={(e) => {
                      setDetails("movies");
                    }}
                  >
                    Movies
                  </Button>
                )}
                {director?.length > 0 && (
                  <Button
                    focus={details === "director"}
                    onClick={(e) => {
                      setDetails("director");
                    }}
                  >
                    Director
                  </Button>
                )}
                {crewDetails?.length > 0 && (
                  <Button
                    focus={details === "crew"}
                    onClick={(e) => {
                      setDetails("crew");
                    }}
                  >
                    Crew jobs
                  </Button>
                )}
                <SortValues>
                  <label htmlFor="orderby">Order by</label>
                  <select
                    defaultValue={"DEFAULT"}
                    onChange={(e) => {
                      setValue(e.target.value);
                      handleChange(e.target.value);
                    }}
                    name="orderby"
                    id="orderby"
                  >
                    <option value="DEFAULT" disabled>
                      Select
                    </option>
                    <option value="Newest">Newest</option>
                    <option value="Oldest">Oldest</option>
                    <option value="Rating">Best Rating</option>
                  </select>
                </SortValues>
              </ButtonsWrapper>
              <CardWrapperHolder id="cradwrapperHolder">
                {details === "shows" ? (
                  <DetailsWrapper
                    side="left"
                    value={value}
                    name="| Roles "
                    movies={updatedActorShowCredits}
                    page="detailsPage"
                    where="actor"
                  />
                ) : details === "movies" ? (
                  <DetailsWrapper
                    side="center"
                    value={value}
                    name="Roles | "
                    movies={updatedActorMovieCredits}
                    page="detailsPage"
                    where="actor"
                  />
                ) : details === "crew" ? (
                  <DetailsWrapper
                    crew="crew"
                    side="rightright"
                    value={value}
                    name=" Roles  |"
                    movies={updatedCrewDetails}
                    page="detailsPage"
                    where="actor"
                  />
                ) : (
                  <DetailsWrapper
                    crew="director"
                    side="right"
                    value={value}
                    name="Roles | "
                    movies={updatedDirector}
                    page="detailsPage"
                    where="actor"
                  />
                )}
              </CardWrapperHolder>
            </Wrapper>
          </Standard>
        </Master>
      </>
    );
  } else {
    return <LoadingCircle />;
  }
};

export default ActorDetails;

const Master = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Standard = styled.div`
  width: 1180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 60px;

  @media screen and (max-width: 1180px) {
    width: 100%;
    margin-top: 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  background: darkgray;
  padding-bottom: 20px;
`;

const HeaderDetails = styled.div`
  width: 80%;
  min-height: 700px;
  margin: 50px 0;
  position: relative;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;

  @media screen and (max-width: 1024px) {
    padding: 30px 0;
    margin-top: 120px;
  }
`;

const PosterWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${
    "" /* @media screen and (max-width: 768px) {
    margin-top: 10px;
  } */
  }
`;

const Social = styled.div`
  display: flex;
`;

const ActorBackground = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5));
  filter: blur(3px);
  position: absolute;
  z-index: -1;
`;

const DetWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 5px 0;
  }
`;

const FavWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;

  @media screen and (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

const FavButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0 10px 30px 0;
  border: 1px solid #fff;
  color: black;
  background-color: ${({ inFav }) => (inFav === "true" ? "red" : "#000")};
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

const SortValues = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 25px;
  margin: 20px;
  border: 1px solid black;
  border-radius: 8px;
  font-size: 13px;
  background: white;

  label {
    margin-right: 5px;
  }

  select {
    border: none;
  }
`;

const Poster = styled.div`
  width: 300px;
  height: 400px;
  border: 1px solid white;
  border-radius: 7px;
  background: url(https://www.themoviedb.org/t/p/w300${(props) => props.bg});
  background-size: cover;
`;

const TextWrapper = styled.div`
  width: 100%;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 7px;
    background-color: #f5f5f5; /* Adjust the background color of the scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Adjust the color of the scrollbar thumb */
    border-radius: 7px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Adjust the color of the scrollbar thumb on hover */
  }
  @media screen and (max-width: 768px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Details = styled.div`
width:60%;
height:80%;
margin-left:50px;
display:flex;
flex-direction:column;
flex-wrap:wrap;
h3{
    color:white;
}
p{
    color:#000;
}

@media screen and (max-width: 1024px){
    margin-left:0;
    width:100%;
    align-self:center;
`;

const Title = styled.span`
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 50px;
`;

const DoB = styled.span`
  color: #fff;
  font-size: 18px;
  margin-top: 5px;

  span {
    color: #000;
  }
`;

const PhotosWrapper = styled.div`
  align-self: flex-start;
  width: 100%;

  overflow-y: auto;
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    max-width: 100%;
  }
`;

const CardWrapperHolder = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: center;
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 7px;
    background-color: #f5f5f5; /* Adjust the background color of the scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Adjust the color of the scrollbar thumb */
    border-radius: 7px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Adjust the color of the scrollbar thumb on hover */
  }
  @media screen and (max-width: 768px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ButtonFocusStyle = css`
  outline: none;
  background-color: #1f1f1f;
  color: white;
  border-bottom: 3px solid #999;
`;
const ButtonNotFocusStyle = css`
  background: #fff;
  color: #000;
`;

const Button = styled.button`
  ${({ focus }) => (focus ? ButtonFocusStyle : ButtonNotFocusStyle)};
  width: 100px;
  height: 30px;
  border: none;
  box-shadow: 2px 2px 2px #888888;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 30px;
  cursor: pointer;
  &:hover {
    ${ButtonFocusStyle}
  }

  &:focus {
    outline: none;
    ${ButtonFocusStyle}
  }

  @media screen and (max-width: 768px) {
    max-width: 100px;
    margin: 5px;
  }
`;

const HearthIcon = styled(AiOutlineHeart)`
  fontsize: 15px;
  color: #fff;
`;
