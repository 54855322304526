import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/images/noImage.png";
import UserContext from "../../context/User/UserContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import AuthContext from "../../context/User/AuthContext";
import WatchedHandler from "../WatchedHandler/WatchedHandler";
import StarComponent from "../RatingComponent/StarComponent";

const CardDetails = (props) => {
  const [showRating, setShowRating] = useState(false);
  const [inWatchList, setInWatchList] = useState(false);
  const [inFavourites, setInFavourites] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showWatchedMessage, setShowWatchedMessage] = useState(false);
  const [inList, setInList] = useState(false);
  const [rating, setRating] = useState(null);
  const {
    removeDataFromWatchlist,
    watchlist,
    favourites,
    removeDataFromFavourites,
    removeFromList,
    findRating,
  } = useContext(UserContext);
  const [person, setPerson] = useLocalStorage("person", "");
  const { removeDetailsFromState, addDetailsToState, user } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const navigateToDetails = () => {
    // setPerson(props.personid != undefined ? props.personid : "");
    navigate(`../detailspage/${props.type}/${props.id}`);
  };
  const mouseEnterHandler = () => {
    setShowMessage(true);
  };
  const mouseLeaveHandler = () => {
    setShowMessage(false);
  };

  const checkIfInWatchlist = () => {
    if (watchlist !== undefined) {
      if (props.type === "movie") {
        if (watchlist.movies.includes(props.id)) {
          setInWatchList(true);
        }
      } else {
        if (watchlist.shows.includes(props.id)) {
          setInWatchList(true);
        }
      }
    }
  };
  const checkIfInFavourites = () => {
    if (favourites !== undefined) {
      if (props.type === "movie") {
        if (favourites.movies.includes(props.id)) {
          setInFavourites(true);
        }
      } else {
        if (favourites.shows.includes(props.id)) {
          setInFavourites(true);
        }
      }
    }
  };
  const checkIfInLists = () => {
    if (props?.where != undefined) {
      setInList(true);
    }
  };

  const removeData = () => {
    if (props.where === "watchlist") {
      removeDataFromWatchlist(props.id, props.type, "watchlist");
      removeDetailsFromState("watchlist", props.type, props.id);
    } else if (props.where === "favourites") {
      removeDataFromFavourites(props.id, props.type);
      removeDetailsFromState("favourites", props.type, props.id);
    } else if (props.where === "watchedMovies") {
      removeDataFromWatchlist(props.id, props.type, "watched");
      removeDetailsFromState("watched", props.type, props.id);
    } else {
      removeFromList(props.where, props.id, props.type);
      removeDetailsFromState("lists", props.type, props.id, props.where);
    }
  };

  useEffect(() => {
    checkIfInWatchlist();
    checkIfInFavourites();
    checkIfInLists();
    if (props.where === "watchedMovies") {
      let rat = findRating(props.id, props.type);
      if (rat) {
        setRating(rat);
      }
    }
    return () => {
      setShowRating(false);
    };
  }, [watchlist, favourites, findRating]);
  return (
    <>
      <Wrapper>
        {props.where === "watchlist" ||
          (props.where === "favourites" && (
            <RemoveButton
              onMouseEnter={mouseEnterHandler}
              onMouseLeave={mouseLeaveHandler}
              onClick={removeData}
              show={inList}
            >
              X
            </RemoveButton>
          ))}

        {user && props.location !== "watched" && (
          <WatchedButton>
            <WatchedHandler
              movie={props.id}
              screenType={props.type}
              // handleRatingConfirm={handleRatingConfirm}
              allDetails={props.allDetails}
              location={props.location}
            />
          </WatchedButton>
        )}

        <WatchlistInfo watched={true} showMessage={showWatchedMessage}>
          Add to Watched
        </WatchlistInfo>
        <WatchlistInfo
          showMessage={showMessage}
        >{`Remove from ${props.where}`}</WatchlistInfo>
        <MainContainer
          onClick={navigateToDetails}
          bg={props.bg}
          rating={props.rating}
          id="cardDetails"
        >
          <Container
            bg={props.bg === null ? "noImage" : props.bg}
            id={props.id}
            rating={props.rating}
            release={props.release}
          ></Container>
          <AllInfo
            bg={props.bg}
            id={props.id}
            rating={props.rating}
            release={props.release}
          >
            <Info
              bg={props.bg}
              id={props.id}
              rating={props.rating}
              release={props.release}
            >
              <RatingWrap rating={props.rating}>
                <RatingNumber>{props.rating?.toFixed(1)}</RatingNumber>
              </RatingWrap>
              {props.allDetails.rating != null && (
                <StarComponent rating={props.allDetails.rating} />
              )}
              <h2>{props.release != null ? props.release.slice(0, 4) : ""}</h2>
            </Info>
            <TitleWrap>
              Title: <Title title={String(props.title)}>{props.title}</Title>
            </TitleWrap>
            {props.character != "" && (
              <h4>
                {props.character && "As:"}{" "}
                <span title={String(props.character)}>{props.character}</span>
              </h4>
            )}
            {props.job && (
              <h4>
                {props.job && "Job:"} <span>{props.job}</span>
              </h4>
            )}
            {props.director && props.episodes > 0 ? (
              <h4>
                {props.job ? "Job:" : ""}
                <span>
                  {" "}
                  Directed {props.episodes} episode
                  {props.episodes > 1 ? "s" : ""}
                </span>
              </h4>
            ) : (
              props.director && (
                <h4>
                  <span>Director</span>
                </h4>
              )
            )}
          </AllInfo>
        </MainContainer>
      </Wrapper>
    </>
  );
};

export default CardDetails;

const Wrapper = styled.div`
  position: relative;
  margin: 10px;
  width: 320px;
  height: 145px;
`;

const MainContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  height: 140px;
  border-radius: 8px;
  background: white;
  box-shadow: 2px 2px 2px #888888;
  color: white;
  cursor: pointer;

  h4 {
    margin: 5px 0;
    color: red;
  }

  span {
    color: black;
    font-size: ${({ title }) => (title?.length < 30 ? "16px" : "12px")};
  }

  &:hover {
    background: gray;
  }
`;

const TitleWrap = styled.div`
  display: flex;
  color: red;
`;

const Title = styled.div`
  color: black;
  margin-left: 5px;
  font-size: ${(props) => (props.title?.length > 30 ? "12px" : "16px")};
`;

const AllInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Info = styled.div.attrs((props) => ({
  style: {
    display: props.bg === null && props.rating === 0 ? "none" : "flex",
  },
}))`
  justify-content: flex-start;
  align-items: center;

  h2 {
    color: black;
    margin-left: 4px;
    text-align: center;
    font-size: 18px;
  }
`;

const WatchedButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 25px;
  height: 25px;
  right: 5px;
  bottom: 10px;
  z-index: 2;
  border-radius: 50%;
  border: 1px solid #000;
  overflow: hidden;
  background: transparent;
  cursor: pointer;
`;

const Container = styled.div.attrs((props) => ({
  style: {
    display: props.bg === null && props.rating === 0 ? "none" : "flex",
    backgroundImage:
      props.bg === "noImage"
        ? `url(${noImage})`
        : `url(https://www.themoviedb.org/t/p/w300${props.bg})`,
  },
}))`
  min-width: 110px;
  height: 140px;
  margin-right: 10px;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
`;

const RatingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.rating >= 6 ? "green" : "red")};
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin: 4px;
`;

const RatingNumber = styled.div`
  color: white;
  text-shadow: 1px solid black;
  font-size: 12px;
`;

const RemoveButton = styled.div`
  position: absolute;
  display: ${(props) => (props.show ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #000;
  font-size: 13px;
  z-index: 50;
  cursor: pointer;
  color: #000;
  &:hover {
    border: 1px solid red;
    color: red;
  }
`;

const WatchlistInfo = styled.span`
  position: absolute;
  display: ${(props) => !props.showMessage && "none"};
  z-index: 60;
  top: ${({ watched }) => (watched ? "90px" : "45px")};
  right: -40px;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 7px;
  font-weight: bold;
  font-size: 12px;
  padding: 3px;
`;
