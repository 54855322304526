import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link as LinkS } from "react-scroll";
import SizeContext from "../../context/SizeContext";

const NavbarLinks = ({ theme }) => {
  const { isSmall } = useContext(SizeContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  if (window.location.pathname !== "/") {
    return null; // Don't render NavbarLinks if the current path is not "/"
  }

  if (isSmall) {
    return null;
  }

  const links = [
    { to: "trending", label: "Trending" },
    { to: "upcoming", label: "Upcoming" },
    { to: "networks", label: "Streaming" },
    { to: "trPeople", label: "Trending People" },
    { to: "oscar", label: "Oscar Winners" },
  ];

  return (
    <MainDiv>
      {links.map((link) => (
        <StyledLink
          key={link.to}
          to={link.to}
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-80}
          theme={theme}
        >
          {link.label}
        </StyledLink>
      ))}
    </MainDiv>
  );
};

export default NavbarLinks;

const MainDiv = styled.div`
  height: 100%;
  min-width: 30%;
  max-width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

const StyledLink = styled(LinkS)`
  color: ${({ theme }) => (theme === "true" ? "#000" : "#fff")};
  cursor: pointer;
  font-size: 12px;
  margin: 0 5px;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  padding-bottom: 3px;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => (theme === true ? "#000" : "#fff")};
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.3s ease-in-out;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`;
