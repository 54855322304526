import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import DetailsWrapper from "../CardWrapper/DetailsWrapper";
import NewListComponent from "./NewListComponent";
import UserContext from "../../context/User/UserContext";
import TmdbContext from "../../context/TmdbContext";
import RemoveOrUpdateListComponent from "./RemoveOrUpdateListComponent";
import { UniversalButtonStyles } from "../../UniversalStyles";
import AuthContext from "../../context/User/AuthContext";
import { MdFormatListBulletedAdd } from "react-icons/md";

const ListComponent = ({ buttonClicked }) => {
  const [userList, setUserList] = useState([]);
  const [listName, setListName] = useState("");
  const [listDetailsFetched, setListDetailsFetched] = useState(false);
  const { lists, removeFromList, listDeleted } = useContext(UserContext);
  const { getDetails } = useContext(TmdbContext);
  const { userEntertDetails } = useContext(AuthContext);

  // Handle the Details for all items in list
  const listHandler = async () => {
    setUserList(userEntertDetails.lists);
  };

  async function listDetailsFunc(value, type) {
    const mOrTvList = type === "movie" ? "movies" : "shows";
    const itemDetails = lists[value][mOrTvList]?.map((item) =>
      getDetails(item, type)
    );
    const itemDetailsResolved = await Promise.all(itemDetails);

    return itemDetailsResolved.map((x) => x[0]);
  }

  const listNameHandler = (value) => {
    setListName(value.list);
    setListDetailsFetched(true);
  };

  useEffect(() => {
    listHandler();
  }, [lists, removeFromList, listDeleted, userEntertDetails]);

  return (
    <UserListsWrapper show={buttonClicked === "Lists" ? "show" : "noShow"}>
      <ListButtonWrapper>
        <ListHandlersWrapper>
          <NewListComponent listNames={lists && lists} />
          <RemoveOrUpdateListComponent listsData={lists} type="remove" />
          <RemoveOrUpdateListComponent listsData={lists} type="update" />
        </ListHandlersWrapper>
        {/* <Title>My Lists:</Title> */}
        <ListButtonsHolder>
          {Object.keys(lists).map((list, idx) => (
            <ListsButton
              focus={listName}
              list={list}
              key={idx}
              onClick={() => {
                listNameHandler({ list });
              }}
            >
              {list.length > 14 ? `${list.substring(0, 14)}...` : list}
            </ListsButton>
          ))}
        </ListButtonsHolder>
      </ListButtonWrapper>
      {Object.keys(lists).length === 0 && <h1>No Lists Yet</h1>}
      <ListContainer>
        {listDetailsFetched && (
          <DetailsWrapper
            movies={userList[listName]?.movies}
            type="movie"
            page="detailsPage"
            where={listName}
          />
        )}
        {listDetailsFetched && (
          <DetailsWrapper
            movies={userList[listName]?.shows}
            type="tv"
            page="detailsPage"
            where={listName}
          />
        )}
      </ListContainer>
    </UserListsWrapper>
  );
};

export default ListComponent;

const UserListsWrapper = styled.div`
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  /* border: 1px solid #000; */
  display: ${(props) => props.show != "show" && "none"};

  h1 {
    margin-left: 20px;
    font-size: 20px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    h1 {
      width: 100%;
      font-size: 16px;
      text-align: center;
      margin: 10px 0;
    }
  }
`;

const ListButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 20px;
  span {
    margin-left: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0;
    span {
      margin: 20px 0;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
`;

const Title = styled.div`
  font-weight: bold;
  /* margin: 20px; */
  font-size: 20px;
`;

const ListsButton = styled.button`
  ${UniversalButtonStyles};
  width: 140px;
  padding: 8px 16px;
  margin: 5px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: ${(props) =>
    props.list === props.focus ? "#007bff" : "#fff"};
  color: ${(props) => (props.list === props.focus ? "#fff" : "#000")};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${(props) =>
      props.list === props.focus ? "#0056b3" : "#f0f0f0"};
    color: ${(props) => (props.list === props.focus ? "#fff" : "#000")};
  }

  @media screen and (max-width: 768px) {
    padding: 6px 12px;
    font-size: 14px;
  }
`;
const ListHandlersWrapper = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  border-bottom: 1px solid #000;
  /* flex-wrap: wrap; */
  margin-top: 22px;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
    height: 80px;
  }
`;

const ListButtonsHolder = styled.div`
  display: flex;
  align-items: flex-start; // Align items at the start of the container
  justify-content: flex-start; // Align buttons to the left
  flex-wrap: wrap; // Wrap buttons to the next line if they exceed container width
  width: 100%;
  padding: 10px 0; // Padding for spacing above and below the button row

  @media screen and (max-width: 768px) {
    justify-content: center; // Center buttons on smaller screens if desired
  }
`;
const ListContainer = styled.div`
  height: 500px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 7px;
    background-color: #f5f5f5; /* Adjust the background color of the scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Adjust the color of the scrollbar thumb */
    border-radius: 7px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Adjust the color of the scrollbar thumb on hover */
  }
  @media screen and (max-width: 768px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
