import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import DragDrop from "../DragnDrop/Drop";
import UserContext from "../../context/User/UserContext";
import { UniversalButtonStyles } from "../../UniversalStyles";
import AuthContext from "../../context/User/AuthContext";

const WatchlistComponent = ({ show }) => {
  const [movieOrTv, setMovieOrTv] = useState("movie");
  const [movieDetails, setMovieDetails] = useState([]);
  const { watchlist, ratings, deleted } = useContext(UserContext);
  const { userEntertDetails, removeDetailsFromState } = useContext(AuthContext);

  const movieOrTvHandler = (input) => {
    setMovieOrTv(input);
  };
  const settingMovieDetails = () => {
    setMovieDetails(userEntertDetails.watchlist);
  };
  useEffect(() => {
    settingMovieDetails();
  }, [userEntertDetails]);
  if (show) {
    return (
      <WatchlistWrapper>
        <ListSelector>
          <ListButton
            onClick={() => movieOrTvHandler("movie")}
            show={movieOrTv === "movie"}
          >
            MOVIES ({watchlist?.movies?.length})
          </ListButton>
          <ListButton
            onClick={() => movieOrTvHandler("tv")}
            show={movieOrTv === "tv"}
          >
            SHOWS ({watchlist?.shows?.length})
          </ListButton>
        </ListSelector>

        <DNDInfo>Please drag and drop to order your watchlist.</DNDInfo>
        <DragDropWrapper>
          {movieOrTv === "movie" ? (
            <DragDrop
              type="movie"
              watchlist={watchlist}
              detailedList={movieDetails.movies}
              location="Watchlist"
            />
          ) : (
            <DragDrop
              type="tv"
              watchlist={watchlist}
              detailedList={movieDetails.shows}
              location="Watchlist"
            />
          )}
        </DragDropWrapper>
      </WatchlistWrapper>
    );
  }
};

export default WatchlistComponent;

const WatchlistWrapper = styled.div`
  width: 80%;
  min-height: 600px;
  display: flex;
  flex-direction: column;

  h4 {
    text-align: center;
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    margin: 50px 0;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }
`;

const DragDropWrapper = styled.div`
  margin-top: 20px;
  height: 600px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 7px;
    background-color: #f5f5f5; /* Adjust the background color of the scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Adjust the color of the scrollbar thumb */
    border-radius: 7px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Adjust the color of the scrollbar thumb on hover */
  }
  @media screen and (max-width: 768px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const ListSelector = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

const ListButton = styled.div`
  color: ${({ show }) => (show ? "powderblue" : "black")};
  background: ${({ show }) => (show ? "black" : "white")};
  ${UniversalButtonStyles};
`;
const DNDInfo = styled.h4`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
