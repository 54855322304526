import React from 'react';
import styled, { keyframes } from 'styled-components';

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Loader = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #000;
  border-radius: 50%;
  border-top-color: transparent;
  animation: ${spinAnimation} 0.8s linear infinite;
`;

const LoadingCircle = () => {
    return (
        <LoaderWrapper>
            <Loader />
        </LoaderWrapper>
    );
};

export default LoadingCircle;