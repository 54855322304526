import React, { createContext, useReducer, useEffect, useContext } from "react";
import AuthContext from "./AuthContext";
import userReducer from "./UserReducer";
import { db, storage } from "../../firebase";
import TmdbContext from "../TmdbContext";

import {
  query,
  getDocs,
  doc,
  getDoc,
  collection,
  where,
  addDoc,
  updateDoc,
} from "firebase/firestore";
const UserContext = createContext("");

export const UserProvider = ({ children }) => {
  const initialState = {
    id: null,
    imgUrl: null,
    watchlist: {
      movies: [],
      shows: [],
    },
    watched: {
      movies: [],
      shows: [],
    },
    ratings: {
      movies: [],
      shows: [],
    },
    favourites: {
      movies: [],
      shows: [],
    },
    favouriteActor: {
      actorId: [],
    },

    deleted: {},
    lists: { firstList: [] },
    loginstate: false,
    showrating: false,
    ratingobj: {
      type: "movie",
      id: null,
    },
  };

  const { user, updateFavouritePeopleDetails } = useContext(AuthContext);
  const [state, dispatch] = useReducer(userReducer, initialState);
  useEffect(() => {
    if (user) {
      // User is authenticated, fetch user data and update states here

      const fetchData = async () => {
        const id = getDocId(user.uid);
        try {
          // Update your states with user data as needed
          const updatedInitialState = {
            ...initialState,
            user: user,
            id: id,
            favouriteActor: user.favouriteactor,
            favourites: user.favourites,
            lists: user.lists,
            ratings: user.ratings,
            watched: user.watched,
            watchlist: user.watchlist,
            // Update other properties based on userData
          };

          dispatch({
            type: "UPDATE_USER_DATA",
            payload: updatedInitialState,
            user: user,
            id: id,
            favouriteActor: user.favouriteactor,
            favourites: user.favourites,
            lists: user.lists,
            ratings: user.ratings,
            watched: user.watched,
            watchlist: user.watchlist,
          });
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchData();
    } else {
      // User is not authenticated, reset states if needed
      const updatedInitialState = {
        ...initialState,
        user: null,
        id: null,
        watchlist: {
          movies: [],
          shows: [],
        },
        watched: {
          movies: [],
          shows: [],
        },
        ratings: {
          movies: [],
          shows: [],
        },
        favourites: {
          movies: [],
          shows: [],
        },
        favouriteActor: {
          actorId: [],
        },

        deleted: {},
        lists: { firstList: [] },
        // Reset other properties
      };

      dispatch({
        type: "RESET_USER_DATA",
        user: updatedInitialState.user,
        id: updatedInitialState.id,
        favouriteActor: updatedInitialState.favouriteActor,
        favourites: updatedInitialState.favourites,
        lists: updatedInitialState.lists,
        ratings: updatedInitialState.ratings,
        watched: updatedInitialState.watched,
        watchlist: updatedInitialState.watchlist,
      });
    }
  }, [user]);

  // get the id of the user
  const getDocId = async (userid) => {
    const q = query(collection(db, "users"), where("uid", "==", userid));
    const snapshot = await getDocs(q);
    const data = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    dispatch({
      type: "GET_DOC_ID",
      id: data[0].id,
    });
    return data[0].id;
  };

  const showRatingFunc = (status, id, type, details) => {
    let ratingShown = state.showrating;
    ratingShown = status;
    let newobj = state.ratingobj;
    newobj = {
      id: id,
      type: type,
      details: details,
    };

    dispatch({
      type: "SHOWRATING",
      payload: ratingShown,
      newratingobj: newobj,
    });
  };

  const closeRatingFunc = () => {
    let ratingShown = state.showrating;
    ratingShown = false;

    dispatch({
      type: "CLOSERATING",
      payload: ratingShown,
    });
  };

  const addToRatings = (id, type, rating) => {
    let selector = type === "tv" ? "shows" : "movies";
    const docRef = doc(db, "users", state.id);
    let currentList = state.ratings;

    const existingItem = currentList[selector].find(
      (item) => item.movieId === id
    );
    if (existingItem) {
      if (existingItem.rating === rating) {
        console.log("Rating is the same. No update needed.");
        return;
      }
      existingItem.rating = rating;
    } else {
      currentList[selector].push({ movieId: id, rating: rating });
    }

    let data = { ...currentList };

    updateDoc(docRef, {
      ratings: data,
    })
      .then(() => {
        console.log("Ratings updated successfully!");
        dispatch({
          type: "ADD_TO_RATINGS",
          payload: data,
        });
      })
      .catch((error) => {
        console.log("Error updating ratings:", error);
      });
  };

  const removeFromRatings = (id, type) => {
    let selector = "";
    if (type === "tv") {
      selector = "shows";
    } else {
      selector = "movies";
    }

    const docRef = doc(db, "users", state.id);
    let currentList = state.ratings;

    // Find the index of the movie/show with the given id
    const index = currentList[selector].findIndex(
      (item) => item.movieId === id
    );

    if (index === -1) {
      console.log(`No ${type} with id ${id} found in ratings.`);
      return;
    }

    // Remove the movie/show from the ratings list
    currentList[selector].splice(index, 1);

    let data = {};
    data = currentList;

    // Update ratings in Firestore
    updateDoc(docRef, {
      ratings: data,
    })
      .then(() => {
        console.log("Ratings updated successfully!");
        dispatch({
          type: "REMOVE_FROM_RATINGS",
          payload: state.ratings,
        });
      })
      .catch((error) => {
        console.log("Error updating ratings:", error);
      });
  };
  // Find Rating

  function findRating(id, type) {
    let selector = "";
    if (type === "tv") {
      selector = "shows";
    } else {
      selector = "movies";
    }

    let ratings = state.ratings[selector];
    for (let i = 0; i < ratings.length; i++) {
      if (ratings[i].movieId === id) {
        return ratings[i].rating;
      }
    }
    return null; // if rating is not found
  }

  // Removing data from the watchlist
  const removeDataFromWatchlist = (id, showOrMovie, where) => {
    let watchlistMovies = [];
    let watchlistShows = [];
    console.log("test");
    if (where === "watchlist") {
      state.watchlist.movies.forEach((movieId) => {
        watchlistMovies.push(movieId);
      });
      state.watchlist.shows.forEach((showId) => {
        watchlistShows.push(showId);
      });
    } else if (where === "watched") {
      state.watched.movies.forEach((movieId) => {
        watchlistMovies.push(movieId);
        console.log("working");
      });
      state.watched.shows.forEach((showId) => {
        watchlistShows.push(showId);
      });
    }

    if (showOrMovie === "movie") {
      const filteredMovies = watchlistMovies.filter(
        (movieid) => movieid !== id
      );
      watchlistMovies = filteredMovies;
    } else {
      const filteredShows = watchlistShows.filter((showid) => showid !== id);
      watchlistShows = filteredShows;
    }

    const newObj = {
      movies: watchlistMovies,
      shows: watchlistShows,
    };
    if (where === "watchlist") {
      dispatch({
        type: "UPDATE_WATCHLIST",
        payload: newObj,
        deleted: newObj,
      });
    } else if (where === "watched") {
      dispatch({
        type: "UPDATE_WATCHED",
        payload: newObj,
      });
      removeFromRatings(id, showOrMovie);
    }
    updWatchlist(newObj, where);
  };

  // Update Watchlist
  const updWatchlist = async (newData, where) => {
    const docRef = doc(db, "users", state.id);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      // Document exists, you can proceed with the update
    } else {
      // Document doesn't exist, handle this case accordingly
      console.error("Document does not exist!");
    }
    if (where === "watchlist") {
      updateDoc(docRef, {
        watchlist: newData,
      });
    } else if (where === "watched") {
      updateDoc(docRef, {
        watched: newData,
      });
    }
  };
  const updateWatchlistAfterDrop = async (type, newWatchlist) => {
    const watchlistMovies = [];
    const watchlistShows = [];

    if (type === "movie") {
      // Ensure state.watchlist.shows is an array before iterating
      if (Array.isArray(state.watchlist.shows)) {
        state.watchlist.shows.forEach((movieId) => {
          watchlistShows.push(movieId);
        });
      }
      newWatchlist.forEach((movie) => {
        if (movie?.id) watchlistMovies.push(movie.id); // Also, defensively check if movie has an id
      });
    } else {
      // Ensure state.watchlist.movies is an array before iterating
      if (Array.isArray(state.watchlist.movies)) {
        state.watchlist.movies.forEach((movieId) => {
          watchlistMovies.push(movieId);
        });
      }
      newWatchlist.forEach((show) => {
        if (show?.id) watchlistShows.push(show.id); // Defensively check if show has an id
      });
    }

    const newObj = {
      movies: watchlistMovies,
      shows: watchlistShows,
    };

    dispatch({
      type: "UPDATE_WATCHLIST",
      payload: newObj,
    });
    console.log("Type:", type);
    console.log("New Watchlist:", newWatchlist);
    console.log("State Watchlist Movies:", state.watchlist.movies);
    console.log("State Watchlist Shows:", state.watchlist.shows);

    updWatchlist(newObj, "watchlist");
  };

  const updateWatchlist = async (id, showOrMovie, where) => {
    const watchlistMovies = [];
    const watchlistShows = [];

    if (where === "watchlist") {
      state.watchlist.movies.forEach((movieId) => {
        watchlistMovies.push(movieId);
      });
      state.watchlist.shows.forEach((showId) => {
        watchlistShows.push(showId);
      });
    } else if (where === "watched") {
      state.watched.movies.forEach((movieId) => {
        watchlistMovies.push(movieId);
      });
      state.watched.shows.forEach((showId) => {
        watchlistShows.push(showId);
      });
    }

    if (showOrMovie === "movie") {
      if (where === "watched" && watchlistMovies.includes(id)) {
        console.log("Already in watched list");
        return;
      }
      watchlistMovies.push(id);
    } else {
      if (where === "watched" && watchlistShows.includes(id)) {
        console.log("Already in watched list");
        return;
      }
      watchlistShows.push(id);
    }

    const newObj = {
      movies: watchlistMovies,
      shows: watchlistShows,
    };

    if (where === "watchlist") {
      dispatch({
        type: "UPDATE_WATCHLIST",
        payload: newObj,
      });
    } else if (where === "watched") {
      dispatch({
        type: "UPDATE_WATCHED",
        payload: newObj,
      });
    }

    updWatchlist(newObj, where);
  };

  // Add new List
  const addNewList = (listName) => {
    const docRef = doc(db, "users", state.id);
    updateDoc(docRef, {
      lists: listName,
    });
  };
  // Update List
  const updateList = (list) => {
    const prop = list;
    state.lists[prop] = {
      movies: [],
      shows: [],
    };

    addNewList(state.lists);
    dispatch({
      type: "ADD_NEW_LIST",
      payload: state.lists,
    });
  };

  const deleteList = (listName) => {
    const docRef = doc(db, "users", state.id);
    const newList = { ...state.lists };
    delete newList[listName];
    updateDoc(docRef, {
      lists: newList,
    })
      .then(() => {
        console.log(`${listName} has been deleted.`);
        dispatch({
          type: "ADD_NEW_LIST",
          payload: newList,
        });
      })
      .catch((error) => {
        console.error(`Error deleting ${listName}: ${error}`);
      });
  };

  const updateListName = (listName, newListName) => {
    const updatedLists = {
      ...state.lists,
      [newListName]: state.lists[listName],
    };
    delete updatedLists[listName];
    const updatedState = updatedLists;
    const docRef = doc(db, "users", state.id);
    const newList = { ...updatedState };
    updateDoc(docRef, {
      lists: newList,
    })
      .then(() => {
        console.log(`${listName} has been deleted.`);
        dispatch({
          type: "ADD_NEW_LIST",
          payload: newList,
        });
      })
      .catch((error) => {
        console.error(`Error deleting ${listName}: ${error}`);
      });
  };

  const addToList = (listName, id, type) => {
    const selector = type === "movie" ? "movies" : "shows";
    let currentList = state.lists[listName][selector];
    currentList.push(id);
    addNewList(state.lists);
    dispatch({
      type: "ADD_TO_LIST",
      payload: state.lists,
    });
  };

  const creatingAndAddingToList = (newList, movieId, showOrMovie) => {
    let newObj = {};
    if (showOrMovie === "movie") {
      newObj = {
        movies: [movieId],
        shows: [],
      };
    } else {
      newObj = {
        movies: [],
        shows: [movieId],
      };
    }
    const prop = newList.charAt(0).toUpperCase() + newList.slice(1);
    // Create a copy of the current state.lists object
    const updatedLists = { ...state.lists };
    // Update the updatedLists object with the new list object
    updatedLists[prop] = newObj;

    // Call addNewList with the updatedLists object
    addNewList(updatedLists);

    // Dispatch an action with the updated state
    dispatch({
      type: "ADD_NEW_LIST",
      payload: updatedLists,
    });
  };

  const removeFromList = (listName, id, type) => {
    const selector = type === "movie" ? "movies" : "shows";
    let filtered = state.lists[listName][selector].filter(
      (movieId) => movieId != id
    );
    state.lists[listName][selector] = filtered;
    const newState = state.lists;
    addNewList(state.lists);
    dispatch({
      type: "ADD_TO_LIST",
      payload: newState,
    });
  };

  const checkIfInLists = (type, id) => {
    const selector = type === "movie" ? "movies" : "shows";
    const listNames = Object.keys(state.lists).map((list) => list);
    const movieInList = Object.values(state.lists).map((list) =>
      list[selector]?.includes(id)
    );
    const indexOfAll = (arr, val) =>
      arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), []);

    const indexofListName = indexOfAll(movieInList, true);
    const nameOfListWhereIn = indexofListName.map((idx) => listNames[idx]);

    return nameOfListWhereIn;
  };

  // Update Favourites
  const addFavourite = (newData) => {
    const docRef = doc(db, "users", state.id);
    updateDoc(docRef, {
      favourites: newData,
    });
  };

  const updateFavourites = async (id, showOrMovie) => {
    const favouriteMovies = [];
    const favouriteShows = [];
    state.favourites.movies.forEach((movieId) => {
      favouriteMovies.push(movieId);
    });
    state.favourites.shows.forEach((showId) => {
      favouriteShows.push(showId);
    });
    if (showOrMovie === "movie") {
      favouriteMovies.push(id);
    } else {
      favouriteShows.push(id);
    }

    const newObj = {
      movies: favouriteMovies,
      shows: favouriteShows,
    };
    dispatch({
      type: "UPDATE_FAVOURITES",
      payload: newObj,
    });
    addFavourite(newObj);
  };
  // Remove data from Favourites
  const removeDataFromFavourites = (id, showOrMovie) => {
    let favouriteMovies = [];
    let favouriteShows = [];
    state.favourites.movies.forEach((movieId) => {
      favouriteMovies.push(movieId);
    });
    state.favourites.shows.forEach((showId) => {
      favouriteShows.push(showId);
    });
    if (showOrMovie === "movie") {
      const filteredMovies = favouriteMovies.filter(
        (movieid) => movieid !== id
      );
      favouriteMovies = filteredMovies;
    } else {
      const filteredShows = favouriteShows.filter((showid) => showid !== id);
      favouriteShows = filteredShows;
    }

    const newObj = {
      movies: favouriteMovies,
      shows: favouriteShows,
    };
    dispatch({
      type: "UPDATE_FAVOURITES",
      payload: newObj,
    });
    addFavourite(newObj);
  };

  // Update Favourite Actors

  const addFavouriteActor = (newData) => {
    const docRef = doc(db, "users", state.id);
    updateDoc(docRef, {
      favouriteactor: newData,
    });
  };

  const updateFavouriteActors = async (id) => {
    const favouriteActor = state.favouriteActor.actorId.slice(); // Create a copy of the favouriteactor array

    const index = favouriteActor.indexOf(id);
    if (index !== -1) {
      favouriteActor.splice(index, 1);
      updateFavouritePeopleDetails(id, "remove"); // Remove the ID if it already exists
    } else {
      favouriteActor.push(id);
      updateFavouritePeopleDetails(id, "add");
      // Add the ID if it doesn't exist
    }

    const newObj = {
      actorId: favouriteActor,
    };

    dispatch({
      type: "UPDATE_FAVOURITE_ACTORS",
      payload: newObj,
    });

    addFavouriteActor(newObj);
  };

  return (
    <UserContext.Provider
      value={{
        lists: state.lists,
        updateList,
        addNewList,
        updWatchlist,
        updateWatchlist,
        updateFavourites,
        updateFavouriteActors,
        removeDataFromWatchlist,
        removeDataFromFavourites,
        addToList,
        removeFromList,
        checkIfInLists,
        deleteList,
        updateListName,
        addToRatings,
        findRating,
        updateWatchlistAfterDrop,
        creatingAndAddingToList,
        showRatingFunc,
        closeRatingFunc,
        showrating: state.showrating,
        ratingobj: state.ratingobj,
        showNavButtons: state.showNavButtons,
        watchlist: state.watchlist,
        watchedList: state.watched,
        favourites: state.favourites,
        ratings: state.ratings,
        deleted: state.deleted,
        favouriteActor: state.favouriteActor,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
