import React, { useContext, useState, useEffect, useMemo } from "react";
import FirstLoaderContext from "../../context/FirstLoaders/FirstLoaders";
import styled, { keyframes } from "styled-components";
import noImage from "../../assets/images/noImage.png";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const HeaderBackdrop = () => {
  const { trendingMovies } = useContext(FirstLoaderContext);
  const [randomIndex, setRandomIndex] = useState(
    Math.floor(Math.random() * trendingMovies?.length)
  );
  const movie = trendingMovies[randomIndex] || {};
  const navigate = useNavigate();

  const navigateToDetails = async (id, type) => {
    navigate(`../detailspage/${type}/${id}`);
  };

  const idHandler = async () => {
    await navigateToDetails(movie.id, movie.media_type);
  };

  const handleBackButtonClick = (event) => {
    event.stopPropagation();
    setRandomIndex(randomIndex === 0 ? 19 : randomIndex - 1);
  };

  const handleForwardButtonClick = (event) => {
    event.stopPropagation();
    setRandomIndex(randomIndex === 19 ? 0 : randomIndex + 1);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRandomIndex(randomIndex === 19 ? 0 : randomIndex + 1);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [movie]);

  return (
    <MainWrapper>
      <SearchBoxWrapper onClick={idHandler} bg={movie.backdrop_path}>
        <Title>{movie.title?.toUpperCase()}</Title>
        <ReleaseDate>
          {movie.release_date?.split("-").reverse().join("-")}
        </ReleaseDate>
        <ArrowBack onClick={handleBackButtonClick} />
        <ArrowForward onClick={handleForwardButtonClick} />
      </SearchBoxWrapper>
    </MainWrapper>
  );
};

export default HeaderBackdrop;

const MoveUpDown = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0% 50%;
  }
`;

const MainWrapper = styled.div`
  width: 100%;
  height: 460px;
  position: relative;
  padding-top: 60px;
  background: linear-gradient(to top, rgba(87, 84, 84, 0), rgba(87, 84, 84, 1));

  @media (max-width: 768px) {
    height: 320px;
    padding-top: 0;
  }
`;

const SearchBoxWrapper = styled.div`
  ${"" /* display: flex; */}
  justify-content: space-evenly;
  padding: 20px 0;
  height: 380px;
  width: 95%;
  background-image: ${({ bg }) =>
    `url(${bg ? `https://www.themoviedb.org/t/p/original${bg}` : noImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: ${MoveUpDown} 30s linear infinite alternate;
  position: relative;
  left: 2.5%;
  top: 30px;
  margin-bottom: 20px;
  transition: 1s ease-out;
  cursor: pointer;
  z-index: 100;

  @media (max-width: 768px) {
    height: 240px;
    background-size: cover;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
`;

const Title = styled.div`
  position: absolute;
  top: 330px;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-style: italic;
  font-size: 30px;
  align-self: flex-end;
  background: linear-gradient(
    to left,
    rgba(255, 0, 0, 0.4),
    rgba(255, 0, 0, 1)
  );
  padding: 10px 30px;

  @media (max-width: 768px) {
    top: 200px;
    font-size: 18px;
  }
`;

const ReleaseDate = styled.div`
  color: white;
  text-shadow: 1px 1px 1px black;
  font-style: italic;
  position: absolute;
  top: 300px;
  padding-left: 30px;
  font-weight: bold;

  &:before {
    color: white;
    font-size: 16px;
    content: "Release: ";
  }

  @media (max-width: 768px) {
    top: 170px;
    &:before {
      font-size: 16px;
    }
  }
`;

const ArrowBack = styled(IoIosArrowBack)`
  color: white;
  position: absolute;
  bottom: 5px;
  left: 10px;
  font-size: 30px;

  &:hover {
    color: red;
  }
`;

const ArrowForward = styled(IoIosArrowForward)`
  color: white;
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 30px;

  &:hover {
    color: red;
  }
`;
