import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { UniversalButtonStyles } from "../../UniversalStyles";
import { useLocalStorage } from "react-use";

const SearchParameters = (props) => {
  const [fromYear, setFromYear] = useLocalStorage("fromYear", "", {
    raw: true,
  });
  const [toYear, setToYear] = useLocalStorage("toYear", "", { raw: true });
  const [genre, setGenre] = useLocalStorage("genre", "Any", { raw: true });
  const [minRating, setMinRating] = useLocalStorage("minRating", "6", {
    raw: true,
  });
  const [provider, setProvider] = useLocalStorage("provider", "Any", {
    raw: true,
  });
  const [sortBy, setSortBy] = useLocalStorage("sortBy", "MostPopular", {
    raw: true,
  });
  const inputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/") {
      setFromYear("DEFAULT");
      setToYear("DEFAULT");
      setGenre("DEFAULT");
      setMinRating("DEFAULT");
      setProvider("DEFAULT");
      setSortBy("DEFAULT");
    }
    const handleStorageChange = (e) => {
      if (e.key === "fromYear") {
        setFromYear(e.newValue);
      } else if (e.key === "toYear") {
        setToYear(e.newValue);
      } else if (e.key === "genre") {
        setGenre(e.newValue);
      } else if (e.key === "minRating") {
        setMinRating(e.newValue);
      } else if (e.key === "provider") {
        setProvider(e.newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleFromYear = (event) => {
    setFromYear(event.target.value);
  };

  const handleToYear = (event) => {
    setToYear(event.target.value);
  };

  const handleGenre = (event) => {
    setGenre(event.target.value);
  };

  const handleProvider = (event) => {
    setProvider(event.target.value);
  };

  const handleMinRating = (event) => {
    setMinRating(event.target.value);
  };

  const handleSortBy = (event) => {
    setSortBy(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // if (parseInt(fromYear) > parseInt(toYear)) {
    //   const tempToYear = fromYear;
    //   const tempFromYear = toYear;
    //   navigate(
    //     `/searchrespar/${
    //       tempFromYear === "DEFAULT" ? 1896 + "-01-01" : tempFromYear + "-01-01"
    //     }/${
    //       tempToYear === "DEFAULT"
    //         ? Number(new Date().getFullYear() + 1) + "-01-01"
    //         : tempToYear + "-01-01"
    //     }/${genre === "DEFAULT" ? "Any" : genre}/${
    //       minRating === "DEFAULT" ? "6" : minRating
    //     }/${provider === "DEFAULT" ? "Any" : provider}/${
    //       sortBy === "DEFAULT" ? "MostPopular" : sortBy
    //     }`
    //   );
    // } else {
    navigate(
      `/searchrespar/${
        fromYear === "DEFAULT" ? 1896 + "-01-01" : fromYear + "-01-01"
      }/${
        toYear === "DEFAULT"
          ? Number(new Date().getFullYear() + 1) + "-01-01"
          : toYear + "-01-01"
      }/${genre === "DEFAULT" ? "Any" : genre}/${
        minRating === "DEFAULT" ? "6" : minRating
      }/${provider === "DEFAULT" ? "Any" : provider}/${
        sortBy === "DEFAULT" ? "MostPopular" : sortBy
      }`
    );
    // }

    // handleClear();

    inputRef.current?.blur();
  };

  const handleClear = () => {
    setFromYear("DEFAULT");
    setToYear("DEFAULT");
    setGenre("DEFAULT");
    setMinRating("DEFAULT");
    setProvider("DEFAULT");
    setSortBy("DEFAULT");
  };

  return (
    <MainWrap>
      <Title>Browse by:</Title>
      <Input>
        <InputBox>
          {/* <Parameters>From Year:</Parameters> */}
          <select
            value={fromYear}
            onChange={handleFromYear}
            id="fromYear"
            name="fromYear"
            ref={inputRef}
          >
            {" "}
            <option value="DEFAULT" disabled>
              From Year
            </option>
            {Array.from(
              { length: new Date().getFullYear() - 1895 },
              (_, index) => (
                <option key={index} value={new Date().getFullYear() - index}>
                  {new Date().getFullYear() - index}
                </option>
              )
            )}
          </select>
        </InputBox>
        <InputBox>
          {/* <Parameters>To Year:</Parameters> */}
          <select
            value={toYear}
            onChange={handleToYear}
            id="toYear"
            name="toYear"
            ref={inputRef}
          >
            <option value="DEFAULT" disabled>
              To Year
            </option>
            {Array.from(
              {
                length:
                  fromYear === ""
                    ? new Date().getFullYear() - 1895
                    : new Date().getFullYear() - fromYear,
              },
              (_, index) => (
                <option key={index} value={new Date().getFullYear() - index}>
                  {new Date().getFullYear() - index}
                </option>
              )
            )}
          </select>
        </InputBox>
        <InputBox>
          {/* <Parameters>Genre:</Parameters> */}
          <select value={genre} onChange={handleGenre} name="genre" id="genre">
            <option value="DEFAULT" disabled>
              Genre
            </option>
            <option value="Any">Any</option>
            <option value="Drama">Drama</option>
            <option value="Comedy">Comedy</option>
            <option value="History">History</option>
            <option value="Action">Action</option>
            <option value="Adventure">Adventure</option>
            <option value="Animation">Animation</option>
            <option value="Crime">Crime</option>
            <option value="Drama">Drama</option>
            <option value="Fantasy">Fantasy</option>
            <option value="Family">Family</option>
            <option value="Mistery">Mistery</option>
            <option value="Music">Music</option>
            <option value="Romance">Romance</option>
            <option value="Sci-Fi">Sci-Fi</option>
            <option value="Thriller">Thriller</option>
            <option value="Horror">Horror</option>
            <option value="War">War</option>
          </select>
        </InputBox>
        <InputBox>
          {/* <Parameters>Min. rating:</Parameters> */}
          <select
            value={minRating}
            onChange={handleMinRating}
            name="minRating"
            id="minRating"
          >
            <option value="DEFAULT" disabled>
              Min Rating
            </option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
          </select>
        </InputBox>
        <InputBox>
          {/* <Parameters>Provider:</Parameters> */}
          <select
            value={provider}
            onChange={handleProvider}
            name="provider"
            id="provider"
          >
            <option value="DEFAULT" disabled>
              Provider
            </option>
            <option value="Netflix">Netflix</option>
            <option value="DisneyPlus">DisneyPlus</option>
            <option value="AppleTV">AppleTV</option>
            <option value="AppleTVPLUS">AppleTVPLUS</option>
            <option value="AmazonPrime">Amazon Prime</option>
            <option value="Paramount">Paramount</option>
            <option value="Channel4">Channel4</option>
            <option value="SkyGo">SkyGo</option>
            <option value="BBCiPlayer">BBC iPLayer</option>
            <option value="SkyStore">Fantasy</option>
            <option value="Chili">Chili</option>
            <option value="Youtube">Youtube</option>
            <option value="RakutenTV">RakutenTV</option>
          </select>
        </InputBox>
        <InputBox>
          {/* <Parameters>Sort by:</Parameters> */}
          <select
            value={sortBy}
            onChange={handleSortBy}
            name="sortBy"
            id="sortBy"
          >
            <option value="DEFAULT" disabled>
              Sort By
            </option>
            <option value="MostPopular">Most Popular</option>
            <option value="Highest">Highest Rating</option>
            <option value="Lowest">Lowest Rating</option>
            <option value="Newest">Newest </option>
            <option value="Oldest">Oldest</option>
          </select>
        </InputBox>
      </Input>
      <ButtonWrap>
        <Button onClick={handleClear}>Reset</Button>
        <Button onClick={handleSubmit}>Search</Button>
      </ButtonWrap>
      {/* <CardWrapper /> */}
    </MainWrap>
  );
};

export default SearchParameters;

const MainWrap = styled.div`
  width: 100%;
  height: auto;
  background: black;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  margin: 10px 0;
  font-size: 20px;
  color: white;
`;

const Input = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  @media screen and (max-width: 1024px) {
    width: 80%;
    flex-wrap: wrap;
  }
`;

const InputBox = styled.div`
  display: flex;

  select {
    -webkit-appearance: none;
    width: 120px;
    padding: 5px;
    margin: 5px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 24 24" fill="none" stroke="%23333" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"><path d="M19 9l-7 7-7-7"></path></svg>');
    background-repeat: no-repeat;
    background-position: right 5px center;
    border-radius: 7px;
    color: black;
    font-size: 14px;
  }

  option {
    -webkit-appearance: none;
    margin: 5px;
    font-size: 16px;
    border: 1px solid #fff;
    background: #fff;
    color: black;
  }

  @media screen and (max-width: 768px) {
    select {
      -webkit-appearance: none;
      padding: 5px;
      margin: 5px;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 24 24" fill="none" stroke="%23333" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"><path d="M19 9l-7 7-7-7"></path></svg>');
      background-repeat: no-repeat;
      background-position: right 5px center;
      border-radius: 8px;
      font-size: 14px;
      color: black;
    }

    option {
      -webkit-appearance: none;
      margin: 5px;
      font-size: 16px;
      width: 120px;
      border: 1px solid #fff;
      background: #fff;
      color: black;
    }
  }
`;

const Parameters = styled.div`
  font-size: 16px;
  color: white;
  margin: 0 10px;

  @media screen and (min-width: 800px) and (max-width: 860px) {
    font-size: 14px;
  }

  @media screen and (max-width: 1024px) {
    width: 100px;
    margin: 0;
  }
`;

const ButtonWrap = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-evenly;
  }
`;

const Button = styled.button`
  color: black;
  ${UniversalButtonStyles};
  text-transform: uppercase;
  width: 150px;
  margin: 20px 10px;

  @media screen and (max-width: 768px) {
    margin: 10px 0 20px 0;
    font-size: 12px;
  }
`;
