import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import TmdbContext from "../context/TmdbContext";
import HeaderBackdrop from "../components/HeaderBackdrop/HeaderBackdropcomponent";
import LoadingCircle from "../components/Loading/LoadingCircle";
import Card from "../components/CardWrapper/Card";
import CardPeople from "../components/CardWrapper/CardPeople";
import { UniversalButtonStyles } from "../UniversalStyles";
import {
  MdOutlineArrowBackIosNew as BackArrow,
  MdOutlineArrowForwardIos as ForwardArrow,
} from "react-icons/md";

const SearchResultPage = () => {
  const {
    searchMovies,
    searchTV,
    searchPeople,
    searchLoading,
    getSearch,
    searchMoviesFunc,
    searchShowsFunc,
    searchPeopleFunc,
  } = useContext(TmdbContext);
  const { searchvalue } = useParams();
  const [type, setType] = useState("MOVIES");
  const [pageNumber, setPageNumber] = useState(
    Number(sessionStorage.getItem("pageNumber")) || 1
  );
  const [totalResults, setTotalResults] = useState([]);
  const [totalPageNumber, setTotalPageNumber] = useState([]);

  const handleClick = (button) => {
    setType(button);
    sessionStorage.setItem("pageNumber", 1);
    setPageNumber(1);
    setPageNumber(1);
    setPageNumber(1);
  };

  const sessionFunc = (value) => {
    sessionStorage.setItem("pageNumber", value);
  };

  const handlePage = (action) => {
    if (type === "MOVIES") {
      if (action === "sub") {
        setPageNumber((prevPageNumber) => prevPageNumber - 1);
        sessionFunc(pageNumber - 1);
      } else if (action === "add") {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
        sessionFunc(pageNumber + 1);
      }
    } else if (type === "SHOWS") {
      if (action === "sub") {
        sessionFunc(pageNumber - 1);
        setPageNumber((prevPageNumber) => prevPageNumber - 1);
      } else if (action === "add") {
        sessionFunc(pageNumber + 1);
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    } else {
      if (action === "sub") {
        sessionFunc(pageNumber - +1);
        setPageNumber((prevPageNumber) => prevPageNumber - 1);
      } else if (action === "add") {
        sessionFunc(pageNumber + 1);
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    }
  };

  const moveToPage = (page) => {
    if (type === "MOVIES") {
      setPageNumber(page);
    } else if (type === "SHOWS") {
      setPageNumber(page);
    } else {
      setPageNumber(page);
    }
    sessionFunc(page);
  };

  const handleSearch = () => {
    if (type === "MOVIES") {
      searchMoviesFunc(searchvalue, pageNumber);
    } else if (type === "SHOWS") {
      searchShowsFunc(searchvalue, pageNumber);
    } else {
      searchPeopleFunc(searchvalue, pageNumber);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const allLengths = await getSearch(
        searchvalue,
        Number(sessionStorage.getItem("pageNumber")) || 1
      );

      const moviesTotalResults = allLengths.movies.total_results;
      const showsTotalResults = allLengths.shows.total_results;
      const peopleTotalResults = allLengths.people.total_results;

      const moviesTotalPages = allLengths.movies.total_pages;
      const showsTotalPages = allLengths.shows.total_pages;
      const peopleTotalPages = allLengths.people.total_pages;

      setTotalResults({
        movies: moviesTotalResults,
        shows: showsTotalResults,
        people: peopleTotalResults,
      });

      setTotalPageNumber({
        movies: moviesTotalPages,
        shows: showsTotalPages,
        people: peopleTotalPages,
      });
    };

    fetchData();
  }, [searchvalue]);

  useEffect(() => {
    handleSearch();
    // Scroll to top of the page
    window.scrollTo({ top: "330", behavior: "smooth" });
  }, [pageNumber, pageNumber, pageNumber]);

  const renderNextPagesButtons = (currentPage, totalPages) => {
    const nextPages = [];

    const remainingPages = Math.min(totalPages - currentPage, 1);

    for (let i = currentPage + 1; i <= currentPage + remainingPages; i++) {
      nextPages.push(
        <NextPagesButton
          key={i}
          isActive={i === currentPage}
          onClick={() => moveToPage(i)}
        >
          {i}
        </NextPagesButton>
      );
    }

    if (currentPage + remainingPages < totalPages) {
      nextPages.push(
        <NextPagesButton
          key={currentPage + remainingPages + 1}
          onClick={() => moveToPage(currentPage + remainingPages + 1)}
        >
          {currentPage + remainingPages + 1}
        </NextPagesButton>
      );
    }

    return nextPages;
  };

  if (searchvalue === undefined) {
    return <LoadingCircle />;
  } else {
    return (
      <>
        <HeaderBackdrop />
        <Master>
          <Search>Result for "{searchvalue}"</Search>
          <Standard>
            <ButtonWrap>
              <Button
                focus={type === "MOVIES"}
                onClick={() => handleClick("MOVIES")}
              >
                MOVIES ({totalResults.movies})
              </Button>
              <Button
                focus={type === "SHOWS"}
                onClick={() => handleClick("SHOWS")}
              >
                SHOWS ({totalResults.shows})
              </Button>
              <Button
                focus={type === "PEOPLE"}
                onClick={() => handleClick("PEOPLE")}
              >
                PEOPLE ({totalResults.people})
              </Button>
            </ButtonWrap>
            {searchTV.length === 0 &&
            searchMovies.length === 0 &&
            searchPeople.length === 0 ? (
              <NoResult> No Result</NoResult>
            ) : (
              <SearchResultWrapper>
                {type === "MOVIES"
                  ? searchMovies
                      .sort((a, b) => b.popularity - a.popularity)
                      .map((movie, idx) => (
                        <Card
                          key={idx}
                          bg={movie.poster_path}
                          id={movie.id}
                          rating={movie.vote_average}
                          character={movie.character}
                          release={movie.release_date || movie.first_air_date}
                          title={movie.title}
                          type="movie"
                          allDetails={movie}
                        />
                      ))
                  : type === "SHOWS"
                  ? searchTV
                      .sort((a, b) => b.popularity - a.popularity)
                      .map((show, idx) => (
                        <Card
                          key={idx}
                          bg={show.poster_path}
                          id={show.id}
                          rating={show.vote_average}
                          character={show.character}
                          release={show.release_date || show.first_air_date}
                          title={show.original_name}
                          type="tv"
                          allDetails={show}
                        />
                      ))
                  : searchPeople
                      .sort((a, b) => b.popularity - a.popularity)
                      .map((person, idx) => (
                        <CardPeople
                          key={idx}
                          bio={person.biography}
                          bg={person.profile_path}
                          id={person.id}
                          name={person.name}
                          character={person.character}
                          type="person"
                        />
                      ))}
              </SearchResultWrapper>
            )}
            <PagesWrap>
              {type === "SHOWS" && pageNumber > 1 && (
                <>
                  <FirstPageButton
                    onClick={() => {
                      moveToPage(1);
                    }}
                  >
                    1{pageNumber > 2 && "..."}
                  </FirstPageButton>
                  <PreviousPageButton onClick={() => handlePage("sub")} />
                  <CurrentPage>{pageNumber}</CurrentPage>
                </>
              )}
              {type === "MOVIES" && pageNumber > 1 && (
                <>
                  <FirstPageButton
                    onClick={() => {
                      moveToPage(1);
                    }}
                  >
                    1{pageNumber > 2 && "..."}
                  </FirstPageButton>
                  <PreviousPageButton onClick={() => handlePage("sub")} />
                  <CurrentPage>{pageNumber}</CurrentPage>
                </>
              )}
              {type === "PEOPLE" && pageNumber > 1 && (
                <>
                  <FirstPageButton
                    onClick={() => {
                      moveToPage(1);
                    }}
                  >
                    1{pageNumber > 2 && "..."}
                  </FirstPageButton>
                  <PreviousPageButton onClick={() => handlePage("sub")} />
                  <CurrentPage>{pageNumber}</CurrentPage>
                </>
              )}

              {type === "SHOWS" && pageNumber < totalPageNumber.shows && (
                <NextPage onClick={() => handlePage("add")} />
              )}
              {type === "MOVIES" && pageNumber < totalPageNumber.movies && (
                <NextPage onClick={() => handlePage("add")} />
              )}
              {type === "PEOPLE" && pageNumber < totalPageNumber.people && (
                <NextPage onClick={() => handlePage("add")} />
              )}

              {type === "SHOWS" && pageNumber + 1 < totalPageNumber.shows && (
                <>{renderNextPagesButtons(pageNumber, totalPageNumber.shows)}</>
              )}
              {type === "MOVIES" && pageNumber + 1 < totalPageNumber.movies && (
                <>
                  {renderNextPagesButtons(pageNumber, totalPageNumber.movies)}
                </>
              )}
              {type === "PEOPLE" && pageNumber + 1 < totalPageNumber.people && (
                <>
                  {renderNextPagesButtons(pageNumber, totalPageNumber.people)}
                </>
              )}
              {type === "SHOWS" && pageNumber < totalPageNumber.shows && (
                <LastPageButton
                  onClick={() => moveToPage(totalPageNumber.shows)}
                  currentPage={pageNumber}
                  totalPage={totalPageNumber.shows}
                >
                  {pageNumber < totalPageNumber.shows - 3 ? "..." : ""}(
                  {totalPageNumber.shows})
                </LastPageButton>
              )}
              {type === "MOVIES" &&
                pageNumber < totalPageNumber.movies &&
                pageNumber != totalPageNumber.movies && (
                  <LastPageButton
                    onClick={() => moveToPage(totalPageNumber.movies)}
                    currentPage={pageNumber}
                    totalPage={totalPageNumber.movies}
                  >
                    {pageNumber < totalPageNumber.movies - 3 ? "..." : ""}(
                    {totalPageNumber.movies})
                  </LastPageButton>
                )}
              {type === "PEOPLE" && pageNumber < totalPageNumber.people && (
                <>
                  <LastPageButton
                    onClick={() => handlePage(totalPageNumber.people)}
                    currentPage={pageNumber}
                    totalPage={totalPageNumber.people}
                  >
                    {pageNumber < totalPageNumber.people - 3 ? "..." : ""}(
                    {totalPageNumber.people})
                  </LastPageButton>
                </>
              )}
            </PagesWrap>
          </Standard>
        </Master>
      </>
    );
  }
};

export default SearchResultPage;

const Master = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Search = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 25px 0;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    text-align: center;
  }
`;

const Standard = styled.div`
  width: 1180px;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #edeef2;

  @media screen and (max-width: 1024px) {
    width: 90%;
  }
`;

const SearchResultWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const People = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: row;
  }
`;

const NoResult = styled.h1`
  min-height: 600px;
  text-align: center;
  font-size: 22px;
`;

const ButtonWrap = styled.div`
  width: 40%;
  height: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    width: 40%;
  }
`;

const Button = styled.div`
  background: ${({ focus }) => (focus ? "black" : "white")};
  color: ${({ focus }) => (focus ? "powderblue" : "black")};
  ${UniversalButtonStyles};
  min-width: 100px;
`;

const PagesWrap = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  p {
    cursor: pointer;

    &:hover {
      color: red;
    }
  }
  @media screen and (max-width: 768px) {
    width: 350px;
  }
`;
const FirstPageButton = styled.p``;

const NextPagesButton = styled.p`
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
`;

const LastPageButton = styled.p`
  display: ${({ currentPage, totalPage }) =>
    currentPage === totalPage - 2 && "none"};
`;

const PreviousPageButton = styled(BackArrow)`
  cursor: pointer;
`;

const NextPage = styled(ForwardArrow)`
  cursor: pointer;
`;

const CurrentPage = styled.p`
  color: green;
`;
