import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ReviewsContext } from "../../context/Reviews/ReviewsContext";
import ReviewsModal from "./ReviewsModal";

const LeaveReviews = ({ type, id, title, uid, ratingScore, name }) => {
  const [review, setReview] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { saveReview } = useContext(ReviewsContext); // Extracting saveReview from context

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const handleSubmitReview = async () => {
    setIsLoading(true);
    setMessage("");
    try {
      await saveReview(review, id, uid, title, type, ratingScore, name);
      setMessage("Review submitted successfully!"); // Set success message
    } catch (err) {
      setMessage("Failed to submit review. Please try again."); // Set error message
    }
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setMessage("");
    setReview(""); // Clear review text when modal is closed
  };

  return (
    <MainWrap>
      <Text>Leave a review:</Text>
      <Textarea
        value={review}
        onChange={handleReviewChange}
        placeholder="Write your review here..."
        disabled={isLoading}
      />
      <SubmitButton
        onClick={handleSubmitReview}
        disabled={isLoading || !review}
      >
        {isLoading ? "Saving..." : "Save"}
      </SubmitButton>
      {message && (
        <ReviewsModal onClose={handleCloseModal}>{message}</ReviewsModal>
      )}
    </MainWrap>
  );
};

export default LeaveReviews;

const MainWrap = styled.div`
  width: 100%;
  min-height: 200px;
  color: white;
`;

const Text = styled.p``;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 100px;
  color: white;
  resize: vertical;
  margin-top: 10px;
  padding: 10px;
  background: black;
  border: 1px solid white;
  border-radius: 7px;

  ::placeholder {
    color: white;
    opacity: 0.7;
  }
`;

const SubmitButton = styled.button`
  color: #fff;
  background-color: black;
  border: 1px solid #fff;
  width: 150px;
  height: 25px;
  border-radius: 8px;
  margin: 20px;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    border: 1px solid green;
    color: green;
  }

  @media screen and (max-width: 768px) {
    min-width: 80px;
    margin: 5px;
    &:active {
      background-color: #000;
      border: 1px solid green;
      color: #fff;
    }
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

const SuccessMessage = styled.div`
  color: green;
  margin-top: 10px;
`;
