import React, { createContext, useEffect, useReducer } from "react";
import reviewsReducer from "./ReviewsReducer";
import { db } from "../../firebase";
import {
  Timestamp,
  arrayUnion,
  doc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

// Initial state
const initialState = {
  reviews: {
    movies: {},
    shows: {},
  },
  loading: false,
  error: null,
};

// Create context
export const ReviewsContext = createContext();

// Context provider component
export const ReviewsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reviewsReducer, initialState);

  const saveReview = async (
    review,
    itemId,
    userId,
    title,
    type,
    ratingScore,
    username
  ) => {
    console.log("saving", username);
    try {
      dispatch({ type: "ADD_REVIEW_REQUEST" });

      const collectionName = type === "movie" ? "movieReviews" : "showReviews";
      const reviewId = `${userId}_${new Date().getTime()}`;

      const itemDocRef = doc(db, collectionName, itemId);
      const reviewData = {
        [reviewId]: {
          userId,
          title,
          review,
          timestamp: Timestamp.now(),
          likes: [],
          ratingScore,
          username,
        },
      };

      const docSnap = await getDoc(itemDocRef);
      if (docSnap.exists()) {
        await updateDoc(itemDocRef, reviewData);
      } else {
        await setDoc(itemDocRef, reviewData);
      }

      const userQuery = query(
        collection(db, "users"),
        where("uid", "==", userId)
      );
      const userQuerySnapshot = await getDocs(userQuery);
      if (userQuerySnapshot.empty) {
        throw new Error("No user found with the given UID.");
      }
      const userDocRef = userQuerySnapshot.docs[0].ref;
      const userReviewField = type === "movie" ? "movieReviews" : "showReviews";

      await updateDoc(userDocRef, {
        [userReviewField]: arrayUnion({
          reviewId: reviewId,
          itemId: itemId,
          title: title,
        }),
      });

      dispatch({
        type: "ADD_REVIEW_SUCCESS",
        payload: { reviewId, type, itemId, ...reviewData[reviewId] },
      });
    } catch (error) {
      console.error("Error saving review:", error);
      dispatch({ type: "ADD_REVIEW_FAILURE", payload: error.message });
    }
  };

  // useEffect(() => {
  //   console.log(state.reviews);
  // }, [state.reviews]);
  async function checkIfReviewExists(movieId, type) {
    console.log("Checking reviews for:", movieId, "Type:", type);
    const collectionName = type === "movie" ? "movieReviews" : "showReviews";
    const reviewType = type === "movie" ? "movies" : "shows";

    // Check if the reviews are already in the state
    if (state.reviews[reviewType][movieId]) {
      console.log("Using cached reviews");
      console.log(state.reviews[reviewType][movieId]);
      return state.reviews[reviewType][movieId];
    }

    try {
      const docRef = doc(db, collectionName, movieId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        dispatch({
          type: "FETCH_REVIEW_SUCCESS",
          payload: { movieId: movieId, reviews: docSnap.data(), type: type },
        });
        return docSnap.data();
      } else {
        console.log("No such document!");
        dispatch({
          type: "FETCH_REVIEW_FAILURE",
          payload: "No reviews found.",
          type: type,
        });
        return null;
      }
    } catch (error) {
      dispatch({
        type: "FETCH_REVIEW_FAILURE",
        payload: error.message,
        type: type,
      });
      console.error("Error fetching reviews:", error);
    }
  }

  return (
    <ReviewsContext.Provider
      value={{
        state,
        dispatch,
        saveReview,
        checkIfReviewExists,
        myreviews: state.reviews,
      }}
    >
      {children}
    </ReviewsContext.Provider>
  );
};
