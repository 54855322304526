import { useEffect, useState, useContext } from "react";
import styled, { css } from "styled-components";
import { FcGoogle } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";
import LoadingCircle from "../Loading/LoadingCircle";
import { passwordReset } from "../../firebase";

import AuthContext from "../../context/User/AuthContext";

const LoginComponent = () => {
  const {
    registerFunc,
    register,
    registerError,
    handleGoogleLogin,
    login,
    user,
    loginError,
    handleLoginState,
  } = useContext(AuthContext);

  const [firstName, setFirstName] = useState("");
  const [sirName, setSirName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [errorSpan, setErrorSpan] = useState("");
  const [loading, setLoading] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetError, setResetError] = useState("");
  const [emailMessage, setEmailMessage] = useState(false);
  const [usernameState, setUsernameState] = useState("");

  const handleResetPasswordClick = () => {
    setShowResetPassword(true);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await passwordReset(resetEmail);
      setEmailMessage(true);
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        alert("User not found, try again!");
        setResetEmail("");
      }
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    const name = `${firstName} ${sirName}`;
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (email !== confirmEmail || password !== confirmPassword) {
      if (email !== confirmEmail) {
        setConfirmEmail("");
        setEmailError(true);
      }
      if (password !== confirmPassword) {
        setConfirmPassword("");
        setPasswordError(true);
      }
    } else {
      if (password.match(passw)) {
        setLoading(true);
        registerFunc(
          email,
          password,
          setErrorSpan,
          name,
          usernameState,
          setLoading
        );
      } else {
        alert(
          "Password must include 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"
        );
      }
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    login(loginEmail, loginPassword, setLoginPassword, setLoginEmail);
    setLoading(true);
  };

  useEffect(() => {
    setLoading(false);
    if (user) {
      handleLoginState(false);
    }
    if (loginError) {
      setLoading(false);
    }
  }, [user, loginError]);

  return (
    <Master>
      {loading ? (
        <LoadingCircle />
      ) : (
        <MainWrapper>
          <CloseButton onClick={() => handleLoginState(false)}>
            <CloseIcon />
          </CloseButton>
          {showResetPassword ? (
            emailMessage ? (
              <SuccessMessage>
                The Email has been sent; Check your Inbox!
              </SuccessMessage>
            ) : (
              <FormWrapper>
                <h2>Reset Password</h2>
                {resetSuccess ? (
                  <p>
                    A password reset email has been sent to your email address.
                  </p>
                ) : (
                  <>
                    <p>Enter your email address to reset your password:</p>
                    <form onSubmit={handleResetPassword}>
                      <input
                        type="email"
                        placeholder="Email"
                        value={resetEmail}
                        onChange={(e) => setResetEmail(e.target.value)}
                        required
                      />
                      <button type="submit">Reset Password</button>
                    </form>
                    {resetError && <p className="error">{resetError}</p>}
                  </>
                )}
              </FormWrapper>
            )
          ) : (
            <>
              {register ? (
                <RegisterForm onSubmit={handleRegister}>
                  <input
                    type="text"
                    placeholder="First name"
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    value={firstName}
                  />
                  <input
                    type="text"
                    placeholder="Surname"
                    onChange={(e) => setSirName(e.target.value)}
                    required
                    value={sirName}
                  />
                  <input
                    type="text"
                    placeholder="Username"
                    onChange={(e) => setUsernameState(e.target.value)}
                    required
                    value={usernameState}
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    value={email}
                  />
                  <input
                    onClick={() => setEmailError(false)}
                    type="email"
                    placeholder="Confirm Email"
                    required
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    value={confirmEmail}
                  />
                  {emailError && <span>*Email doesn't match*</span>}
                  <input
                    type="password"
                    placeholder="Password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  <input
                    onClick={() => setPasswordError(false)}
                    type="password"
                    placeholder="Confirm Password"
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                  />
                  {passwordError && <span>*Password doesn't match*</span>}
                  <button type="submit">Register</button>
                  <ErrorSpan>{errorSpan}</ErrorSpan>
                  <p>
                    Password must include 6 to 20 characters which contain at
                    least one numeric digit, one uppercase and one lowercase
                    letter
                  </p>
                  <GoogleLoginButton onClick={handleGoogleLogin}>
                    <GoogleIcon />
                    <GoogleSpan>Register with Google</GoogleSpan>
                  </GoogleLoginButton>
                </RegisterForm>
              ) : (
                <>
                  <LoginForm onSubmit={handleLogin}>
                    <input
                      type="email"
                      value={loginEmail}
                      onChange={(e) => setLoginEmail(e.target.value)}
                      placeholder="Email"
                    />
                    <input
                      type="password"
                      value={loginPassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                      placeholder="Password"
                    />
                    <button type="submit">Login</button>
                    <ResetPasswordButton onClick={handleResetPasswordClick}>
                      Reset Password
                    </ResetPasswordButton>
                    <span>{loginError}</span>
                    <GoogleLoginButton onClick={handleGoogleLogin}>
                      <GoogleIcon />
                      <GoogleSpan>Sign in with Google</GoogleSpan>
                    </GoogleLoginButton>
                  </LoginForm>
                </>
              )}
            </>
          )}
        </MainWrapper>
      )}
    </Master>
  );
};

export default LoginComponent;

const Master = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 500;
  top: 0;
  background-color: rgba(0, 0, 0, 0.61);
  backdrop-filter: blur(5px);
`;

const MainWrapper = styled.div`
  height: auto;
  width: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  border: 1px solid #000;
  background-color: #fff;
  overflow: hidden;
  padding: 20px 20px 40px 20px;
  box-sizing: border-box;
  h5 {
    margin: 10px 0;
  }
  input {
    width: 100%;
    height: 20px;
    margin-top: 10px;
    border-radius: 7px;
    border: 1px solid grey;
    padding: 5px;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const CloseIcon = styled(IoMdClose)`
  color: grey;
  font-size: 24px;
`;

const ButtonCss = css`
  color: #fff;
  background: #e50914;
  border: none;
  width: 150px;
  height: 35px;
  border-radius: 5px;
  margin: 20px 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  &:hover {
    background: #f40612;
  }
`;

const RegisterForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    ${ButtonCss}
  }
  p {
    font-size: 12px;
    max-width: 280px;
    text-align: center;
  }
`;

const ErrorSpan = styled.div`
  margin-top: 5px;
  width: 80%;
  font-size: 12px;
  text-align: center;
  color: red;
  font-weight: bold;
  background-color: #fff;
`;

const LoginForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    ${ButtonCss}
  }
  span {
    background: white;
    color: red;
  }
`;

const GoogleLoginButton = styled.div`
  height: 35px;
  width: 200px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-around;
  border: 1px solid #ccc;
  margin-top: 10px;
  &:hover {
    background: #ecf0f4;
  }
`;

const GoogleSpan = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #757575;
  font-size: 14px;
  margin-right: 10px;
  background-color: transparent;
`;

const GoogleIcon = styled(FcGoogle)`
  width: 20%;
  height: 70%;
`;

const ResetPasswordButton = styled.h5`
  font-size: 12px;
  color: blue;
  cursor: pointer;
  margin-top: 10px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 10px;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    input {
      margin: 5px;
    }
    button {
      ${ButtonCss}
    }
    p.error {
      color: red;
    }
  }
`;

const SuccessMessage = styled.h3`
  text-align: center;
`;
