import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import MasterCardWrapper from "../CardWrapper/MasterCardWrapper";
import FirstLoaderContext from "../../context/FirstLoaders/FirstLoaders";
import { UniversalButtonStyles } from "../../UniversalStyles";

const MainCardWrapper = ({ content, type }) => {
  const [selectorState, setSelectorState] = useState("MOST POPULAR");
  const [cardState, setCardState] = useState([]);
  const { topMovies, topSeries, series, movies, upcoming, upcomingSeries } =
    useContext(FirstLoaderContext);

  // Define selectors here so it can access the content prop
  const selectors = ["MOST POPULAR", "TOP RATED", "UPCOMING"].filter(Boolean);

  const selectorHandle = (selector) => {
    setSelectorState(selector);
  };

  useEffect(() => {
    if (selectorState === "MOST POPULAR") {
      setCardState(type === "movie" ? movies : series);
    } else if (selectorState === "TOP RATED") {
      setCardState(content === "MOVIES" ? topMovies : topSeries);
    } else {
      setCardState(content === "MOVIES" ? upcoming : upcomingSeries);
    }
  }, [
    selectorState,
    type,
    content,
    movies,
    series,
    topMovies,
    topSeries,
    upcoming,
  ]);

  return (
    <MasterDiv>
      <Selector>
        <WrapperTitle>{content}</WrapperTitle>
        {selectors.map((type, idx) => (
          <SelectorButton
            css={selectorState}
            type={type}
            key={idx}
            onClick={() => selectorHandle(type)}
          >
            {type}
          </SelectorButton>
        ))}
      </Selector>
      <MasterCardWrapper
        movieCard={"movie"}
        content={content}
        name={selectorState}
        movies={cardState}
        type={type}
      />
    </MasterDiv>
  );
};

export default MainCardWrapper;

const MasterDiv = styled.div`
  width: 100%;
  height: 27rem;
  margin-top: 20px;

  @media screen and (max-width: 768px) {
    height: 29rem;
  }
`;

const Selector = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  margin-bottom: 0;
`;

const WrapperTitle = styled.div`
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  border-right: solid 2px black;
`;

const SelectorButton = styled.button`
  background: ${({ type, css }) => (type === css ? "black" : "white")};
  color: ${({ type, css }) => (type === css ? "powderblue" : "black")};
  ${UniversalButtonStyles};
  width: 150px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
