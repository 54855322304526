import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import DisneyLogo from "../../assets/images/Networks/DisneyLogo.png";
import PrimeLogo from "../../assets/images/Networks/PrimeLogo.png";
import NetflixLogo from "../../assets/images/Networks/NetflixLogo.png";
import NowLogo from "../../assets/images/Networks/NowLogo.svg";
import AppleLogo from "../../assets/images/Networks/AppleLogo.png";
import SizeContext from "../../context/SizeContext";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import TmdbContext from "../../context/TmdbContext";

const networksList = [
  { image: DisneyLogo, name: "disney" },
  { image: PrimeLogo, name: "prime" },
  { image: NetflixLogo, name: "netflix" },
  { image: NowLogo, name: "nowTv" },
  { image: AppleLogo, name: "apple" },
];

const NetworksComponent = () => {
  const { isSmall } = useContext(SizeContext);
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate();

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const { getNetworkIds } = useContext(TmdbContext);
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const contentWrapper = React.useRef(null);

  const handleLink = (link) => {
    navigate(`/network/${link}/`);
  };

  const sideScroll = (
    element: HTMLDivElement,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  return (
    <MainWrapper id="networks">
      <TitleTag>
        <Square /> Networks{" "}
      </TitleTag>

      <MainScroll>
        {!isSmall &&
          contentWrapper?.current?.clientWidth <
            contentWrapper?.current?.scrollWidth && (
            <ScrollButton
              onClick={() => sideScroll(contentWrapper.current, 1, 1, -300)}
            >
              <BackArrow />
            </ScrollButton>
          )}
        <Scrolling
          id="scroller"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <Container ref={contentWrapper}>
            {networksList.map((network, idx) => (
              <Box key={idx}>
                <Card
                  key={idx}
                  onClick={() => {
                    handleLink(network.name);
                    getNetworkIds(network.name);
                  }}
                >
                  <img src={network.image} />
                </Card>
              </Box>
            ))}
          </Container>
        </Scrolling>
        {!isSmall &&
          contentWrapper?.current?.clientWidth <
            contentWrapper?.current?.scrollWidth && (
            <ScrollButton
              onClick={() => sideScroll(contentWrapper.current, 1, 1, 300)}
            >
              <ForwardArrow />
            </ScrollButton>
          )}
      </MainScroll>
    </MainWrapper>
  );
};

export default NetworksComponent;

const MainWrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
`;

const Square = styled.div`
  width: 15px;
  height: 15px;
  margin: 0 10px;
  border-radius: 4px;
  background: rgb(255, 0, 0);
`;

const TitleTag = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
`;

const ScrollButton = styled.button`
  z-index: 1;
  border: none;
  width: 40px;
  height: 40px;
  background: transparent;
  border-radius: 50%;
  margin: 0 -20px;
  cursor: pointer;
`;

const Container = styled.div`
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  ::-webkit-scrollbar {
    height: 5px;
    border-radius: 7px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 7px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

const Box = styled.div`
  display: inline-block;
  padding: 2vh;
  white-space: normal;
  vertical-align: top;
  height: 150px;
`;

const MainScroll = styled.div`
  display: flex;
  width: 98%;
  align-items: center;
`;

const Scrolling = styled.div`
  display: flex;
  width: 98%;
  height: 100%;
`;

const Card = styled.div`
  width: 230px;
  height: 150px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);

  img {
    width: 130px;
    max-height: 80px;
  }
`;

const BackArrow = styled(IoIosArrowBack)`
  color: black;
  margin-left: -30px;
  font-size: 40px;
`;

const ForwardArrow = styled(IoIosArrowForward)`
  color: black;
  margin-left: 10px;
  font-size: 40px;
`;
