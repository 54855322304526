import React from "react";
import styled from "styled-components";
import { ImStarFull } from "react-icons/im";

const StarComponent = ({ rating, location }) => {
  return (
    <OwnRating location={location}>
      <OwnRatingNumber location={location}>{rating}</OwnRatingNumber>
      <Star location={location} />
    </OwnRating>
  );
};

export default StarComponent;

const OwnRating = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${({ location }) => (location === "details" ? "50px" : "30px")};
  width: ${({ location }) => (location === "details" ? "50px" : "30px")};
  margin: 4px;
`;

const Star = styled(ImStarFull)`
  width: ${({ location }) => (location === "details" ? "50px" : "30px")};
  height: ${({ location }) => (location === "details" ? "50px" : "30px")};
  color: gold;
  @media screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

const OwnRatingNumber = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 1px solid black;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  position: absolute;
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
`;
