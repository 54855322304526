import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/images/noImage.png";
import UserContext from "../../context/User/UserContext";
import AuthContext from "../../context/User/AuthContext";
import NewCardModal from "../CardModal/NewCardModal";
import ToFavouriteButton from "../FavWishAdd/ToFavouriteButton";

const CardPeople = React.memo(({ id, name, character, bg }) => {
  const navigate = useNavigate();
  const navigateToActorDetails = () => {
    navigate(`/actordetails/${id}`);
  };

  return (
    <>
      <MainWrapper>
        <FavButtonWrapper>
          <ToFavouriteButton location={"person"} id={id} />
        </FavButtonWrapper>

        <ContainerPeople
          onClick={navigateToActorDetails}
          bg={bg}
          id={id}
        ></ContainerPeople>
        <Info id="cardpeople" name={name.lenght}>
          <NameFavWrap>
            <ActorName>{name?.toUpperCase().slice(0, 23) || "N/N"}</ActorName>
            {character && (
              <>
                <span>as</span>
                <Name>{character?.slice(0, 23)}</Name>
              </>
            )}
          </NameFavWrap>
        </Info>
      </MainWrapper>
    </>
  );
});

export default CardPeople;

const MainWrapper = styled.div`
  width: 180px;
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 10px;
  border-radius: 1rem;
  border: 1px solid #999;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    width: 140px;
    margin-right: 10px;
  }
`;

const ContainerPeople = styled.div`
  flex: 0.8;
  display: flex;
  width: 100%;

  position: relative;
  background-image: ${({ bg }) =>
    bg != null
      ? `url(https://www.themoviedb.org/t/p/w300${bg})`
      : `url(${noImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 200px;
  }
`;

const Info = styled.div`
  flex: 0.2;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #000;
  color: #fff;
  bottom: 0;

  span {
    font-size: 0.8rem;
    margin-top: 0.7rem;

    @media screen and (max-width: 48rem) {
      font-size: 0.6rem;
      margin: 0.5rem 0;
    }
  }
`;

const NameFavWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ActorName = styled.div`
  text-align: center;
  font-size: 0.7rem;
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;

  @media screen and (max-width: 48rem) {
    font-size: 1rem;
    font-size: 0.6rem;
  }
`;

const Name = styled.h6`
  margin-top: 0.9rem;

  @media screen and (max-width: 48rem) {
    margin-top: 0.6rem;
  }
`;

const FavButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 85px;
  right: 8px;
  width: 30px;
  height: 30px;
  margin-left: 5px;

  z-index: 5;
  @media screen and (max-width: 48rem) {
    margin-left: 3px;
  }
`;
