import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import SizeContext from "../../context/SizeContext";
import Reviews from "./Reviews";

const NewReviews = ({ reviews }) => {
  const [expandedReview, setExpandedReview] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [displayedReviews, setDisplayedReviews] = useState(reviews.slice(0, 3));
  const { isSmall } = useContext(SizeContext);
  const [count, setCount] = useState(null);

  const handleShowAll = () => {
    setShowAll(!showAll);
    if (!showAll) {
      setDisplayedReviews(reviews);
    } else {
      setDisplayedReviews(reviews.slice(0, 3));
    }
  };

  useEffect(() => {
    if (isSmall) {
      setCount(1);
    } else if (window.innerWidth < 1250) {
      setCount(2);
    } else if (reviews?.length < 4) {
      setCount(reviews?.length);
    } else {
      setCount(4);
    }
  }, [isSmall, reviews]);

  // Update displayedReviews when reviews prop changes
  useEffect(() => {
    if (showAll) {
      setDisplayedReviews(reviews);
    } else {
      setDisplayedReviews(reviews.slice(0, 3));
    }
  }, [reviews, showAll]);

  return (
    <ReviewsWrap>
      {displayedReviews.map((review, idx) => (
        <Card key={idx}>
          <Reviews
            review={review}
            username={review.author_details.username}
            rate={review.author_details.rating}
            content={review.content}
            expanded={expandedReview === idx}
            setExpanded={() =>
              setExpandedReview(expandedReview === idx ? null : idx)
            }
          />
        </Card>
      ))}
      {reviews.length > 3 && (
        <CenteredButtonContainer>
          <ShowAllButton onClick={handleShowAll}>
            {showAll ? "Show Less" : "Show All"}
          </ShowAllButton>
        </CenteredButtonContainer>
      )}
    </ReviewsWrap>
  );
};

export default NewReviews;

const ReviewsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  /* padding: 10px; */
`;

const Card = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border-radius: 8px; */
  margin-bottom: 5px;
`;

const CenteredButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ShowAllButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
`;

const UserScore = styled.div`
  height: 20px;
  width: 20px;
  border: 3px solid ${(props) => (props.rating >= 6 ? "green" : "red")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  h3 {
    color: #fff;
    font-size: 14px;
  }
  h5 {
    font-size: 14px;
  }
`;
