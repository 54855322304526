import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import oscar from "../../assets/images/oscar.png";
import {
  MdOutlineArrowBackIosNew as BackArrow,
  MdOutlineArrowForwardIos as ForwardArrow,
} from "react-icons/md";
import {
  oscarWinnersData2023,
  oscarWinnersData2024,
} from "../../assets/oscarwinnersData";
import OscarCard from "./OscarCard";

const Oscar = () => {
  const [index, setIndex] = useState(0);
  const [currentYear, setCurrentYear] = useState(2024);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);

  const oscarData =
    currentYear === 2023 ? oscarWinnersData2023 : oscarWinnersData2024;
  const totalWinners = oscarData.length - 1;

  const toggleYear = () => {
    setCurrentYear(currentYear === 2023 ? 2024 : 2023);
    setIndex(0); // Reset index to show the first winner from the toggled year
  };

  return (
    <MainWrap id="oscar">
      <TitleWrap>
        <YearToggle>
          {currentYear === 2024 && <SmallLeftArrow onClick={toggleYear} />}
        </YearToggle>

        <OscarLogo />
        <OscarTitle>OSCAR WINNERS {currentYear}</OscarTitle>

        <YearToggle>
          {currentYear === 2023 && <SmallRightArrow onClick={toggleYear} />}
        </YearToggle>
      </TitleWrap>
      <Box>
        <LeftArrow
          onClick={() => setIndex(index !== 0 ? index - 1 : totalWinners)}
        />
        <OscarCard
          data={oscarData[index]}
          type="movie"
          videoRef={videoRef}
          playing={isVideoPlaying}
          onPlay={() => setIsVideoPlaying(true)}
          onPause={() => setIsVideoPlaying(false)}
        />
        <RightArrow
          onClick={() => setIndex(index !== totalWinners ? index + 1 : 0)}
        />
      </Box>
    </MainWrap>
  );
};
export default Oscar;

const MainWrap = styled.div`
  width: 98%;
  height: auto;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 50px 0;
  padding: 5px 0;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    margin: 30px 0;
  }
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const OscarLogo = styled.div`
  width: 15px;
  height: 40px;
  background: url(${oscar});
  background-size: cover;
  margin-right: 10px;

  @media screen and (max-width: 768px) {
    width: 10px;
    height: 26px;
  }
`;

const OscarTitle = styled.div`
  font-size: 24px;
  color: gold;
  margin: 10px 0;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    margin: 10px 0;
  }
`;

const MovieAward = styled.div`
  color: red;
  text-align: center;
  margin: 5px 0;
  font-size: ${({ award }) => (award.lenght < 26 ? "18px" : "14px")};
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0 0 0 5px;
    color: #fff;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 15px 15px 0;
  width: 70%;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 60%;
    margin: 0;
  }
`;

const ArrowStyle = css`
  color: #fff;
  font-size: 3vw;
  z-index: 500;
  top: 50%;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    font-size: 10vw;
    margin-left: 0;
  }
`;

const LeftArrow = styled(BackArrow)`
  ${ArrowStyle};
`;

const RightArrow = styled(ForwardArrow)`
  ${ArrowStyle};
`;
const SmallLeftArrow = styled(BackArrow)`
  cursor: pointer;
`;

const SmallRightArrow = styled(ForwardArrow)`
  cursor: pointer;
`;
const YearToggle = styled.div`
  color: #fff;
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const YearArrow = styled(ForwardArrow)`
  // Style specifically for the year toggle arrow, if using an arrow icon
  // You may want to adjust its size, color, or add animation
`;
