import React from "react";
import styled from "styled-components";

const ConditionOfUse = () => {
  return (
    <MainWrap>
      <Opening>Condition Of Use</Opening>

      <Intro>
        Upon accessing and utilizing mycinemahub.com or any other platform,
        application, API, or embedded content owned or operated by GNDTDIGITAL
        Ltd (referred to as the "Service"), you are indicating your acceptance
        and agreement to adhere to the subsequent terms and conditions
        ("Terms"):
      </Intro>
      <ol>
        <li>
          <strong>Usage:</strong> You are permitted to utilize the Service
          solely in compliance with these Terms. Any rights not explicitly
          granted within these Terms are retained by us.
        </li>
        <li>
          <strong>Responsibility:</strong> You are accountable for all
          activities resulting from your utilization of the Service. We absolve
          ourselves of any liability (including negligence) regarding the
          content, opinions, statements, or other information posted by users or
          utilized within the Service.
        </li>
        <li>
          <strong>Provision of Information:</strong> To utilize the services
          provided through the Service, you must be at least 16 years old. By
          registering to use the Service, you consent to providing accurate,
          current, and complete information about yourself ("Registration
          Information"). It is your responsibility to maintain and promptly
          update this information to ensure its accuracy.
        </li>
        <li>
          <strong>Community Guidelines:</strong> Respectful and courteous
          behavior towards others' opinions is mandatory within the Service.
          Posting unwelcome, aggressive, suggestive, or otherwise inappropriate
          remarks directed at fellow members is prohibited. Please refer to our
          community policy for further details.
        </li>
        <li>
          <strong>Conduct:</strong> The Service must not be utilized to promote,
          incite, or engage in hate, violence, discrimination, or intolerance
          based on various attributes. We retain the right to remove content
          breaching community policies, engage in off-platform behavior
          moderation, and take action against prohibited activities such as
          spamming or deceptive practices.
        </li>
        <li>
          <strong>No Malicious Use:</strong> You agree to access the Service
          solely through our provided interface and refrain from any malicious
          activities, harassment, or impersonation of other users or Service
          employees. Soliciting personal information or misleading support
          representatives is strictly prohibited and may result in account
          actions.
        </li>
        <li>
          <strong>No Illegal Use:</strong> The Service shall not be used for any
          unlawful purposes or to violate any intellectual property rights or
          confidentiality obligations.
        </li>
        <li>
          <strong>Content Removal:</strong> We reserve the right to remove any
          content violating the Terms, including offensive, unlawful, or
          explicit material. This includes content promoting ideologies or
          misinformation counter to our guidelines.
        </li>
        {/* <li>
          <strong>Subscription Fees:</strong> Certain services within the
          Service may require subscription fees, which are subject to change.
          Refunds for canceled subscriptions within 30 days may be sought,
          subject to our discretion.
        </li> */}
        <li>
          <strong>Intellectual Property:</strong> We retain intellectual
          property rights over the Service. By posting content, you grant us a
          non-exclusive right to utilize it within the Service.
        </li>
        <li>
          <strong>Indemnity:</strong> You indemnify us against any liabilities
          resulting from your use of the Service.
        </li>
        <li>
          <strong>Amendments:</strong> We reserve the right to amend the Terms,
          with significant changes communicated to users.
        </li>
        <li>
          <strong>Logo Usage:</strong> Limited usage of our logo is permitted,
          subject to compliance with our guidelines. (Please contact us for more
          details)
        </li>
        <li>
          <strong>Third-Party Applications:</strong> Usage of third-party
          applications is subject to their terms of use, and we disclaim
          liability for their behavior or content.
        </li>
        <li>
          <strong>Account Termination:</strong> Failure to comply with these
          Terms may result in account suspension or termination.
        </li>
        <li>
          <strong>Technical Support:</strong> We will address technical issues
          promptly, but we are not liable for any losses resulting from Service
          malfunctions.
        </li>
        <li>
          <strong>Governing Law:</strong> These Terms are governed by England
          law, with disputes subject to the jurisdiction of England courts.
        </li>
      </ol>

      <p>This policy was last updated in April 2024 </p>
    </MainWrap>
  );
};

export default ConditionOfUse;

const MainWrap = styled.div`
  min-height: auto;
  width: 90%;
  padding: 150px 5%;

  li {
    padding-bottom: 30px;
  }
`;

const Opening = styled.div`
  font-weight: bold;
  padding-bottom: 30px;
`;

const Intro = styled.div``;
