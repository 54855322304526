import React, { useState, useEffect, useContext } from "react";
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigationType,
  NavigationType,
} from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import { TmdbProvider } from "./context/TmdbContext";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import DetailsPage from "./pages/DetailsPage";
import ActorDetails from "./pages/ActorDetails";
import SearchResultPage from "./pages/SearchResultPage";
import { SizeProvider } from "./context/SizeContext";
import { UserProvider } from "./context/User/UserContext";
import { FirstLoaderProvider } from "./context/FirstLoaders/FirstLoaders";
import { AuthProvider } from "./context/User/AuthContext";
import UserPage from "./pages/UserPage";
import Crew from "./pages/Crew";
import NetworksPage from "./pages/NetworksPage";
import SearchResultParameters from "./pages/SearchResultParameters";
import SeasonsEpisodes from "./pages/SeasonsEpisodes";
import ConditionOfUse from "./pages/ConditionOfUse";
import CommunityPolicy from "./pages/CommunityPolicy";
import Help from "./pages/Help";
import Privacy from "./pages/Privacy";
import Contact from "./pages/Contact";
import CookieConsentBanner from "./components/CookiesConsent/CookiesConsent";
import { ReviewsProvider } from "./context/Reviews/ReviewsContext";

function ScrollToTop() {
  const { pathname, key } = useLocation();
  const navigationType = useNavigationType();

  React.useEffect(() => {
    if (navigationType === NavigationType.Pop) {
      // User is navigating back
      const savedPosition = sessionStorage.getItem(`scrollPosition:${key}`);
      if (savedPosition) {
        window.scrollTo(0, parseInt(savedPosition, 10));
        // Optionally, you can remove the saved position if you only want to restore once
        // sessionStorage.removeItem(scrollPosition:${key});
      }
    } else {
      // For new navigation, scroll to top
      window.scrollTo(0, 0);
    }

    // Save the scroll position on unmount or path change
    return () => {
      sessionStorage.setItem(
        `scrollPosition:${key}`,
        window.scrollY.toString()
      );
    };
  }, [pathname, key, navigationType]);

  return null;
}

function App() {
  const [consentGiven, setConsentGiven] = useState(false);
  const googleAnalytics = process.env.REACT_APP_GA_TRACKING_ID;

  useEffect(() => {
    const necessaryCookies =
      sessionStorage.getItem("necessaryCookies") === "true";
    const analyticsCookies =
      sessionStorage.getItem("analyticsCookies") === "true";
    // const marketingCookies =
    //   sessionStorage.getItem("marketingCookies") === "true";

    if (necessaryCookies && analyticsCookies) {
      setConsentGiven(true);
    }
  }, []);

  useEffect(() => {
    if (!consentGiven) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [consentGiven]);

  useEffect(() => {
    const consent = sessionStorage.getItem("cookieConsent"); // Switched to sessionStorage
    if (consent === "true") {
      setConsentGiven(true);
    }
  }, []); // Removed consentGiven from dependency array since it's not necessary

  const handleAccept = () => {
    setConsentGiven(true);
    sessionStorage.setItem("cookieConsent", "true"); // Switched to sessionStorage
    // Save consent to sessionStorage
  };

  useEffect(() => {
    const necessaryCookies =
      sessionStorage.getItem("necessaryCookies") === "true";
    const analyticsCookies =
      sessionStorage.getItem("analyticsCookies") === "true";
    // const marketingCookies =
    //   sessionStorage.getItem("marketingCookies") === "true";

    if (necessaryCookies && analyticsCookies) {
      ReactGA.initialize(googleAnalytics);
      ReactGA.pageview(window.location.pathname + window.location.search);
      console.log("active");
    } else {
      console.log("not active");
    }
  }, [consentGiven]);

  return (
    <TmdbProvider>
      <AuthProvider>
        <FirstLoaderProvider>
          <UserProvider>
            <SizeProvider>
              <ReviewsProvider>
                <div className="App">
                  <Router>
                    {!consentGiven && (
                      <CookieConsentBanner onAccept={handleAccept} />
                    )}
                    <Navbar />
                    <ScrollToTop />

                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route
                        path="/detailspage/:type/:id"
                        element={<DetailsPage />}
                      />
                      <Route
                        path="/actordetails/:personid"
                        element={<ActorDetails />}
                      />
                      <Route
                        path="/searchresult/:searchvalue"
                        element={<SearchResultPage />}
                      />
                      <Route
                        path="/searchrespar/:fromyear/:toyear/:genre/:minrating/:provider/:sortby"
                        element={<SearchResultParameters />}
                      />

                      {/* <Route path="/login" element={<LoginPage />} /> */}
                      <Route path="/user/:option" element={<UserPage />} />
                      <Route path="/castandcrew/:type/:id" element={<Crew />} />
                      <Route
                        path="/seasonsepisodes/:title/:id/:numberseason"
                        element={<SeasonsEpisodes />}
                      />
                      <Route
                        path="/network/:channel/"
                        element={<NetworksPage />}
                      />
                      <Route path="/terms" element={<ConditionOfUse />} />
                      <Route
                        path="/communitypolicy"
                        element={<CommunityPolicy />}
                      />
                      <Route path="/help" element={<Help />} />
                      <Route path="/privacy" element={<Privacy />} />
                      <Route path="/contact" element={<Contact />} />
                    </Routes>
                    <Footer />
                  </Router>
                </div>
              </ReviewsProvider>
            </SizeProvider>
          </UserProvider>
        </FirstLoaderProvider>
      </AuthProvider>
    </TmdbProvider>
  );
}

export default App;
